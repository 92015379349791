import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  GetLinkedOrganizationsGQL,
  GetLinkedOrganizationsQuery,
  GetOrganizationGQL,
  OrganizationCreateInput,
  OrganizationUpdateInput,
  CreateOrganizationGQL,
  UpdateOrganizationGQL,
  OrganizationLinkInput,
  LinkOrganizationGQL,
  UnlinkOrganizationGQL,
  OrganizationFragmentDoc,
  GetLinkableOrganizationsGQL,
  GetLinkableOrganizationsQuery,
  OrganizationFragment,
  LinkOrganizationMutation,
  RemoveOrganizationGQL,
  GetOrganizationLogoUploadUrlGQL,
  OrganizationDeleteInput,
  UpdateOrganizationCurrenciesGQL,
  OrganizationCurrenciesInput,
  UpdateOrganizationCounterpartiesGQL,
  OrganizationCounterpartiesInput,
  FullOrganizationFragment,
  GetCounterpartiesGQL,
  Counterparty,
  OrganizationClientInput,
  EnableOrganizationClientGQL,
  DisableOrganizationClientGQL,
  GetOrganizationClientSecretGQL,
} from "@hedgebench/graphql";
import { map } from "rxjs/operators";
import { AppSettings } from "../../shared/services/app.settings";
import { getLogoDownloadURI } from "@hedgebench/shared";

@Injectable({ providedIn: "root" })
export class OrganizationsService {
  constructor(
    private readonly appSettings: AppSettings,
    private readonly orgList: GetLinkedOrganizationsGQL,
    private readonly org: GetOrganizationGQL,
    private readonly cOrg: CreateOrganizationGQL,
    private readonly uOrg: UpdateOrganizationGQL,
    private readonly linkOrg: LinkOrganizationGQL,
    private readonly linkable: GetLinkableOrganizationsGQL,
    private readonly unLinkOrg: UnlinkOrganizationGQL,
    private readonly organizationRemove: RemoveOrganizationGQL,
    private readonly uploadLogoUrl: GetOrganizationLogoUploadUrlGQL,
    private readonly currencyUpdate: UpdateOrganizationCurrenciesGQL,
    private readonly counterpartyUpdate: UpdateOrganizationCounterpartiesGQL,
    private readonly counterparties: GetCounterpartiesGQL,
    private readonly clientEnable: EnableOrganizationClientGQL,
    private readonly clientDisable: DisableOrganizationClientGQL,
    private readonly clientSecret: GetOrganizationClientSecretGQL
  ) {}

  getLinkableOrganizations(id: string): Observable<GetLinkableOrganizationsQuery["getLinkableOrganizations"]> {
    return this.linkable.fetch({ id: id }).pipe(map((result) => result.data.getLinkableOrganizations));
  }

  getOrganizationLogoDownloadURL(id: string): Observable<string> {
    return this.appSettings
      .getImageSasTokenAndOrigin(id)
      .pipe(map((tokenInfo) => `${tokenInfo.origin}/${getLogoDownloadURI(id)}${tokenInfo.token}`));
  }

  getOrganizationList(id: string): Observable<GetLinkedOrganizationsQuery["getLinkedOrganizations"]> {
    return this.orgList.watch({ id: id }).valueChanges.pipe(map((result) => result.data.getLinkedOrganizations));
  }

  getOrganization(id: string): Observable<FullOrganizationFragment> {
    return this.org
      .watch({ id: id }, { fetchPolicy: "cache-first" })
      .valueChanges.pipe(map((result) => result.data.getOrganization));
  }

  getLogoUploadUrl(id: string): Observable<string> {
    return this.uploadLogoUrl.fetch({ id: id }).pipe(map((value) => value.data.getOrganizationLogoUploadUrl));
  }

  getCounterparties(): Observable<Counterparty[]> {
    return this.counterparties
      .fetch({ organizationId: this.appSettings.organizationId })
      .pipe(map((result) => result.data.getCounterparties));
  }

  createOrganization(input: OrganizationCreateInput): Observable<OrganizationFragment> {
    input.parentId = this.appSettings.organizationId; // Here we want to have the organization which created the Organization
    return this.cOrg
      .mutate(
        { input: input },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                getLinkedOrganizations(refs, helper) {
                  if (!helper.storeFieldName.includes(input.parentId)) return refs;
                  const ref = cache.writeFragment({
                    data: mutationResult.data.createOrganization,
                    fragment: OrganizationFragmentDoc,
                  });
                  if (refs != null && refs.length > 0) {
                    return [...refs, ref];
                  } else {
                    return [ref];
                  }
                },
              },
            }),
        }
      )
      .pipe(map((result) => result.data.createOrganization));
  }

  updateOrganization(input: OrganizationUpdateInput): Observable<FullOrganizationFragment> {
    delete input["functionalCurrency"];
    delete input["supportedCurrencies"];
    delete input["counterpartyIds"];
    delete input["accounts"];
    return this.uOrg.mutate({ input: input }).pipe(map((result) => result.data.updateOrganization));
  }

  updateCurrencies(input: OrganizationCurrenciesInput): Observable<FullOrganizationFragment> {
    const copy = new OrganizationCurrenciesInput();
    copy.id = input.id;
    copy.etag = input.etag;
    copy.functionalCurrency = input.functionalCurrency;
    copy.supportedCurrencies = input.supportedCurrencies;
    return this.currencyUpdate.mutate({ input: copy }).pipe(map((result) => result.data.updateOrganizationCurrencies));
  }

  updateCounterparties(input: OrganizationCounterpartiesInput): Observable<FullOrganizationFragment> {
    const copy = new OrganizationCounterpartiesInput();
    copy.id = input.id;
    copy.etag = input.etag;
    copy.counterpartyIds = input.counterpartyIds;
    return this.counterpartyUpdate
      .mutate({ input: copy })
      .pipe(map((result) => result.data.updateOrganizationCounterparties));
  }

  enableClient(input: OrganizationClientInput): Observable<FullOrganizationFragment> {
    const copy = new OrganizationClientInput();
    copy.id = input.id;
    copy.etag = input.etag;
    return this.clientEnable.mutate({ input: copy }).pipe(map((result) => result.data.enableOrganizationClient));
  }

  disableClient(input: OrganizationClientInput): Observable<FullOrganizationFragment> {
    const copy = new OrganizationClientInput();
    copy.id = input.id;
    copy.etag = input.etag;
    return this.clientDisable.mutate({ input: copy }).pipe(map((result) => result.data.disableOrganizationClient));
  }

  getClientSecret(id: string): Observable<string> {
    return this.clientSecret.fetch({ id: id }).pipe(map((value) => value.data.getOrganizationClientSecret));
  }

  linkOrganization(input: OrganizationLinkInput): Observable<LinkOrganizationMutation["linkOrganization"]> {
    return this.linkOrg
      .mutate(
        { input: input },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                getLinkedOrganizations(refs, helper) {
                  if (!helper.storeFieldName.includes(input.linkedToId)) return refs;
                  const ref = cache.writeFragment({
                    data: mutationResult.data.linkOrganization.linked,
                    fragment: OrganizationFragmentDoc,
                  });
                  if (refs != null && refs.length > 0) {
                    return [...refs, ref];
                  } else {
                    return [ref];
                  }
                },
              },
            }),
        }
      )
      .pipe(map((result) => result.data.linkOrganization));
  }

  unLinkOrganization(input: OrganizationLinkInput): Observable<OrganizationFragment> {
    const proxy = { id: input.id, __typename: "Organization" }; // creating the dummy object here in ordet to create id with cache.identiy method
    return this.unLinkOrg
      .mutate(
        { input: input },
        {
          update: (cache) => (<any>cache).data.delete(cache.identify(proxy)),
        }
      )
      .pipe(map((result) => result.data.unlinkOrganization));
  }

  removeOrganization(input: OrganizationDeleteInput): Observable<boolean> {
    const copy = new OrganizationDeleteInput();
    copy.id = input.id;
    copy.etag = input.etag;

    const proxy = { id: input.id, __typename: "Organization" };
    return this.organizationRemove
      .mutate(
        { input: copy },
        {
          update: (cache, result) =>
            result?.data.removeOrganization.status && (<any>cache).data.delete(cache.identify(proxy)),
        }
      )
      .pipe(map((x) => x.data.removeOrganization.status));
  }
}
