<div class="layout-wrapper p-input-filled layout-overlay-menu" [class.layout-wrapper-menu-active]="sidebarActive">
  <p-toast></p-toast>

  <app-topbar></app-topbar>

  <app-sidebar></app-sidebar>

  <div class="layout-main">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
