import { Directive, Host, Self } from "@angular/core";
import { Calendar } from "primeng/calendar";

// https://github.com/primefaces/primeng/issues/6841
@Directive({
  selector: "[utcFix]",
})
export class UtcFixDirective {
  constructor(@Host() @Self() readonly calendar: Calendar) {
    // https://github.com/primefaces/primeng/blob/ade949cc3af9248c62954e067e2c6db783bfa366/src/app/components/calendar/calendar.ts#L920-L979
    calendar.selectDate = (dateMeta) => {
      let date = new Date(Date.UTC(dateMeta.year, dateMeta.month, dateMeta.day, 0, 0, 0));

      if (calendar.showTime) {
        if (calendar.hourFormat == "12") {
          if (calendar.currentHour === 12) date.setHours(calendar.pm ? 12 : 0);
          else date.setHours(calendar.pm ? calendar.currentHour + 12 : calendar.currentHour);
        } else {
          date.setHours(calendar.currentHour);
        }

        date.setMinutes(calendar.currentMinute);
        date.setSeconds(calendar.currentSecond);
      }

      if (calendar.minDate && calendar.minDate > date) {
        date = calendar.minDate;
        calendar.setCurrentHourPM(date.getHours());
        calendar.currentMinute = date.getMinutes();
        calendar.currentSecond = date.getSeconds();
      }

      if (calendar.maxDate && calendar.maxDate < date) {
        date = calendar.maxDate;
        calendar.setCurrentHourPM(date.getHours());
        calendar.currentMinute = date.getMinutes();
        calendar.currentSecond = date.getSeconds();
      }

      if (calendar.isSingleSelection()) {
        calendar.updateModel(date);
      } else if (calendar.isMultipleSelection()) {
        calendar.updateModel(calendar.value ? [...calendar.value, date] : [date]);
      } else if (calendar.isRangeSelection()) {
        if (calendar.value && calendar.value.length) {
          let startDate = calendar.value[0];
          let endDate = calendar.value[1];

          if (!endDate && date.getTime() >= startDate.getTime()) {
            endDate = date;
          } else {
            startDate = date;
            endDate = null;
          }

          calendar.updateModel([startDate, endDate]);
        } else {
          calendar.updateModel([date, null]);
        }
      }

      calendar.onSelect.emit(date);
    };
  }
}
