import { Injectable } from "@angular/core";
import { ApplicationInsights, DistributedTracingModes, IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { AngularPlugin } from "@zaslavskyv.kleinod21/applicationinsights-angularplugin-js/dist/applicationinsights-angularplugin-js";
import { Router } from "@angular/router";
import { environment } from "apps/frontend/src/environments/environment";
import {CustomErrorHandler} from './app.customErrorHandler'

@Injectable({
  providedIn: "root",
})
export class ApplicationInsightsService {
  private appInsights: ApplicationInsights;

  constructor(private readonly router: Router) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appinsights_connectionstring,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking: false,
        enableAutoRouteTracking:true,
        autoTrackPageVisitTime:true,
        correlationHeaderExcludedDomains: ["auth0.com"],
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router , errorServices: [new CustomErrorHandler()]}
        },
      },
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
  }

  setUserId(userId: string): void {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  startTrackEvent(name: string): void {
    this.appInsights.startTrackEvent(name);
  }

  stopTrackEvent(name: string, properties?: { [key: string]: any }, measurements?: { [key: string]: number }): void {
    this.appInsights.stopTrackEvent(name, properties, measurements);
  }

  clearUserId(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name: uri, uri: uri, isLoggedIn: true });
  }

  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error,
    };
    this.appInsights.trackException(exception);
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  private loadCustomTelemetryProperties(): void {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties["ApplicationPlatform"] = "Web";
      item.properties["ApplicationName"] = "HedgeBench";
    });
  }
}
