import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { AppSettings } from "../services/app.settings";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { UserPermission } from "@hedgebench/shared";

@Injectable()
export class PermissionGuard {
  constructor(
    private readonly authSvc: AuthService,
    private readonly appSettings: AppSettings,
    private readonly router: Router
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // checks general Authentication
    return this.checkPermission(childRoute.data.permission, state);
  }

  // Checks user permissions for routing
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkPermission(route.data.permission, state);
  }

  private checkPermission(
    requiredPermission: UserPermission,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest([this.appSettings.isAuthorized$, this.appSettings.isAuthenticated$]).pipe(
      map(([authenticated, authorized]) => {
        if (!authenticated) {
          this.authSvc.loginWithRedirect({ appState: { target: state.url } });
          return this.router.parseUrl("/accessdenied");
        }
        if (authenticated && !authorized) {
          return this.router.parseUrl("/error");
        }
        if (authorized && (!requiredPermission || this.appSettings.hasPermission(requiredPermission))) {
          return true;
        } else {
          return this.router.parseUrl("/accessdenied");
        }
      })
    );
  }
}
