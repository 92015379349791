import { FxForwardData, ProductCashflow,  } from "./products.model";
import { marketConvention, roundCashflow } from "@hedgebench/shared";
import { ProductShared } from "./product.shared";

export class FxForwardShared extends ProductShared {

  isValid(product: FxForwardData): boolean {
    return super.isValid(product) && product.amount!=null && product.amount>=0 && product.amountCurrency!=null && product.valueDate!=null && product.exchangeRate!=null && product.exchangeRate>0
  }

  getCashflows(product: FxForwardData): ProductCashflow[] {
    if (!(product.amount && product.amount > 0 && product.exchangeRate && product.exchangeRate > 0)) return [];
    else
      return [
        {
          currency: product.buyCurrency,
          valueDate: product.valueDate,
          amount: roundCashflow(
            product.amountCurrency === product.buyCurrency ? product.amount : this.calcAmount(product),
            product.buyCurrency
          ),
        },
        {
          currency: product.sellCurrency,
          valueDate: product.valueDate,
          amount: roundCashflow(
            product.amountCurrency === product.sellCurrency ? -product.amount : -this.calcAmount(product),
            product.sellCurrency
          ),
        },
      ];
  }

  private calcAmount(product: FxForwardData): number {
    let amount: number;
    const convention = marketConvention.getFXConvention(product.buyCurrency, product.sellCurrency);
    if (convention.QuotationMarketStandard) {
      if (product.amountCurrency == product.buyCurrency) amount = product.amount * product.exchangeRate;
      else amount = product.amount / product.exchangeRate;
    } else {
      if (product.amountCurrency == product.sellCurrency) amount = product.amount * product.exchangeRate;
      else amount = product.amount / product.exchangeRate;
    }
    return amount;
  }
}
