import { StaticDataType, StaticDataModel, StaticDataSet } from "./static-data.model";
import { StaticDataEnGB } from "./i18n/static-data.en-GB";
import { StaticDataDeDE } from "./i18n/static-data.de-DE";

const staticDataMap: Record<string, StaticDataSet> = {
  "en-GB": StaticDataEnGB,
  "de-DE": StaticDataDeDE,
};

const mapObject: Map<StaticDataType, Map<string, string>> = new Map();
export function getStaticDataLabel(value: string, datatype: StaticDataType, language: string): string {
  if (value == null) return null;
  if (!mapObject.has(datatype))
    mapObject.set(datatype, new Map(getStaticData(datatype, language)?.map((x) => [x.value, x.label])));
  return mapObject.get(datatype).get(value);
}

export function getStaticData(datatype: StaticDataType, language: string): StaticDataModel[] {
  return staticDataMap[language][datatype].sort((a,b)=>a.label.localeCompare(b.label,[language]));
}
