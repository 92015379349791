export const CurrencyColorPlugin = {
  id: "currencyColor",
  beforeUpdate: function (chart) {
    for (const dataset of chart.data.datasets) {
      // https://stackoverflow.com/a/16348977
      let hash = 0;
      for (let i = 0; i < dataset.label.length; i++) {
        hash = dataset.label.charCodeAt(i) + ((hash << 5) - hash);
      }

      const r = (hash >> (0 * 8)) & 0xff;
      const g = (hash >> (1 * 8)) & 0xff;
      const b = (hash >> (2 * 8)) & 0xff;
      dataset.backgroundColor = `rgba(${r}, ${g}, ${b}, 0.5)`;
    }
  },
};
