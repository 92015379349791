import { Pipe, PipeTransform } from "@angular/core";
import { StaticDataType, getStaticDataLabel } from "@hedgebench/shared";
import { TranslocoService } from "@ngneat/transloco";

@Pipe({
  name: "staticDataPipe",
})
export class StaticDataPipe implements PipeTransform {
  constructor(private readonly transloco: TranslocoService) {}

  transform(value: any, type: StaticDataType): string {
    if (Array.isArray(value)) {
      return value.map((x) => this.transformInternal(x, type)).join(",");
    } else {
      return this.transformInternal(value, type);
    }
  }

  private transformInternal(value: string, type: StaticDataType): any {
    return getStaticDataLabel(value, type, this.transloco.getActiveLang());
  }
}
