<div class="p-fluid" [formGroup]="form" *transloco="let t">
  <p-fieldset [legend]="t('leg.near')">
    <div class="p-grid">
      <div class="p-field p-col-6">
        <label for="buyCurrency">{{ t("buyCurrency.title") }}</label>
        <p-dropdown
          inputId="buyCurrency"
          formControlName="buyCurrency"
          appendTo="body"
          [options]="buyCurrencies"
          [required]="true"
          [placeholder]="t('buyCurrency.placeholder')"
        ></p-dropdown>
        <small *ngIf="form.controls.buyCurrency.errors?.required" class="p-error">
          {{ t("buyCurrency.required") }}
        </small>
      </div>

      <div class="p-field p-col-6">
        <label for="sellCurrency">{{ t("sellCurrency.title") }}</label>
        <p-dropdown
          inputId="sellCurrency"
          formControlName="sellCurrency"
          appendTo="body"
          [options]="sellCurrencies"
          [required]="true"
          [placeholder]="t('sellCurrency.placeholder')"
        ></p-dropdown>
        <small *ngIf="form.controls.sellCurrency.errors?.required" class="p-error">
          {{ t("sellCurrency.required") }}
        </small>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-field p-col-6">
        <label for="amountCurrencyNear">{{ t("currency.title") }}</label>
        <p-dropdown
          inputId="amountCurrencyNear"
          formControlName="amountCurrencyNear"
          appendTo="body"
          [options]="currencies"
          [required]="true"
          [placeholder]="t('currency.placeholder')"
        ></p-dropdown>
        <small *ngIf="form.controls.amountCurrencyNear.errors?.required" class="p-error">
          {{ t("currency.required") }}
        </small>
      </div>

      <div class="p-field p-col-6">
        <label for="amountNear">{{ t("amount.title") }}</label>
        <p-inputNumber
          inputId="amountNear"
          formControlName="amountNear"
          [locale]="locale"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          [required]="true"
          [min]="1"
          [placeholder]="t('amount.placeholder')"
        ></p-inputNumber>
        <small *ngIf="form.controls.amountNear.errors?.required" class="p-error">
          {{ t("amount.required") }}
        </small>
      </div>
    </div>

    <div class="p-field">
      <label for="valueDateNear">{{ t("valueDate.title") }}</label>
      <p-calendar
        utcFix
        inputId="valueDateNear"
        formControlName="valueDateNear"
        appendTo="body"
        [dateFormat]="dateFormat"
        [showIcon]="true"
        [required]="true"
      ></p-calendar>
      <small *ngIf="form.controls.valueDateNear.errors?.required" class="p-error">
        {{ t("valueDate.required") }}
      </small>
    </div>

    <div class="p-field">
      <label for="exchangeRateNear">{{ t("exchangeRate.title") }}</label>
      <p-inputNumber
        inputId="exchangeRateNear"
        formControlName="exchangeRateNear"
        [locale]="locale"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        [required]="true"
        [min]="0.01"
        [placeholder]="t('exchangeRate.placeholder')"
      ></p-inputNumber>
      <small *ngIf="form.controls.exchangeRateNear.errors?.required" class="p-error">
        {{ t("exchangeRate.required") }}
      </small>
    </div>
  </p-fieldset>

  <p-fieldset [legend]="t('leg.far')">
    <div class="p-grid">
      <div class="p-field p-col-6">
        <label>{{ t("buyCurrency.title") }}</label>
        <p-dropdown #buyCurrencyFar [disabled]="true"></p-dropdown>
      </div>

      <div class="p-field p-col-6">
        <label>{{ t("sellCurrency.title") }}</label>
        <p-dropdown #sellCurrencyFar [disabled]="true"></p-dropdown>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-field p-col-6">
        <label>{{ t("currency.title") }}</label>
        <p-dropdown #amountCurrencyFar [disabled]="true"></p-dropdown>
      </div>

      <div class="p-field p-col-6">
        <label>{{ t("amount.title") }}</label>
        <p-inputNumber
          #amountFar
          [locale]="locale"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          [disabled]="true"
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-field">
      <label for="valueDateFar">{{ t("valueDate.title") }}</label>
      <p-calendar
        utcFix
        inputId="valueDateFar"
        formControlName="valueDateFar"
        appendTo="body"
        [dateFormat]="dateFormat"
        [minDate]="minValueDateFar"
        [showIcon]="true"
        [required]="true"
      ></p-calendar>
      <small *ngIf="form.controls.valueDateFar.errors?.required" class="p-error">
        {{ t("valueDate.required") }}
      </small>
    </div>

    <div class="p-field">
      <label for="exchangeRateFar">{{ t("exchangeRate.title") }}</label>
      <p-inputNumber
        inputId="exchangeRateFar"
        formControlName="exchangeRateFar"
        [locale]="locale"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        [required]="true"
        [min]="0.01"
        [placeholder]="t('exchangeRate.placeholder')"
      ></p-inputNumber>
      <small *ngIf="form.controls.exchangeRateFar.errors?.required" class="p-error">
        {{ t("exchangeRate.required") }}
      </small>
    </div>
  </p-fieldset>
</div>
