import { Pipe, PipeTransform } from "@angular/core";
import { translate } from "@ngneat/transloco";

@Pipe({
  name: "enum",
})
export class EnumPipe implements PipeTransform {
  transform(value: any | any[], enumName: string): string {
    return Array.isArray(value)
      ? value.map((v) => this.transformSingle(v, enumName)).join(", ")
      : this.transformSingle(value, enumName);
  }

  private transformSingle(value: any, enumName: string): string {
    return translate(`enum.${enumName}.${value}`);
  }
}
