import { Component, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { ApplicationInsightsService } from "../../shared/services/applicationInsights.service";

@Component({
  selector: "app-login",
  templateUrl: "./logout.component.html",
})
export class LogoutComponent implements OnInit {
  constructor(private readonly auth: AuthService, private readonly appInsights: ApplicationInsightsService) {}

  ngOnInit(): void {
    this.appInsights.clearUserId();
    this.auth.logout({});
  }
}
