import { Component, OnInit } from "@angular/core";
import { PrimeNGConfig } from "primeng/api";
import { AppSettings } from "../shared/services/app.settings";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  intializationFinished: Observable<boolean>;

  constructor(private readonly primengConfig: PrimeNGConfig, readonly appSettings: AppSettings) {
    if (this.isGuarded()) this.intializationFinished = appSettings.isAuthorized$;
    else this.intializationFinished = of(true);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  private isGuarded(): boolean {
    const openRoutes = ["/notfound", "/accessdenied", "/error", "/login", "/logout"];
    return !openRoutes.includes(window.location.pathname);
  }
}
