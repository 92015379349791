import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { ErrorDialogComponent } from "./error-dialog.component";

@Injectable({ providedIn: "root" })
export class ErrorDialogService {
  constructor(private readonly dynamicDialog: DialogService) {}

  showError(header: string, message: string) {
    this.dynamicDialog.open(ErrorDialogComponent, { width: "50%", header: header, data: { message: message } });
  }
}
