import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder,  Validators } from "@angular/forms";
import { getLocaleDateString } from "@hedgebench/shared";
import { TranslocoService } from "@ngneat/transloco";
import { Dropdown } from "primeng/dropdown";
import { InputNumber } from "primeng/inputnumber";
import { combineLatest, startWith } from "rxjs";
import { AppSettings } from "../../../../shared/services/app.settings";
import { ProductForm } from "../../product/product.form";
import { FxSwapProduct } from "../fx-swap.product";

@Component({
  selector: "app-fx-swap-form",
  templateUrl: "./fx-swap-form.component.html",
})
export class FxSwapFormComponent extends ProductForm implements OnInit, AfterViewInit {

  product: FxSwapProduct;

  @ViewChild("amountFar")
  amountFar: InputNumber;

  @ViewChild("buyCurrencyFar")
  buyCurrencyFar: Dropdown;

  @ViewChild("sellCurrencyFar")
  sellCurrencyFar: Dropdown;

  @ViewChild("amountCurrencyFar")
  amountCurrencyFar: Dropdown;

  locale: string;
  dateFormat: string;
  currencies: string[];
  buyCurrencies: string[];
  sellCurrencies: string[];
  minValueDateFar: Date;

  constructor(
    private readonly appSettings: AppSettings,
    private readonly formBuilder: FormBuilder,
    private readonly transloco: TranslocoService
  ) {
    super()
  }

  ngOnInit(): void {
    this.locale = this.transloco.getActiveLang();
    this.dateFormat = getLocaleDateString(this.locale);

    this.form.addControl("buyCurrency", this.formBuilder.control(this.product.productState.buyCurrency, Validators.required));
    this.form.addControl("sellCurrency", this.formBuilder.control(this.product.productState.sellCurrency, Validators.required));
    this.form.addControl("valueDateNear", this.formBuilder.control(this.product.productState.valueDateNear, Validators.required));
    this.form.addControl(
      "exchangeRateNear",
      this.formBuilder.control(
        this.product.productState.exchangeRateNear,
        Validators.compose([Validators.required, Validators.min(0.01)])
      )
    );
    this.form.addControl(
      "amountNear",
      this.formBuilder.control(this.product.productState.amountNear, Validators.compose([Validators.required, Validators.min(1)]))
    );
    this.form.addControl(
      "amountCurrencyNear",
      this.formBuilder.control(this.product.productState.amountCurrencyNear, Validators.required)
    );
    this.form.addControl("valueDateFar", this.formBuilder.control(this.product.productState.valueDateFar, Validators.required));
    this.form.addControl(
      "exchangeRateFar",
      this.formBuilder.control(
        this.product.productState.exchangeRateFar,
        Validators.compose([Validators.required, Validators.min(0.01)])
      )
    );

    this.buyCurrencies = this.appSettings.supportedCurrencies;
    combineLatest([
      this.form.controls.buyCurrency.valueChanges.pipe(startWith(this.product.productState.buyCurrency)),
      this.form.controls.sellCurrency.valueChanges.pipe(startWith(this.product.productState.sellCurrency)),
    ]).subscribe(([buy, sell]) => this.updateCurrencies(buy, sell));
    this.form.controls.buyCurrency.valueChanges
      .pipe(startWith(this.product.productState.buyCurrency))
      .subscribe((buy) => this.updateCurrencies2(buy));
    this.form.controls.valueDateNear.valueChanges
      .pipe(startWith(this.product.productState.valueDateNear))
      .subscribe((valueDateNear) => this.updateValueDateFar(valueDateNear));
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.form.controls.amountNear.valueChanges.pipe(startWith(this.product.productState.amountNear)),
      this.form.controls.exchangeRateNear.valueChanges.pipe(startWith(this.product.productState.exchangeRateNear)),
    ]).subscribe(([amountNear, exchangeRateNear]) => this.updateAmountFar(amountNear, exchangeRateNear));
    combineLatest([
      this.form.controls.amountCurrencyNear.valueChanges.pipe(startWith(this.product.productState.amountCurrencyNear)),
      this.form.controls.buyCurrency.valueChanges.pipe(startWith(this.product.productState.buyCurrency)),
      this.form.controls.sellCurrency.valueChanges.pipe(startWith(this.product.productState.sellCurrency)),
    ]).subscribe(([currencyNear, buy, sell]) => this.updateAmountCurrencyFar(currencyNear, buy, sell));
    this.form.controls.buyCurrency.valueChanges
      .pipe(startWith(this.product.productState.buyCurrency))
      .subscribe((buy) => this.updateSellCurrencyFar(buy));
    this.form.controls.sellCurrency.valueChanges
      .pipe(startWith(this.product.productState.sellCurrency))
      .subscribe((sell) => this.updateBuyCurrencyFar(sell));
  }

  private updateCurrencies(buy: string, sell: string): void {
    this.currencies = [...new Set([buy, sell])].filter((c) => c);
    if (!this.currencies.includes(this.form.controls.amountCurrencyNear.value)) {
      this.form.controls.amountCurrencyNear.setValue(null);
    }
  }

  private updateCurrencies2(buy: string): void {
    this.sellCurrencies = this.appSettings.supportedCurrencies.filter((c) => c !== buy);
    if (!this.sellCurrencies.includes(this.form.controls.sellCurrency.value)) {
      this.form.controls.sellCurrency.setValue(null);
    }
  }

  private updateValueDateFar(valueDateNear: Date): void {
    const minValueDateFar = new Date(valueDateNear);
    minValueDateFar.setDate(minValueDateFar.getDate() + 1);
    this.minValueDateFar = minValueDateFar;

    if (this.form.controls.valueDateFar.value < this.minValueDateFar) {
      this.form.controls.valueDateFar.reset();
    }
  }

  private updateAmountFar(amountNear: number, exchangeRateNear: number): void {
    const amountFar = amountNear * exchangeRateNear;
    this.amountFar.writeValue(isNaN(amountFar) ? null : amountFar);
  }

  private updateSellCurrencyFar(buy: string): void {
    this.sellCurrencyFar.options = buy ? [buy] : null;
    this.sellCurrencyFar.writeValue(buy);
  }

  private updateBuyCurrencyFar(sell: string): void {
    this.buyCurrencyFar.options = sell ? [sell] : null;
    this.buyCurrencyFar.writeValue(sell);
  }

  private updateAmountCurrencyFar(currencyNear: string, buy: string, sell: string): void {
    const currencyFar = currencyNear ? (currencyNear === buy ? sell : buy) : null;
    this.amountCurrencyFar.options = currencyFar ? [currencyFar] : null;
    this.amountCurrencyFar.writeValue(currencyFar);
  }
}
