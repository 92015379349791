import { marketConvention } from "./financial/marketConvention"

export function round(x:number,digits:number) : number
{
  const factor = Math.pow(10,digits)
  return Math.round(x*factor)  / factor
}

export function roundCashflow(amount:number,currency:string) : number
{
  const convention = marketConvention.getCurrencyConvention(currency);
  return round(amount,convention.notionalDecimals);
}
