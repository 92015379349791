import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// Copy Auth Header in order to avoid problems with modified Auhorization Header from Azure Functions
@Injectable()
export class HttpCopyAuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = req.headers.get("Authorization");
    if (token != null) {
      const clone = req.clone({
        headers: req.headers.append("X-Custom-Authorization", `${token}`).delete("Authorization"),
      });
      return next.handle(clone);
    } else {
      return next.handle(req);
    }
  }
}
