import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UserPermission } from "@hedgebench/shared";
import { Sidebar } from "primeng/sidebar";

export interface TableColumn {
  header: string;
  fieldname: string;
  pipeName?: any;
  pipeArgs?: any[];
  sortable: boolean;
  includeInGlobalFilter: boolean;
  routeLink?: (item: unknown) => any[] | null;
}

export interface TableOperation {
  icon: string;
  label: string;
  operation: (item: unknown, event?: unknown) => void;
  disabled?: boolean;
  canOperate?: (item: unknown) => void;
  permission: UserPermission;
}

type sideBarSizeType = "medium" | "large" | "full";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
})
export class TableComponent implements OnInit {
  @Input() title: string;
  @Input() items: unknown[];
  @Input() columns: TableColumn[];
  @Input() tableOperations: TableOperation[];
  @Input() newOperations: TableOperation[];
  @Input() captionOperations: TableOperation[];
  @Input() sidebarSize: sideBarSizeType = "medium";
  @Input() numberOfRows: number = 10;
  @Input() sortField: string;
  @Input() sortOrder: number;
  sideBarSizeMapping = { medium: "p-sidebar-md", large: "p-sidebar-lg" };

  globalFilterFields: string[];

  item: unknown;
  private _showSidebar: boolean;

  @Output() showSidebarChange: EventEmitter<any> = new EventEmitter();

  @Input() get showSidebar(): boolean {
    return this._showSidebar;
  }

  set showSidebar(value: boolean) {
    this._showSidebar = value;
    this.showSidebarChange.emit(value);
  }

  @ViewChild("sidebar")
  sidebar: Sidebar;

  ngOnInit(): void {
    this.globalFilterFields = this.columns.filter((col) => col.includeInGlobalFilter).map((col) => col.fieldname);
  }
}
