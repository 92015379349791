import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { AppSettings } from "../../shared/services/app.settings";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  constructor(
    private readonly appSettings: AppSettings,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.appSettings.isAuthenticated$.subscribe((x) => {
      if (!x) {
        const email = this.route.snapshot.queryParams.email ? this.route.snapshot.queryParams.email : "";
        this.auth.loginWithRedirect({ login_hint: email });
      } else {
        this.router.navigate(["../app"], { relativeTo: this.route });
      }
    });
  }
}
