import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { translate } from "@ngneat/transloco";

export interface HasChanges {
  hasChanges(): boolean;
}

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<HasChanges> {
  canDeactivate(target: HasChanges) {
    if (target.hasChanges()) {
      return window.confirm(translate("common.unsavedChanges"));
    }
    return true;
  }
}
