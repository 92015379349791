import { Directive, Host, Input} from "@angular/core";
import { CurrencyPairDate } from "@hedgebench/shared";
import { Calendar } from "primeng/calendar";

@Directive({
  selector: "[dateInput]",
})
export class DateInputDirective  {
  @Input() ccy1: string;
  @Input() ccy2: string;
  @Input() referenceDate:Date;
  @Input() type: "VALUEDATE" | "EXPIRYDATE" = "VALUEDATE";

  constructor(@Host() private readonly input: Calendar) {
    input.onInput.subscribe(() => this.updateValue());
  }


  updateValue(): void {
    let value: string = this.input.containerViewChild?.nativeElement?.children[0]?.value;
    value = value?.toUpperCase();
    if (
      value &&
      value.length > 1 &&
      (value.endsWith("D") ||
        value.endsWith("W") ||
        value.endsWith("Y") ||
        value.endsWith("SN") ||
        value.endsWith("ON") ||
        value.endsWith("TN") ||
        value.endsWith("M"))
    ) {
      const pairdate = new CurrencyPairDate([this.ccy1, this.ccy2]);
      if (this.type == "VALUEDATE") this.input.updateModel(pairdate.getExpiryAndValueDateFromInput(value,this.referenceDate)?.valueDate);
      else this.input.updateModel(pairdate.getExpiryAndValueDateFromInput(value,this.referenceDate)?.expiryDate);
    }
  }
}
