<div class="exception-body accessdenied-body" *transloco="let t">
  <div class="exception-panel">
    <img src="assets/layout/images/exception/icon-access.png" />

    <p-button *ngIf="!loggedIn" [label]="t('login.title')" [routerLink]="['/login']"></p-button>
    <p-button *ngIf="loggedIn" [label]="t('home.title')" [routerLink]="['/']"></p-button>
  </div>

  <div class="exception-band">
    <div class="exception-content">
      <h3 class="p-underline">{{ t("accessDenied.title") }}</h3>
      <p *ngIf="loggedIn">{{ t("accessDenied.noPermissions") }}</p>
      <p *ngIf="!loggedIn">{{ t("accessDenied.notLoggedIn") }}</p>
      <img src="assets/layout/images/logo.png" />
    </div>
  </div>
</div>
