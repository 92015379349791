export enum StaticDataType {
  MartialStatus = "StaticDataType.MartialStatus",
  Gender = "StaticDataType.Gender",
  Salutation = "StaticDataType.Salutation",
  Countries = "StaticDataType.Countries",
  GermanStates = "StaticDataType.GermanState",
  Languages = "StaticDataType.Languages",
  Occupation = "StaticDataType.Occupation",
  Education = "StaticDataType.Education",
  CareFacilities = "StaticDataType.CareFacilities",
  LanguageLevels = "StatStaticDataType.LanguageLevels",
  ProfessionalFields = "StatStaticDataType.ProfessionalFields",
  AllowedUploadFileTypes = "StatStaticDataType.AllowedUploadFileTypes",
  WorkArea = "StatStaticDataType.WorkArea",
  Currency = "StatStaticDataType.Currency",
  Product = "StatStaticDataType.Product",
}

export interface StaticDataModel {
  value: string;
  label: string;
}

export type StaticDataSet = Record<StaticDataType, StaticDataModel[]>;
