import {  FormGroup, } from "@angular/forms";
import {  Product } from "./product";




export abstract class ProductForm  {
  abstract product: Product;

  form: FormGroup;
}

