<div class="p-dynamicdialog" *transloco="let t">
  <div class="p-dialog-content">
    <p>{{ errorMessage }}</p>
  </div>
  <div class="p-dialog-footer">
    <button
      pButton
      pRipple
      [label]="t('common.close')"
      icon="pi pi-check"
      class="p-button-warning p-mr-2 p-mb-2"
      (click)="onClose()"
    ></button>
  </div>
</div>
