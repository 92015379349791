import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { MainComponent } from "./structure/main/main.component";
import { LoginComponent } from "./pages/login/login.component";
import { NotFoundComponent } from "./pages/notfound/notfound.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { AccessDeniedComponent } from "./pages/acessdenied/accessdenied.component";
import { ErrorComponent } from "./pages/error/error.component";

export const routes: Routes = [
  { path: "notfound", component: NotFoundComponent },
  { path: "accessdenied", component: AccessDeniedComponent },
  { path: "error", component: ErrorComponent },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  {
    path: "app",
    component: MainComponent,
    children: [
      {
        path: "workbench",
        loadChildren: () => import("./features/workbench/workbench.module").then((m) => m.WorkbenchModule),
      },
      {
        path: "cashflows",
        loadChildren: () => import("./features/cashflows/cashflows.module").then((m) => m.CashflowsModule),
      },
      {
        path: "positions",
        children: [
          {
            path: "portfolios",
            loadChildren: () => import("./features/portfolios/portfolios.module").then((m) => m.PortfoliosModule),
          },
          {
            path: "transactions",
            loadChildren: () => import("./features/transactions/transactions.module").then((m) => m.TransactionsModule),
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "organizations",
            loadChildren: () =>
              import("./features/organizations/organizations.module").then((m) => m.OrganizationsModule),
          },
          {
            path: "user-groups",
            loadChildren: () => import("./features/user-groups/user-groups.module").then((m) => m.UserGroupsModule),
          },
          {
            path: "users",
            loadChildren: () => import("./features/users/users.module").then((m) => m.UsersModule),
          },
        ],
      },
    ],
  },
  { path: "", redirectTo: "/app", pathMatch: "full" },
  { path: "**", redirectTo: "/notfound" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
