import {  NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { createModuleProductFactory, PRODUCT } from "../product/product";
import { factoryDependencies } from "../product/product";
import { FxSwapFormComponent } from "./fx-swap-form/fx-swap-form.component";
import { FxSwapProduct } from "./fx-swap.product";

@NgModule({
  imports: [SharedModule],
  declarations: [FxSwapFormComponent],
  providers: [
    {
      provide: PRODUCT,
      useFactory: createModuleProductFactory(FxSwapProduct),
      deps: factoryDependencies,
      multi: true,
    },
  ],
})
export class FxSwapModule {}
