<p-card *transloco="let t">
  <div class="p-d-flex p-flex-column app-form p-card-content">
    <p-toolbar *ngIf="this.title && this.newOperations && this.newOperations.length > 0" styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <h3>{{ title }}</h3>
      </ng-template>
      <ng-template *ngIf="newOperations" pTemplate="right">
        <ng-container *ngFor="let op of newOperations">
          <p-button
            *appPermissions="op.permission"
            [label]="op.label"
            [icon]="op.icon"
            class="p-button-success p-mr-2 p-mb-2"
            (onClick)="op.operation($event)"
            [disabled]="op.disabled"
          ></p-button>
        </ng-container>
      </ng-template>
    </p-toolbar>

    <p-table
      #dt
      [value]="items"
      [columns]="columns"
      [rows]="numberOfRows"
      [paginator]="true"
      [globalFilterFields]="globalFilterFields"
      [rowHover]="true"
      [scrollable]="true"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      scrollHeight="flex"
      dataKey="id"
      class="app-form"
      styleClass="p-datatable app-form"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-flex-row p-flex-md-row p-jc-md-end table-header">
          <h3 *ngIf="this.title && (!this.newOperations || this.newOperations.length === 0)" class="p-mr-auto">
            {{ title }}
          </h3>

          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($any($event.target).value, 'contains')"
              [placeholder]="t('common.search')"
            />
          </span>

          <ng-container *ngFor="let op of captionOperations">
            <div class="p-mr-2"></div>
            <p-button
              *appPermissions="op.permission"
              [label]="op.label"
              [icon]="op.icon"
              class="p-button-success p-mr-2 p-mb-2"
              (onClick)="op.operation($event)"
              [disabled]="op.disabled"
            ></p-button>
          </ng-container>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.fieldname : null">
            {{ col.header }} <p-sortIcon *ngIf="col.sortable" [field]="col.fieldname"></p-sortIcon>
          </th>
          <th *ngIf="tableOperations && tableOperations.length > 0"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <ng-container *ngIf="col.routeLink && col.routeLink(rowData); else plain">
              <ng-container *ngIf="col.pipeName">
                <a href="javascript:void(0)" [routerLink]="col.routeLink(rowData)">{{
                  rowData[col.fieldname] | dynamicPipe: col.pipeName:col.pipeArgs
                }}</a>
              </ng-container>
              <ng-container *ngIf="!col.pipeName">
                <a href="javascript:void(0)" [routerLink]="col.routeLink(rowData)">{{ rowData[col.fieldname] }}</a>
              </ng-container>
            </ng-container>
            <ng-template #plain>
              <ng-container *ngIf="col.pipeName">
                {{ rowData[col.fieldname] | dynamicPipe: col.pipeName:col.pipeArgs }}
              </ng-container>
              <ng-container *ngIf="!col.pipeName">
                {{ rowData[col.fieldname] }}
              </ng-container>
            </ng-template>
          </td>
          <td *ngIf="tableOperations && tableOperations.length > 0">
            <ng-container *ngFor="let op of tableOperations">
              <ng-container *ngIf="!op.canOperate || op.canOperate(rowData)">
                <p-button
                  *appPermissions="op.permission"
                  [pTooltip]="op.label"
                  [icon]="op.icon"
                  class="p-button-rounded p-button-success p-mr-2"
                  (onClick)="op.operation(rowData, $event)"
                  [disabled]="op.disabled"
                ></p-button>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>

<p-sidebar
  #sidebar
  position="right"
  [(visible)]="showSidebar"
  [styleClass]="this.sideBarSizeMapping[this.sidebarSize]"
  [dismissible]="false"
  [showCloseIcon]="false"
  [fullScreen]="this.sidebarSize === 'full'"
>
  <ng-content></ng-content>
</p-sidebar>
<p-toast></p-toast>
