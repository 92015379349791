import { ComponentFactoryResolver } from "@angular/core";
import { FxForwardData, FxForwardShared, ProductType } from "@hedgebench/shared";
import { Product, ProductInstance } from "../product/product";
import { FxForwardFormComponent } from "./fx-forward-form/fx-forward-form.component";
import { translate } from "@ngneat/transloco";
import { ExchangeRatePipe } from "../../../shared/pipes/exchangeRate.pipe";
import { AmountPipe } from "../../../shared/pipes/amount.pipe";
import { SwapPointsPipe } from "../../../shared/pipes/swapPoints.pipe";
import { FxForward } from "@hedgebench/graphql";

@ProductInstance(ProductType.FxForward)
export class FxForwardProduct extends Product {
  productState: FxForwardData = new FxForward();

  getInputChartData() {
    if (this.isValid) {
      const cashflowBuyCurrency = this.getAggregatedCashflow(
        this.productState.buyCurrency,
        this.productState.valueDate
      );
      const cashflowSellCurrency = this.getAggregatedCashflow(
        this.productState.sellCurrency,
        this.productState.valueDate
      );
      return {
        labels: [this.productState.valueDate],
        sublabels: [this.productState.termOption],
        datasets: [
          {
            label: this.productState.buyCurrency,
            data: [cashflowBuyCurrency],
            datalabels: { anchor: "end", align: 15 },
            maxBarThickness: 30,
          },
          {
            label: this.productState.sellCurrency,
            data: [cashflowSellCurrency],
            datalabels: { anchor: "start", align: -15 },
            maxBarThickness: 30,
          },
        ],
      };
    } else return {};
  }

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    exchangePipe: ExchangeRatePipe,
    amountPipe: AmountPipe,
    swapPointsPipe: SwapPointsPipe
  ) {
    super(componentFactoryResolver, exchangePipe, amountPipe, swapPointsPipe);
    this.formComponentFactoryProduct = this.componentFactoryResolver.resolveComponentFactory(FxForwardFormComponent);
    this.productShared = new FxForwardShared();
  }

  getProductDescription(language?: string): string {
    if (this.isValid()) {
      const translateKey = this.productState.termOption
        ? "productSummary.FXFORWARD_TERMOPTION"
        : "productSummary.FXFORWARD";
      const text = translate(
        translateKey,
        {
          exchangeRate: this.exchangeRatePipe.transform(
            this.productState.exchangeRate,
            this.productState.buyCurrency,
            this.productState.sellCurrency
          ),
          operation:
            this.productState.amountCurrency == this.productState.buyCurrency
              ? translate("productSummary.buy")
              : translate("productSummary.sell"),
          amount:
            this.productState.amountCurrency == this.productState.buyCurrency
              ? this.amountPipe.transform(
                  Math.abs(this.getAggregatedCashflow(this.productState.buyCurrency, this.productState.valueDate)),
                  this.productState.buyCurrency
                )
              : this.amountPipe.transform(
                  Math.abs(this.getAggregatedCashflow(this.productState.sellCurrency, this.productState.valueDate)),
                  this.productState.sellCurrency
                ),
          counterAmount:
            this.productState.amountCurrency == this.productState.sellCurrency
              ? this.amountPipe.transform(
                  Math.abs(this.getAggregatedCashflow(this.productState.buyCurrency, this.productState.valueDate)),
                  this.productState.buyCurrency
                )
              : this.amountPipe.transform(
                  Math.abs(this.getAggregatedCashflow(this.productState.sellCurrency, this.productState.valueDate)),
                  this.productState.sellCurrency
                ),
          valueDate: (this.productState.valueDate as Date).toLocaleDateString(),
          termOptionDate: (this.productState.termOption as Date)?.toLocaleDateString(),
        },
        language
      );
      return text;
    } else {
      return "";
    }
  }
}
