import { Injector, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dynamicPipe",
})
export class DynamicPipe implements PipeTransform {
  constructor(private readonly injector: Injector) {}

  transform(value: any, pipeToken: any, pipeArgs?: any[]): any {
    if (!pipeToken) {
      return value;
    } else {
      const pipe = this.injector.get(pipeToken);
      if (pipeArgs != null) return pipe.transform(value, ...pipeArgs);
      else return pipe.transform(value);
    }
  }
}
