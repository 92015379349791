import { Component } from "@angular/core";

@Component({
  selector: "app-blockui",
  templateUrl: "./blockui.component.html",
  styleUrls: ["./blockui.component.scss"],
})
export class BlockUIComponent {
  message: string;
}
