import { ComponentFactory, ComponentFactoryResolver, InjectionToken, ViewContainerRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ProductCashflow, ProductData, ProductType } from "@hedgebench/shared";
import { translate } from "@ngneat/transloco";
import { ProductShared } from "libs/shared/src/lib/products/product.shared";
import "reflect-metadata";
import { BehaviorSubject } from "rxjs";
import { AmountPipe } from "../../../shared/pipes/amount.pipe";
import { ExchangeRatePipe } from "../../../shared/pipes/exchangeRate.pipe";
import { SwapPointsPipe } from "../../../shared/pipes/swapPoints.pipe";
import { DisplayChartComponent } from "./input-chart/displayChart.component";
import { DisplaySummaryComponent } from "./input-summary/displaySummary.component";
import { ProductForm } from "./product.form";

export abstract class Product {
  protected formComponentFactoryProduct: ComponentFactory<ProductForm>;
  private formComponentFactorySummary: ComponentFactory<DisplaySummaryComponent>;
  private formComponentFactoryChart: ComponentFactory<DisplayChartComponent>;
  abstract productState: ProductData;
  protected productShared: ProductShared;
  public productDescription$  = new BehaviorSubject<string>("");
  public chartOption$ = new BehaviorSubject<any>(null);
  public chartData$ =  new BehaviorSubject<any>(null);

  private emptyChart =  {
    labels: [],
    sublabels: [],
    datasets: [
      {
        label: "",
        data: [0],
        datalabels: { anchor: "end", align: 15 },
        maxBarThickness: 30,
      },
      {
        label: "",
        data: [0],
        datalabels: { anchor: "start", align: -15 },
        maxBarThickness: 30,
      },
    ],
  };


  constructor(
    protected readonly componentFactoryResolver: ComponentFactoryResolver,
    protected readonly exchangeRatePipe: ExchangeRatePipe,
    protected readonly amountPipe: AmountPipe,
    protected readonly swapPointsPipe: SwapPointsPipe
  ) {
     this.formComponentFactorySummary = this.componentFactoryResolver.resolveComponentFactory(DisplaySummaryComponent);
     this.formComponentFactoryChart =this.componentFactoryResolver.resolveComponentFactory(DisplayChartComponent);
     this.chartOption$.next(this.getInputChartOptions());
  }

  update(state: any) {
    this.productState = state;
    if (this.isValid())
    {
      this.productDescription$.next(this.getProductDescription())
      this.chartData$.next(this.getInputChartData())
    } else
    {
      this.productDescription$.next(translate("productSummary.noDescription"))
      this.chartData$.next(this.emptyChart)
    }

  }

  abstract getProductDescription(language?: string): string;
  abstract getInputChartData(): any;

  renderForm(view: ViewContainerRef, form: FormGroup): void {
    view.clear();
    const componentRef = view.createComponent(this.formComponentFactoryProduct);
    componentRef.instance.product = this;
    componentRef.instance.form = form;
  }

  renderSummary(view: ViewContainerRef): void {
    view.clear();
    const componentRef = view.createComponent(this.formComponentFactorySummary);
    componentRef.instance.product = this;
  }

  renderChart(view: ViewContainerRef): void {
    view.clear();
    const componentRef = view.createComponent(this.formComponentFactoryChart);
    componentRef.instance.product = this;
  }

  getInputChartOptions(): any {
    return {
      plugins: {
        datalabels: {
          offset: 20,
          display: (x) => !!x.dataset.data[x.dataIndex],
        },
        legend: false,
        tooltip: false,
        format: {
          scales: {
            x: { mode: "date" },
            y: { mode: "decimal", options: { notation: "compact" } },
          },
          datalabels: {
            label: { mode: "currency", options: { notation: "compact", absolute: true } },
          },
        },
      },
      scales: { x: { stacked: true, type: "timeseries", ticks: { source: "data" } } },
    };
  }

  getAggregatedCashflow(currency: string, valueDate: Date): number {
    return this.productShared.getAggregatedCashflow(this.productState, currency, valueDate);
  }

  getCashflows(): ProductCashflow[] {
    return this.productShared.getCashflows(this.productState);
  }

  isValid() : boolean
  {
    return this.productShared.isValid(this.productState);
  }

}

export const PRODUCT: InjectionToken<() => Product> = new InjectionToken("product");

export function ProductInstance(type: ProductType): ClassDecorator {
  return (target) => {
    Reflect.defineMetadata(PRODUCT.toString(), type, target.prototype);
    return target;
  };
}

export function getProductType(target: any): ProductType {
  return Reflect.getMetadata(PRODUCT.toString(), target) as ProductType;
}

export function createModuleProductFactory<T>(type: (new (cfr: ComponentFactoryResolver,exchangePipe:ExchangeRatePipe,amountPipe:AmountPipe,swapPointsPipe:SwapPointsPipe) => T))
{
  return function (cfr:ComponentFactoryResolver,exchangePipe:ExchangeRatePipe,amountPipe:AmountPipe,swapPointsPipe:SwapPointsPipe)
  {
    return ()=>new type(cfr,exchangePipe,amountPipe,swapPointsPipe);
  }
}

export const factoryDependencies = [ComponentFactoryResolver,ExchangeRatePipe,AmountPipe,SwapPointsPipe]



