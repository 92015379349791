import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ApplicationInsightsService } from "./applicationInsights.service";

interface SpinnerTimer {
  timerHandle: any;
  spinnerStarted: boolean;
}

@Injectable()
export class UIBlockingHttpInterceptor implements HttpInterceptor {
  private count: number = 0;
  constructor(private readonly spinner: NgxSpinnerService, private readonly appInsights: ApplicationInsightsService) {}

  spinnerStart(): void {
    this.count++;
    this.spinner.show("Main");
  }

  spinnerStop(force: boolean) {
    this.count--;
    if (this.count <= 0 || force) this.spinner.hide("Main");
  }

  timerStart(state: SpinnerTimer) {
    if (state.timerHandle < 0) {
      state.timerHandle = setTimeout(() => {
        state.spinnerStarted = true;
        this.spinnerStart();
        this.appInsights.startTrackEvent("Spinner");
      }, 950);
    }
  }

  timerStop(state: SpinnerTimer) {
    if (state != null && state.timerHandle != null && state.timerHandle >= 0) {
      clearTimeout(state.timerHandle);
      if (state.spinnerStarted) {
        this.spinnerStop(false);
        state.spinnerStarted = false;
        this.appInsights.stopTrackEvent("Spinner", { timerHandle: state.timerHandle });
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.endsWith("api/graphql") || req?.body?.operationName == "keepAlive") {
      return next.handle(req);
    } else {
      const state: SpinnerTimer = { spinnerStarted: false, timerHandle: -1 };
      return next.handle(req).pipe(
        tap((x) => {
          if (
            x.type == HttpEventType.Sent &&
            req?.body?.operationName != "keepAlive" &&
            req?.body?.operationName != "getSettings"
          ) {
            this.timerStart(state);
          }
        }),
        finalize(() => {
          this.timerStop(state);
        })
      );
    }
  }
}
