import {  NgModule } from "@angular/core";

import { SharedModule } from "../../../shared/shared.module";
import { PRODUCT,createModuleProductFactory,factoryDependencies } from "../product/product";
import { FxForwardFormComponent } from "./fx-forward-form/fx-forward-form.component";
import { FxForwardProduct } from "./fx-forward.product";

@NgModule({
  imports: [SharedModule],
  declarations: [FxForwardFormComponent],
  providers: [
    {
      provide: PRODUCT,
      useFactory: createModuleProductFactory(FxForwardProduct),
      deps: factoryDependencies,
      multi: true,
    },
  ],
})
export class FxForwardModule {}
