import { Injectable, OnDestroy } from "@angular/core";
import { Subject, Subscription, ReplaySubject } from "rxjs";
import { ErrorResponse } from "@apollo/client/link/error";
import {
  GetSettingsGQL,
  OrganizationRole,
  KeepAliveGQL,
  GetImageAccessTokenGQL,
  ImageAccessTokenResult,
  GetHolidayCalendarDataGQL,
} from "@hedgebench/graphql";
import { AuthService } from "@auth0/auth0-angular";
import { UserPermission } from "@hedgebench/shared";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { environment } from "apps/frontend/src/environments/environment";
import { ErrorDialogService } from "../components/error-dialog/error-dialog.service";
import { of, Observable } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ApplicationInsightsService } from "./applicationInsights.service";
import { translate } from "@ngneat/transloco";
import {HolidayCalendar} from "@hedgebench/shared";

@Injectable({ providedIn: "root" })
export class AppSettings implements OnDestroy {
  static graphQLError$: Subject<ErrorResponse> = new Subject<ErrorResponse>();

  private userPermissions: UserPermission[];
  auth0UserId: string;
  userId: string;
  userName: string;
  userInitials: string;
  userDisplayName: string;
  organizationId: string;
  organizationName: string;
  organizationCode: string;
  organizationRole: OrganizationRole;
  creatorOrganizationId: string;
  functionalCurrency: string;
  private _supportedCurrencies: string[]
  get supportedCurrencies() : string[]
  {
    return this._supportedCurrencies;
  }
  set supportedCurrencies(currencies:string[])
  {
    this._supportedCurrencies = currencies
    this.initializeHolidayCalendar();
  }

  counterpartyIds: string[];

  private authSub: Subscription;
  private userSub: Subscription;
  private graphQLErrorSub: Subscription;
  private keepAliveSub: Subscription = null;
  private keepAliveIntervalHandle: any;

  isAuthorized$ = new ReplaySubject<boolean>(1);
  isAuthenticated$: Observable<boolean>;
  private internalAuthorized: boolean = false;
  private imageSasTokens = new Map<string, ImageAccessTokenResult>();

  get graphQLError$(): Subject<ErrorResponse> {
    return AppSettings.graphQLError$;
  }

  hasPermission(permission: UserPermission) {
    return this.userPermissions != null ? this.userPermissions.includes(permission) : false;
  }

  constructor(
    private readonly getSQL: GetSettingsGQL,
    private readonly keepAlive: KeepAliveGQL,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly errorService: ErrorDialogService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly imageTokenQuery: GetImageAccessTokenGQL,
    private readonly appInsights: ApplicationInsightsService,
    private readonly holiday: GetHolidayCalendarDataGQL
  ) {
    // Fill Userpermissions as soon user is authenticated, clear it when user is not authenticated anymore
    //this.authSub = authService.isAuthenticated$.subscribe((auth) => this.doAuthorization(auth, getSQL, authService));
    this.isAuthenticated$ = authService.isAuthenticated$.pipe(tap((auth) => this.doAuthorization(auth)));
    this.userSub = authService.user$.pipe(filter((user) => !!user)).subscribe((user) => {
      this.userName = user.name;
      this.userDisplayName = `${user.family_name}, ${user.given_name}`;
      this.userInitials =
        user.given_name && user.family_name ? (user.given_name[0] + user.family_name[0]).toUpperCase() : "";
    });
    this.graphQLError$.subscribe((error) => this.graphQLErrorHandling(error));
  }

  private graphQLErrorHandling(error: ErrorResponse) {
    error.graphQLErrors?.map((e) => {
      const extensions = e.extensions;
      if (extensions?.exception.status === 403) {
        this.router.navigate(["/accessdenied"]);
      }
      if (extensions?.code == "BAD_USER_INPUT") {
        this.appInsights.logEvent("BAD_USER_INPUT");
        const errorMessageProps: Record<string, any> = {};
        for (const x in extensions) {
          const number = +x;
          if (number > 0) {
            errorMessageProps[extensions[x].key] = extensions[x].value;
          }
        }
        const errorMessage = translate(`graphql.${extensions["0"].value}`, errorMessageProps);
        this.errorService.showError(translate("user.badInput.title"), errorMessage);
      } else if (extensions?.code == "OPTIMISTICCONCURRENCYVIALOTAION") {
        this.appInsights.logEvent("OPTIMISTICCONCURRENCYVIALOTAION");
        this.errorService.showError(translate("user.concurrency.title"), translate("user.concurrency.message"));
      } else {
        error.graphQLErrors?.forEach((x) => this.appInsights.logException(x));
        let message: string = error.operation.operationName + " ";
        error.graphQLErrors?.map((val) => (message = message + val.message));
        message = message + error?.networkError?.message;
        message =
          message +
          " " +
          extensions?.code +
          " " +
          extensions?.exception?.response?.message +
          " " +
          extensions?.exception?.stacktrace;
        this.messageService.add({ severity: "error", summary: message, life: 4000, closable: true });
      }
    });
    if (error.networkError != null) {
      this.appInsights.logException(error.networkError);
      let message: string = error.operation.operationName + " ";
      error.graphQLErrors?.map((val) => (message = message + val.message));
      message = message + error.networkError?.message;
      this.messageService.add({ severity: "error", summary: message, life: 4000, closable: true });
    }
  }

  private doAuthorization(authenticated: boolean) {
    if (authenticated) {
      if (!this.internalAuthorized) {
        this.internalAuthorized = true;
        this.getSQL.fetch().subscribe(
          (val) => {
            const settings = val.data.getSettings;
            this.userPermissions = <UserPermission[]>settings.userPermissions;
            this.organizationId = settings.organizationId;
            this.auth0UserId = settings.auth0UserId;
            this.userId = settings.userId;
            this.organizationCode = settings.organizationCode;
            this.organizationName = settings.organizationName;
            this.organizationRole = settings.organizationRole;
            this.creatorOrganizationId = settings.creatorOrganizationId;
            this.functionalCurrency = settings.functionalCurrency;
            this.supportedCurrencies = settings.supportedCurrencies;
            this.counterpartyIds = settings.counterpartyIds;
            this.appInsights.setUserId(this.userId);
            this.isAuthorized$.next(true);
            this.startPollingToKeepServerAlive();
            this.internalAuthorized = true;
          },
          () => {
            (this.internalAuthorized = false), this.isAuthorized$.next(false);
          }
        );
      }
    } else {
      const email = this.activatedRoute.snapshot.queryParams.email
        ? this.activatedRoute.snapshot.queryParams.email
        : "";
      this.authService.loginWithRedirect({ login_hint: email, appState: { target: new URL(document.URL).pathname } });
    }
  }

  private async initializeHolidayCalendar()
  {
    this.holiday.fetch({input:{currencies:this.supportedCurrencies}}).subscribe(x=>{
      HolidayCalendar.setHolidyData(x.data.getHolidayData.data)
      })
  }

  startPollingToKeepServerAlive(): void {
    // We poll every 10 minutes in order to keep the server alive
    if (this.keepAliveSub == null) {
      const queryRef = this.keepAlive.watch();
      this.keepAliveSub = queryRef.valueChanges.subscribe((x) => {
        if (!environment.production) console.log("Keepalive: ", x.data.keepAlive.serverTime);
      });
      this.keepAliveIntervalHandle = setInterval(() => queryRef.refetch(), 1000 * 60 * 10);
    }
  }

  getImageSasTokenAndOrigin(organizationId: string): Observable<{ origin: string; token: string }> {
    if (this.imageSasTokens.has(organizationId)) {
      const tokenInfo = this.imageSasTokens.get(organizationId);
      if ((tokenInfo.expiresOn as Date) > new Date()) {
        return of({ origin: tokenInfo.origin, token: tokenInfo.sasToken });
      }
    }
    return this.isAuthorized$.pipe(
      switchMap(() =>
        this.imageTokenQuery.fetch({ input: { organizationId: organizationId } }).pipe(
          tap((data) => this.imageSasTokens.set(organizationId, data.data.getImageAccessToken)),
          map((x) => {
            return { origin: x.data.getImageAccessToken.origin, token: x.data.getImageAccessToken.sasToken };
          })
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.userSub.unsubscribe();
    this.graphQLErrorSub.unsubscribe();
    clearInterval(this.keepAliveIntervalHandle);
    this.keepAliveSub.unsubscribe();
  }

  navigateToNotFound(): void {
    this.router.navigate(["/notfound"]);
  }
}
