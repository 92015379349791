import { Component, HostBinding } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { translate } from "@ngneat/transloco";
import { MainComponent } from "../main/main.component";
import { MenuService, MenueSection } from "../menu/menu.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent {
  @HostBinding("class.no-sidebar")
  hidden: boolean = false;

  readonly items = [
    {
      section: MenueSection.Workbench,
      icon: "pi pi-fw pi-home",
      label: translate("workbench.title"),
      routerLink: ["/app"],
    },
    {
      section: MenueSection.Cashflows,
      icon: "pi pi-fw pi-money-bill",
      label: translate("cashflows.title"),
    },
    {
      section: MenueSection.Positions,
      icon: "pi pi-fw pi-book",
      label: translate("positions.title"),
    },
    {
      section: MenueSection.Settings,
      icon: "pi pi-fw pi-cog",
      label: translate("settings.title"),
    },
  ];

  constructor(
    public readonly main: MainComponent,
    private readonly menuService: MenuService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.redirectIfOnlyOneItem();
    this.setActiveItem();
  }

  hasItems(section: MenueSection): boolean {
    return this.menuService.getMenueItemsForSection(section).length > 0;
  }

  private redirectIfOnlyOneItem(): void {
    const menuItems = this.menuService.getAllMenuItems();
    if (menuItems.length === 1) {
      this.hidden = true;
      if (this.router.url === "/app") {
        this.router.navigate(menuItems[0].routerLink, { relativeTo: this.route });
        return;
      }
    }
  }

  private setActiveItem(): void {
    if (this.router.url === "/app") {
      this.main.activeTabIndex = 0;
      return;
    }

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].routerLink) {
        const isActive = this.router.isActive(
          this.router.createUrlTree(this.items[i].routerLink, { relativeTo: this.route }),
          true
        );
        if (isActive) {
          this.main.activeTabIndex = i;
          return;
        }
      } else {
        const items = this.menuService.getMenueItemsForSection(this.items[i].section);
        const isActive = items.some((x) =>
          this.router.isActive(
            this.router.createUrlTree(x.routerLink, { relativeTo: this.route }),
            x.routerLinkActiveOptions?.exact === false ? false : true
          )
        );
        if (isActive) {
          this.main.activeTabIndex = i;
          return;
        }
      }
    }
  }
}
