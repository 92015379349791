import { Injectable } from "@angular/core";
import { UserPermission } from "@hedgebench/shared";
import { translate } from "@ngneat/transloco";
import { MenuItem } from "primeng/api";
import { Subject } from "rxjs";
import { AppSettings } from "../../shared/services/app.settings";

export enum MenueSection {
  Workbench,
  Cashflows,
  Positions,
  Settings,
}

interface ExtendedMenuItem extends MenuItem {
  permissions: UserPermission[];
}

@Injectable({ providedIn: "root" })
export class MenuService {
  private menuSource = new Subject<string>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  constructor(private readonly appSettings: AppSettings) {}

  onMenuStateChange(key: string): void {
    this.menuSource.next(key);
  }

  reset(): void {
    this.resetSource.next(undefined);
  }

  getAllMenuItems(): MenuItem[] {
    return Object.keys(MenueSection)
      .map((key) => MenueSection[key])
      .map((section) => this.getMenueItemsForSection(section))
      .reduce((acc, cur) => [...acc, ...cur], []);
  }

  getMenueItemsForSection(menueSection: MenueSection): MenuItem[] {
    let model: ExtendedMenuItem[] = [];
    switch (menueSection) {
      case MenueSection.Workbench:
        model = [
          {
            label: translate("workbench.title"),
            icon: "pi pi-fw pi-chart-bar",
            routerLink: ["workbench"],
            permissions: [UserPermission.MYORG_CUD],
          },
        ];
        break;
      case MenueSection.Settings:
        model = [
          {
            label: translate("organization.my"),
            icon: "pi pi-fw pi-map-marker",
            routerLink: ["settings/organizations/my"],
            permissions: [UserPermission.MYORG_CUD],
          },
          {
            label: translate("organizations.title"),
            icon: "pi pi-fw pi-th-large",
            routerLink: [`settings/organizations/${this.appSettings.organizationId}`],
            permissions: [UserPermission.ORG_CUD],
          },
          {
            label: translate("users.title"),
            icon: "pi pi-fw pi-user",
            routerLink: [`settings/users/${this.appSettings.organizationId}`],
            permissions: [UserPermission.USER_CUD],
          },
          {
            label: translate("userGroups.title"),
            icon: "pi pi-fw pi-users",
            routerLink: [`settings/user-groups/${this.appSettings.organizationId}`],
            permissions: [UserPermission.USERGROUP_CUD],
          },
        ];
        break;
      case MenueSection.Cashflows:
        model = [
          {
            label: translate("cashflows.title"),
            icon: "pi pi-fw pi-money-bill",
            routerLink: ["cashflows"],
            permissions: [UserPermission.MYORG_CUD],
          },
          {
            label: translate("imports.title"),
            icon: "pi pi-fw pi-upload",
            routerLink: ["cashflows/imports"],
            permissions: [UserPermission.MYORG_CUD],
          },
        ];
        break;
      case MenueSection.Positions:
        model = [
          {
            label: translate("portfolios.title"),
            icon: "pi pi-fw pi-briefcase",
            routerLink: ["positions/portfolios"],
            permissions: [UserPermission.MYORG_CUD],
          },
          {
            label: translate("transactions.title"),
            icon: "pi pi-fw pi-wallet",
            routerLink: ["positions/transactions"],
            permissions: [UserPermission.MYORG_CUD],
            routerLinkActiveOptions: { exact: false },
          },
          {
            label: translate("imports.title"),
            icon: "pi pi-fw pi-upload",
            routerLink: ["positions/transactions/imports"],
            permissions: [UserPermission.MYORG_CUD],
          },
        ];
        break;
    }
    return model.filter((x) => x.permissions.some((y) => this.appSettings.hasPermission(y)));
  }
}
