
import { addDays } from "@hedgebench/shared";


export class HolidayCalendar {
  private static holidayData: unknown;

  public static setHolidyData(holidayDataInput:unknown) {
    this.holidayData = holidayDataInput;
  }


  public isHoliday(date: Date,currencies:string[]): boolean {
    return currencies.some(ccy=>this.isWeekend(ccy, date) || this.isHolidayButNotWeekend(ccy, date))
  }

  public isBusinessDay(date: Date,currencies:string[]): boolean {
    return !this.isHoliday( date,currencies);
  }

  public getHolidayName(ccy: string, date: Date, lang: "de" | "en"): string {
    const propertyName: string = this.getJSONPropertyDate(date);
    const holiday = HolidayCalendar.holidayData[ccy][propertyName];
    if (holiday) return holiday[lang];
    if (this.isWeekend(ccy, date)) {
      return lang == "de" ? (this.isWeekendDE(date) as string) : (this.isWeekendEN(date) as string);
    }
  }

  public nextBusinessDay( date: Date,currencies:string[]): Date {
    let tempdate = date;
    let i = 1;
    while (this.isHoliday(tempdate,currencies)) {
      tempdate = addDays(date, i);
      i++;
    }
    return tempdate;
  }

  public prevBusinessDay(date: Date,currencies:string[]): Date {
    let tempdate = date;
    let i = 1;
    while (this.isHoliday(tempdate,currencies)) {
      tempdate = addDays(date, -i);
      i++;
    }
    return tempdate;
  }

  private isHolidayButNotWeekend(ccy: string, date: Date): boolean {
    const propertyName: string = this.getJSONPropertyDate(date);
    return HolidayCalendar.holidayData[ccy][propertyName] !== undefined;
  }

  private getJSONPropertyDate(date: Date): string {
    return date.toISOString().substr(0, 10);
  }

  private isWeekend(ccy: string, date: Date): boolean {
    return this.isWeekendEN(date) != false;
  }

  private isWeekendDE(date: Date): boolean | "Samstag" | "Sonntag" {
    return date.getDay() == 0 ? "Sonntag" : date.getDay() == 6 ? "Samstag" : false;
  }

  private isWeekendEN(date: Date): boolean | "Saturday" | "Sunday" {
    return date.getDay() == 0 ? "Sunday" : date.getDay() == 6 ? "Saturday" : false;
  }
}
