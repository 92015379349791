import { NgModule } from "@angular/core";
import { FxForwardModule } from "./fx-forward/fx-forward.module";
import { FxSwapModule } from "./fx-swap/fx-swap.module";
import { ProductsFactory } from "./products.factory";
import {DisplayChartComponent} from "./product/input-chart/displayChart.component"
import {DisplaySummaryComponent} from "./product/input-summary/displaySummary.component"
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  declarations: [DisplayChartComponent, DisplaySummaryComponent],
  imports: [FxForwardModule, FxSwapModule,SharedModule],
  providers: [ProductsFactory],
})
export class ProductsModule {}
