export interface ProductCashflow {
  currency: string;
  valueDate: Date;
  amount: number;
}

export interface ProductData {
  buyCurrency: string;
  sellCurrency: string;
}

export interface FxForwardData extends ProductData  {
  amount: number;
  amountCurrency: string;
  valueDate: Date;
  exchangeRate: number;
  termOption?: Date
}

export interface FxSwapData extends ProductData  {
  amountNear: number;
  amountCurrencyNear: string;
  valueDateNear: Date;
  exchangeRateNear: number;
  valueDateFar: Date;
  exchangeRateFar: number;
}

export enum ProductType {
  FxForward = "FXFORWARD",
  FxSwap = "FXSWAP",
}
