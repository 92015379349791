import { IErrorService } from "@zaslavskyv.kleinod21/applicationinsights-angularplugin-js/dist/applicationinsights-angularplugin-js";
import { environment } from "apps/frontend/src/environments/environment";

export class CustomErrorHandler implements IErrorService {
    handleError(error: any) {
        if (!environment.production)
        {
          console.error(error);
        }
    }
}
