<div class="exception-body pagenotfound-body" *transloco="let t">
  <div class="exception-panel">
    <img src="assets/layout/images/exception/icon-404.png" />

    <p-button [label]="t('home.title')" [routerLink]="['/']"></p-button>
  </div>

  <div class="exception-band">
    <div class="exception-content">
      <h3 class="p-underline">{{ t("notFound.title") }}</h3>
      <p>{{ t("notFound.message") }}</p>
      <img src="assets/layout/images/logo.png" />
    </div>
  </div>
</div>
