import {conventionData} from "./conventionData";

export type CurrencyConvention =
{
  ccy: string,
  notionalDecimals: number
  valueDateOffset:number
  quotationMode: "DIRECT" | "INDIRECT"
}


export type CurrencyPairConvention =
{
  pair:string,
  QuotationMarketStandard: boolean,
  Ratio_QuotationUnit:number
  Ratio_SwapPoints:number
  Precision_SwapPointDecimals:number
  Precision_CrossRateDecimals :number
}


export class marketConvention
{
  public static getCurrencyConvention(currency:string) : CurrencyConvention  {
    const x = conventionData.currencyStyles[currency]
    const result:CurrencyConvention = {
      ccy : currency,
      notionalDecimals: x.nd,
      quotationMode: x.qm=="I" ? "INDIRECT" : "DIRECT",
      valueDateOffset : x.vd,
    }
    return result;
  }

  public static getFXConvention(baseCurrency:string,quoteCurrency:string) : CurrencyPairConvention
  {
    const x = conventionData.crossCurrencyStyles[baseCurrency+quoteCurrency];
    const result:CurrencyPairConvention = {
      pair:baseCurrency+quoteCurrency,
      Precision_CrossRateDecimals:x.xpp,
      Precision_SwapPointDecimals:x.spp,
      QuotationMarketStandard:x.qm,
      Ratio_QuotationUnit:x.qu,
      Ratio_SwapPoints:x.sp
    }
    return result;
  }

}
