import { getDaysInMonth } from "@hedgebench/shared";
import { addDays } from "../dateUtils";
import { DateParser } from "./dateParser";
import { HolidayCalendar } from "./holidayCalendar";

export class CurrencyPairDate {
  private hc = new HolidayCalendar();

  constructor(private currencies: string[]) {}

  getExpiryAndValueDateFromInput(input: string,referenceDate:Date=null): { expiryDate: Date; valueDate: Date } {
    const parser = new DateParser();
    let expiryDate: Date = null;
    let valueDate: Date = null;
    input = input.toUpperCase();
    const spotDate: Date = referenceDate ?? this.getFXSpotDate(new Date(),false,this.currencies);
    if (input.endsWith("D") || input.endsWith("W") || input == "ON" || input == "SW" || input == "TN") {
      expiryDate = parser.fromString(input, new Date());
      expiryDate = this.hc.nextBusinessDay(expiryDate, this.currencies);
      valueDate = this.getFXSpotDate(expiryDate,false,this.currencies);
    } else if (input.endsWith("M") || input.endsWith("Y")) {
      valueDate = parser.fromString(input, spotDate);
      let date = valueDate;
      const currenciesWithUSD = this.currencies.filter((x) => x == "USD").concat("USD");
      if (this.isUltimo(spotDate, currenciesWithUSD)) {
        // if spotDate is ultimo then valueDate is also set to ultimo
        let d = 1;
        while (!this.isUltimo(date, currenciesWithUSD)) {
          if (date.getMonth() != valueDate.getMonth())
            //not allowed to move into the next month.
            d = -1;
          date = addDays(date, d);
        }
      } else {
        let d = 1;
        while (this.hc.isHoliday(date, currenciesWithUSD) || date.getMonth() != valueDate.getMonth()) {
          if (date.getMonth() != valueDate.getMonth())
            //end-of-month rule
            d = -1;
          date= addDays(date,d);
        }
      }
      valueDate = date;
      expiryDate = this.getFXSpotDate(valueDate,true,currenciesWithUSD);
    }
    return {expiryDate:expiryDate,valueDate:valueDate}
  }

  isUltimo(date: Date, ccyies: string[]): boolean {
    const ultimo = getDaysInMonth(date.getMonth(), date.getFullYear());
    let compareDate = new Date(date.getFullYear(), date.getMonth(), ultimo);
    compareDate = this.hc.prevBusinessDay(compareDate, ccyies);
    return compareDate == date;
  }

  private getFXSpotDate(date: Date, moveBackwards: boolean = false,currencies:string[]): Date {
    if (currencies.includes("USD") && (this.currencies.includes("CAD") || this.currencies.includes("TRY"))) {
      if (moveBackwards) return this.hc.prevBusinessDay(addDays(date, -11), this.currencies);
      else return this.hc.nextBusinessDay(addDays(date, 1), this.currencies);
    }
    if (moveBackwards) {
      date = addDays(date, -1);
      date = this.hc.prevBusinessDay(
        date,
        this.currencies.filter((x) => x == "USD")
      );
      date = addDays(date, -1);
      date = this.hc.prevBusinessDay(date, currencies.filter((x) => x == "USD").concat(["USD"]));
    } else {
      date = addDays(date, 1);
      date = this.hc.nextBusinessDay(
        date,
        this.currencies.filter((x) => x == "USD")
      );
      date = addDays(date, 1);
      date = this.hc.nextBusinessDay(date, currencies.filter((x) => x == "USD").concat(["USD"]));
    }
    return date;
  }
}
