import { Inject, Injectable } from "@angular/core";
import { Product as ProductData } from "@hedgebench/graphql";
import { getProductType, Product, PRODUCT } from "./product/product";

@Injectable()
export class ProductsFactory {
  private readonly factories: Record<string, () => Product> = {};

  constructor(@Inject(PRODUCT) factories: (() => Product)[]) {
    factories.forEach((factory) => (this.factories[getProductType(factory())] = factory));
  }

  create(data: ProductData): Product {
    const factory = this.factories[data.name];
    return Object.assign(factory(), data);
  }
}
