import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../../shared/services/app.settings";

@Component({
  selector: "app-init",
  templateUrl: "./init.component.html",
})
export class InitComponent {
  authenticated: Observable<boolean>;
  authorized: Observable<boolean>;

  constructor(readonly appSettings: AppSettings) {
    this.authenticated = appSettings.isAuthenticated$;
    this.authorized = appSettings.isAuthorized$;
  }
}
