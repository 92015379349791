import { Component, AfterViewInit, Renderer2, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, startWith } from "rxjs";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
})
export class MainComponent implements AfterViewInit, OnDestroy {
  activeTabIndex: number;
  sidebarActive: boolean;
  topbarMenuActive: boolean;
  sidebarClick: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: any;
  documentClickListener: any;

  constructor(private readonly renderer: Renderer2, private readonly router: Router) {}

  ngAfterViewInit(): void {
    this.documentClickListener = this.renderer.listen("body", "click", () => {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.sidebarClick) {
        this.sidebarActive = false;
      }
      this.topbarItemClick = false;
      this.sidebarClick = false;
    });

    this.router.events
      .pipe(
        filter<NavigationEnd>((e) => e instanceof NavigationEnd),
        startWith({ url: this.router.url }),
        filter((e) => e.url === "/app")
      )
      .subscribe(() => this.router.navigate(["/app/workbench"]));
  }

  onTabClick(event: Event, index: number): void {
    if (this.activeTabIndex === index) {
      this.sidebarActive = !this.sidebarActive;
    } else {
      this.activeTabIndex = index;
      this.sidebarActive = true;
    }

    event.preventDefault();
  }

  closeSidebar(event: Event): void {
    this.sidebarActive = false;
    event.preventDefault();
  }

  onSidebarClick(): void {
    this.sidebarClick = true;
  }

  onTopbarMenuButtonClick(event): void {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    event.preventDefault();
  }

  onTopbarItemClick(event, item): void {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event): void {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }
}
