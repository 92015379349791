import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: Date;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};



export class Account {
  __typename?: 'Account';
  bankId: Scalars['ID'];
  bankName: Scalars['String'];
  currency: Scalars['String'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class AccountCreateInput {
  bankId: Scalars['ID'];
  currency: Scalars['String'];
  number: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class AccountDeleteInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class AccountGetInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class AccountUpdateInput {
  bankId: Scalars['ID'];
  currency: Scalars['String'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class Bank {
  __typename?: 'Bank';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export class Cashflow {
  __typename?: 'Cashflow';
  amount: Scalars['Float'];
  bundleId: Scalars['ID'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  source: Source;
  state: CashflowState;
  valueDate: Scalars['DateTime'];
};

export class CashflowCreateInput {
  amount: Scalars['Float'];
  bundleId: Scalars['ID'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  source: Source;
  state: CashflowState;
  valueDate: Scalars['DateTime'];
};

export class CashflowDeleteInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class CashflowGetInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class CashflowImport {
  __typename?: 'CashflowImport';
  blob: Scalars['String'];
  bundleId: Scalars['ID'];
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['ID'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  size: Scalars['Int'];
  source: Source;
  type: Scalars['String'];
  userName: Scalars['ID'];
};

export class CashflowImportCreateInput {
  blob: Scalars['String'];
  bundleId: Scalars['ID'];
  displayName: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  size: Scalars['Int'];
  source: Source;
  type: Scalars['String'];
  userName: Scalars['ID'];
};

export class CashflowImportDeleteInput {
  blob: Scalars['String'];
  bundleId: Scalars['ID'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class CashflowImportUrlInput {
  blob: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class CashflowPage {
  __typename?: 'CashflowPage';
  nodes?: Maybe<Array<Cashflow>>;
  totalCount: Scalars['Int'];
};

export class CashflowPageInput {
  filters?: Maybe<Scalars['JSONObject']>;
  first?: Maybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  rows?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum CashflowState {
  Planned = 'Planned',
  Safe = 'Safe',
  Unsafe = 'Unsafe'
}

export class CashflowUpdateInput {
  amount: Scalars['Float'];
  bundleId: Scalars['ID'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  source: Source;
  state: CashflowState;
  valueDate: Scalars['DateTime'];
};

export class ChartDataPoint {
  __typename?: 'ChartDataPoint';
  x: Scalars['DateTime'];
  y: Scalars['Float'];
};

export class ChartDataSet {
  __typename?: 'ChartDataSet';
  data: Array<ChartDataPoint>;
  fill?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  stack: Scalars['String'];
};

export enum ChartType {
  Bar = 'bar',
  Line = 'line'
}

export enum ChartView {
  Balances = 'Balances',
  Cashflows = 'Cashflows'
}

export class Counterparty {
  __typename?: 'Counterparty';
  id: Scalars['ID'];
  name: Scalars['String'];
};


export class FxForward {
  __typename?: 'FxForward';
  amount: Scalars['Float'];
  amountCurrency: Scalars['String'];
  buyCurrency: Scalars['String'];
  exchangeRate: Scalars['Float'];
  name: Scalars['String'];
  sellCurrency: Scalars['String'];
  termOption?: Maybe<Scalars['DateTime']>;
  valueDate: Scalars['DateTime'];
};

export class FxSwap {
  __typename?: 'FxSwap';
  amountCurrencyNear: Scalars['String'];
  amountNear: Scalars['Float'];
  buyCurrency: Scalars['String'];
  exchangeRateFar: Scalars['Float'];
  exchangeRateNear: Scalars['Float'];
  name: Scalars['String'];
  sellCurrency: Scalars['String'];
  valueDateFar: Scalars['DateTime'];
  valueDateNear: Scalars['DateTime'];
};

export class HolidayCalendarData {
  __typename?: 'HolidayCalendarData';
  data: Scalars['JSONObject'];
};

export class HolidayCalendarInput {
  currencies: Array<Scalars['String']>;
};

export class ImageAccessInput {
  organizationId: Scalars['ID'];
};

export class ImageAccessTokenResult {
  __typename?: 'ImageAccessTokenResult';
  expiresOn: Scalars['DateTime'];
  organizationId: Scalars['ID'];
  origin: Scalars['String'];
  sasToken: Scalars['String'];
};


export class KeepAliveResult {
  __typename?: 'KeepAliveResult';
  serverTime: Scalars['String'];
};

export class Mutation {
  __typename?: 'Mutation';
  addUserGroupsToUserGroup: UserGroup;
  addUsersToUserGroup: UserGroup;
  blockUser: User;
  createAccount: Account;
  createCashflow: Cashflow;
  createCashflowImport: CashflowImport;
  createOrganization: Organization;
  createPortfolio: Portfolio;
  createTransaction: Transaction;
  createTransactionAttachment: TransactionAttachment;
  createTransactionImport: TransactionImport;
  createUser: User;
  createUserGroup: UserGroup;
  disableOrganizationClient: Organization;
  enableOrganizationClient: Organization;
  linkOrganization: OrganizationLink;
  reinviteUser: MutationResult;
  removeAccount: MutationResult;
  removeCashflow: MutationResult;
  removeCashflowImport: MutationResult;
  removeOrganization: MutationResult;
  removePortfolio: MutationResult;
  removeTransaction: MutationResult;
  removeTransactionAttachment: MutationResult;
  removeTransactionImport: MutationResult;
  removeUser: MutationResult;
  removeUserGroup: MutationResult;
  removeUserGroupsFromUserGroup: UserGroup;
  removeUsersFromUserGroup: UserGroup;
  unblockUser: User;
  unlinkOrganization: Organization;
  updateAccount: Account;
  updateCashflow: Cashflow;
  updateOrganization: Organization;
  updateOrganizationCounterparties: Organization;
  updateOrganizationCurrencies: Organization;
  updatePortfolio: Portfolio;
  updateTransaction: Transaction;
  updateUser: User;
  updateUserGroup: UserGroup;
};


export type MutationAddUserGroupsToUserGroupArgs = {
  input: UserGroupMemberGroupsInput;
};


export type MutationAddUsersToUserGroupArgs = {
  input: UserGroupMembersInput;
};


export type MutationBlockUserArgs = {
  input: UserBlockInput;
};


export type MutationCreateAccountArgs = {
  input: AccountCreateInput;
};


export type MutationCreateCashflowArgs = {
  input: CashflowCreateInput;
};


export type MutationCreateCashflowImportArgs = {
  input: CashflowImportCreateInput;
};


export type MutationCreateOrganizationArgs = {
  input: OrganizationCreateInput;
};


export type MutationCreatePortfolioArgs = {
  input: PortfolioCreateInput;
};


export type MutationCreateTransactionArgs = {
  input: TransactionCreateInput;
};


export type MutationCreateTransactionAttachmentArgs = {
  input: TransactionAttachmentCreateInput;
};


export type MutationCreateTransactionImportArgs = {
  input: TransactionImportCreateInput;
};


export type MutationCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationCreateUserGroupArgs = {
  input: UserGroupCreateInput;
};


export type MutationDisableOrganizationClientArgs = {
  input: OrganizationClientInput;
};


export type MutationEnableOrganizationClientArgs = {
  input: OrganizationClientInput;
};


export type MutationLinkOrganizationArgs = {
  input: OrganizationLinkInput;
};


export type MutationReinviteUserArgs = {
  input: UserReinviteInput;
};


export type MutationRemoveAccountArgs = {
  input: AccountDeleteInput;
};


export type MutationRemoveCashflowArgs = {
  input: CashflowDeleteInput;
};


export type MutationRemoveCashflowImportArgs = {
  input: CashflowImportDeleteInput;
};


export type MutationRemoveOrganizationArgs = {
  input: OrganizationDeleteInput;
};


export type MutationRemovePortfolioArgs = {
  input: PortfolioDeleteInput;
};


export type MutationRemoveTransactionArgs = {
  input: TransactionDeleteInput;
};


export type MutationRemoveTransactionAttachmentArgs = {
  input: TransactionAttachmentDeleteInput;
};


export type MutationRemoveTransactionImportArgs = {
  input: TransactionImportDeleteInput;
};


export type MutationRemoveUserArgs = {
  input: UserDeleteInput;
};


export type MutationRemoveUserGroupArgs = {
  input: UserGroupDeleteInput;
};


export type MutationRemoveUserGroupsFromUserGroupArgs = {
  input: UserGroupMemberGroupsInput;
};


export type MutationRemoveUsersFromUserGroupArgs = {
  input: UserGroupMembersInput;
};


export type MutationUnblockUserArgs = {
  input: UserBlockInput;
};


export type MutationUnlinkOrganizationArgs = {
  input: OrganizationLinkInput;
};


export type MutationUpdateAccountArgs = {
  input: AccountUpdateInput;
};


export type MutationUpdateCashflowArgs = {
  input: CashflowUpdateInput;
};


export type MutationUpdateOrganizationArgs = {
  input: OrganizationUpdateInput;
};


export type MutationUpdateOrganizationCounterpartiesArgs = {
  input: OrganizationCounterpartiesInput;
};


export type MutationUpdateOrganizationCurrenciesArgs = {
  input: OrganizationCurrenciesInput;
};


export type MutationUpdatePortfolioArgs = {
  input: PortfolioUpdateInput;
};


export type MutationUpdateTransactionArgs = {
  input: TransactionUpdateInput;
};


export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
};


export type MutationUpdateUserGroupArgs = {
  input: UserGroupUpdateInput;
};

export class MutationResult {
  __typename?: 'MutationResult';
  etag?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export enum Netting {
  Gross = 'Gross',
  Net = 'Net'
}

export class Organization {
  __typename?: 'Organization';
  clientId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  counterpartyIds?: Maybe<Array<Scalars['ID']>>;
  email?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  functionalCurrency?: Maybe<Scalars['String']>;
  hasUsers: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  role: OrganizationRole;
  supportedCurrencies?: Maybe<Array<Scalars['String']>>;
};

export class OrganizationClientInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
};

export class OrganizationCounterpartiesInput {
  counterpartyIds?: Maybe<Array<Scalars['ID']>>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
};

export class OrganizationCreateInput {
  code: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  role: OrganizationRole;
};

export class OrganizationCurrenciesInput {
  etag: Scalars['DateTime'];
  functionalCurrency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  supportedCurrencies?: Maybe<Array<Scalars['String']>>;
};

export class OrganizationDeleteInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
};

export class OrganizationLink {
  __typename?: 'OrganizationLink';
  linked: Organization;
  linkedTo: Organization;
};

export class OrganizationLinkInput {
  id: Scalars['ID'];
  linkedToId: Scalars['ID'];
};

export enum OrganizationRole {
  Advisor = 'Advisor',
  Corporate = 'Corporate'
}

export class OrganizationUpdateInput {
  code: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  role: OrganizationRole;
};

export enum Period {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly',
  Yearly = 'Yearly'
}

export class Portfolio {
  __typename?: 'Portfolio';
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class PortfolioCreateInput {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class PortfolioDeleteInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class PortfolioGetInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class PortfolioUpdateInput {
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type Product = FxForward | FxSwap;

export class Query {
  __typename?: 'Query';
  dummy?: Maybe<Product>;
  getAccount: Account;
  getAccounts: Array<Account>;
  getAssignable: UserGroupAssignable;
  getBanks: Array<Bank>;
  getCashflow: Cashflow;
  getCashflowImportUploadUrl: Scalars['String'];
  getCashflowImports: Array<CashflowImport>;
  getCashflows: CashflowPage;
  getCounterparties: Array<Counterparty>;
  getHolidayData: HolidayCalendarData;
  getImageAccessToken: ImageAccessTokenResult;
  getLinkableOrganizations: Array<Organization>;
  getLinkedOrganizations: Array<Organization>;
  getOrganization: Organization;
  getOrganizationClientSecret?: Maybe<Scalars['String']>;
  getOrganizationLogoUploadUrl: Scalars['String'];
  getPortfolio: Portfolio;
  getPortfolios: Array<Portfolio>;
  getSettings: Settings;
  getTransaction: Transaction;
  getTransactionAttachmentUploadUrl: Scalars['String'];
  getTransactionAttachments: Array<TransactionAttachment>;
  getTransactionImportUploadUrl: Scalars['String'];
  getTransactionImports: Array<TransactionImport>;
  getTransactions: Array<Transaction>;
  getUser: User;
  getUserGroup: UserGroup;
  getUserGroups: Array<UserGroup>;
  getUsers: Array<User>;
  getWorkbenchChart: WorkbenchChart;
  keepAlive: KeepAliveResult;
};


export type QueryGetAccountArgs = {
  input: AccountGetInput;
};


export type QueryGetAccountsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetAssignableArgs = {
  input: UserGroupGetInput;
};


export type QueryGetBanksArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetCashflowArgs = {
  input: CashflowGetInput;
};


export type QueryGetCashflowImportUploadUrlArgs = {
  input: CashflowImportUrlInput;
};


export type QueryGetCashflowImportsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetCashflowsArgs = {
  input: CashflowPageInput;
};


export type QueryGetCounterpartiesArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetHolidayDataArgs = {
  input: HolidayCalendarInput;
};


export type QueryGetImageAccessTokenArgs = {
  input: ImageAccessInput;
};


export type QueryGetLinkableOrganizationsArgs = {
  id: Scalars['ID'];
};


export type QueryGetLinkedOrganizationsArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganizationClientSecretArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganizationLogoUploadUrlArgs = {
  id: Scalars['ID'];
};


export type QueryGetPortfolioArgs = {
  input: PortfolioGetInput;
};


export type QueryGetPortfoliosArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetTransactionArgs = {
  input: TransactionGetInput;
};


export type QueryGetTransactionAttachmentUploadUrlArgs = {
  input: TransactionAttachmentUrlInput;
};


export type QueryGetTransactionAttachmentsArgs = {
  input: TransactionAttachmentListInput;
};


export type QueryGetTransactionImportUploadUrlArgs = {
  input: TransactionImportUrlInput;
};


export type QueryGetTransactionImportsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetTransactionsArgs = {
  input: TransactionListInput;
};


export type QueryGetUserArgs = {
  input: UserGetInput;
};


export type QueryGetUserGroupArgs = {
  input: UserGroupGetInput;
};


export type QueryGetUserGroupsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetUsersArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetWorkbenchChartArgs = {
  input: WorkbenchChartInput;
};

export class Settings {
  __typename?: 'Settings';
  auth0UserId: Scalars['String'];
  counterpartyIds?: Maybe<Array<Scalars['String']>>;
  creatorOrganizationId?: Maybe<Scalars['String']>;
  functionalCurrency?: Maybe<Scalars['String']>;
  organizationCode: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  organizationRole: OrganizationRole;
  supportedCurrencies?: Maybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
  userPermissions?: Maybe<Array<Scalars['String']>>;
};

export enum Source {
  Api = 'Api',
  Upload = 'Upload',
  User = 'User'
}

export class Transaction {
  __typename?: 'Transaction';
  bundleId: Scalars['ID'];
  counterpartyId: Scalars['ID'];
  dealDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  product: Scalars['JSONObject'];
  productName: Scalars['String'];
  reference: Scalars['String'];
  source: Source;
};

export class TransactionAttachment {
  __typename?: 'TransactionAttachment';
  blob: Scalars['String'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  size: Scalars['Int'];
  transactionId: Scalars['ID'];
  type: Scalars['String'];
};

export class TransactionAttachmentCreateInput {
  blob: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  size: Scalars['Int'];
  transactionId: Scalars['ID'];
  type: Scalars['String'];
};

export class TransactionAttachmentDeleteInput {
  blob: Scalars['String'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class TransactionAttachmentListInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class TransactionAttachmentUrlInput {
  blob: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class TransactionCreateInput {
  bundleId: Scalars['ID'];
  counterpartyId: Scalars['ID'];
  dealDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  product: Scalars['JSONObject'];
  reference: Scalars['String'];
  source: Source;
};

export class TransactionDeleteInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class TransactionGetInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class TransactionImport {
  __typename?: 'TransactionImport';
  blob: Scalars['String'];
  bundleId: Scalars['ID'];
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['ID'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  portfolioName: Scalars['String'];
  size: Scalars['Int'];
  source: Source;
  type: Scalars['String'];
  userName: Scalars['ID'];
};

export class TransactionImportCreateInput {
  blob: Scalars['String'];
  bundleId: Scalars['ID'];
  displayName: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  size: Scalars['Int'];
  source: Source;
  type: Scalars['String'];
  userName: Scalars['ID'];
};

export class TransactionImportDeleteInput {
  blob: Scalars['String'];
  bundleId: Scalars['ID'];
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class TransactionImportUrlInput {
  blob: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class TransactionListInput {
  organizationId: Scalars['ID'];
  portfolioId: Scalars['ID'];
};

export class TransactionUpdateInput {
  bundleId: Scalars['ID'];
  counterpartyId: Scalars['ID'];
  dealDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  product: Scalars['JSONObject'];
  reference: Scalars['String'];
  source: Source;
};

export class User {
  __typename?: 'User';
  auth0Id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isBlocked: Scalars['Boolean'];
  lastName: Scalars['String'];
  organizationId: Scalars['ID'];
  roles: Array<UserRole>;
  salutation: UserSalutation;
};

export class UserBlockInput {
  auth0Id?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class UserCreateInput {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationId: Scalars['ID'];
  originatorId: Scalars['ID'];
  roles: Array<UserRole>;
  salutation: UserSalutation;
};

export class UserDeleteInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class UserGetInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class UserGroup {
  __typename?: 'UserGroup';
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  memberGroups: Array<UserGroup>;
  memberUsers: Array<User>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class UserGroupAssignable {
  __typename?: 'UserGroupAssignable';
  userGroups: Array<UserGroup>;
  users: Array<User>;
};

export class UserGroupCreateInput {
  description?: Maybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class UserGroupDeleteInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class UserGroupGetInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class UserGroupMemberGroupsInput {
  etag: Scalars['DateTime'];
  groupIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export class UserGroupMembersInput {
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export class UserGroupUpdateInput {
  description?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export class UserReinviteInput {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  originatorId: Scalars['ID'];
};

export enum UserRole {
  Administrator = 'Administrator',
  AdvisorManager = 'Advisor_Manager',
  AdvisorUser = 'Advisor_User',
  CorporateManager = 'Corporate_Manager',
  CorporateUser = 'Corporate_User'
}

export enum UserSalutation {
  Mr = 'MR',
  Mrs = 'MRS',
  Mx = 'MX'
}

export class UserUpdateInput {
  auth0Id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  etag: Scalars['DateTime'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isBlocked: Scalars['Boolean'];
  lastName: Scalars['String'];
  organizationId: Scalars['ID'];
  roles: Array<UserRole>;
  salutation: UserSalutation;
};

export class WorkbenchChart {
  __typename?: 'WorkbenchChart';
  datasets: Array<ChartDataSet>;
  type: ChartType;
};

export class WorkbenchChartInput {
  cashflowStates: Array<CashflowState>;
  currencies: Array<Scalars['String']>;
  netting?: Maybe<Netting>;
  organizationId: Scalars['ID'];
  period?: Maybe<Period>;
  portfolioIds?: Maybe<Array<Scalars['ID']>>;
  view: ChartView;
};

export type GetAccountsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetAccountsQuery = (
  { __typename?: 'Query' }
  & { getAccounts: Array<(
    { __typename?: 'Account' }
    & AccountFragment
  )> }
);

export type GetAccountQueryVariables = Exact<{
  input: AccountGetInput;
}>;


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { getAccount: (
    { __typename?: 'Account' }
    & AccountFragment
  ) }
);

export type GetBanksQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetBanksQuery = (
  { __typename?: 'Query' }
  & { getBanks: Array<(
    { __typename?: 'Bank' }
    & Pick<Bank, 'id' | 'code' | 'name'>
  )> }
);

export type CreateAccountMutationVariables = Exact<{
  input: AccountCreateInput;
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'Account' }
    & AccountFragment
  ) }
);

export type UpdateAccountMutationVariables = Exact<{
  input: AccountUpdateInput;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & AccountFragment
  ) }
);

export type RemoveAccountMutationVariables = Exact<{
  input: AccountDeleteInput;
}>;


export type RemoveAccountMutation = (
  { __typename?: 'Mutation' }
  & { removeAccount: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type AccountFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'etag' | 'number' | 'bankId' | 'bankName' | 'organizationId' | 'currency'>
);

export type GetCashflowImportsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetCashflowImportsQuery = (
  { __typename?: 'Query' }
  & { getCashflowImports: Array<(
    { __typename?: 'CashflowImport' }
    & CashflowImportFragment
  )> }
);

export type GetCashflowImportUploadUrlQueryVariables = Exact<{
  input: CashflowImportUrlInput;
}>;


export type GetCashflowImportUploadUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCashflowImportUploadUrl'>
);

export type CreateCashflowImportMutationVariables = Exact<{
  input: CashflowImportCreateInput;
}>;


export type CreateCashflowImportMutation = (
  { __typename?: 'Mutation' }
  & { createCashflowImport: (
    { __typename?: 'CashflowImport' }
    & CashflowImportFragment
  ) }
);

export type RemoveCashflowImportMutationVariables = Exact<{
  input: CashflowImportDeleteInput;
}>;


export type RemoveCashflowImportMutation = (
  { __typename?: 'Mutation' }
  & { removeCashflowImport: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type CashflowImportFragment = (
  { __typename?: 'CashflowImport' }
  & Pick<CashflowImport, 'id' | 'createdAt' | 'etag' | 'type' | 'blob' | 'name' | 'size' | 'userName' | 'displayName' | 'source' | 'count' | 'bundleId' | 'organizationId'>
);

export type GetCashflowsQueryVariables = Exact<{
  input: CashflowPageInput;
}>;


export type GetCashflowsQuery = (
  { __typename?: 'Query' }
  & { getCashflows: (
    { __typename?: 'CashflowPage' }
    & Pick<CashflowPage, 'totalCount'>
    & { nodes?: Maybe<Array<(
      { __typename?: 'Cashflow' }
      & CashflowFragment
    )>> }
  ) }
);

export type GetCashflowQueryVariables = Exact<{
  input: CashflowGetInput;
}>;


export type GetCashflowQuery = (
  { __typename?: 'Query' }
  & { getCashflow: (
    { __typename?: 'Cashflow' }
    & CashflowFragment
  ) }
);

export type CreateCashflowMutationVariables = Exact<{
  input: CashflowCreateInput;
}>;


export type CreateCashflowMutation = (
  { __typename?: 'Mutation' }
  & { createCashflow: (
    { __typename?: 'Cashflow' }
    & CashflowFragment
  ) }
);

export type UpdateCashflowMutationVariables = Exact<{
  input: CashflowUpdateInput;
}>;


export type UpdateCashflowMutation = (
  { __typename?: 'Mutation' }
  & { updateCashflow: (
    { __typename?: 'Cashflow' }
    & CashflowFragment
  ) }
);

export type RemoveCashflowMutationVariables = Exact<{
  input: CashflowDeleteInput;
}>;


export type RemoveCashflowMutation = (
  { __typename?: 'Mutation' }
  & { removeCashflow: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type CashflowFragment = (
  { __typename?: 'Cashflow' }
  & Pick<Cashflow, 'id' | 'etag' | 'amount' | 'valueDate' | 'description' | 'currency' | 'state' | 'source' | 'bundleId' | 'organizationId'>
);

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationQuery = (
  { __typename?: 'Query' }
  & { getOrganization: (
    { __typename?: 'Organization' }
    & FullOrganizationFragment
  ) }
);

export type GetCounterpartiesQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetCounterpartiesQuery = (
  { __typename?: 'Query' }
  & { getCounterparties: Array<(
    { __typename?: 'Counterparty' }
    & Pick<Counterparty, 'id' | 'name'>
  )> }
);

export type GetLinkableOrganizationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLinkableOrganizationsQuery = (
  { __typename?: 'Query' }
  & { getLinkableOrganizations: Array<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'code'>
  )> }
);

export type GetLinkedOrganizationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLinkedOrganizationsQuery = (
  { __typename?: 'Query' }
  & { getLinkedOrganizations: Array<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'code' | 'role' | 'etag'>
  )> }
);

export type CreateOrganizationMutationVariables = Exact<{
  input: OrganizationCreateInput;
}>;


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganization: (
    { __typename?: 'Organization' }
    & OrganizationFragment
  ) }
);

export type UpdateOrganizationMutationVariables = Exact<{
  input: OrganizationUpdateInput;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization: (
    { __typename?: 'Organization' }
    & FullOrganizationFragment
  ) }
);

export type UpdateOrganizationCurrenciesMutationVariables = Exact<{
  input: OrganizationCurrenciesInput;
}>;


export type UpdateOrganizationCurrenciesMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationCurrencies: (
    { __typename?: 'Organization' }
    & FullOrganizationFragment
  ) }
);

export type UpdateOrganizationCounterpartiesMutationVariables = Exact<{
  input: OrganizationCounterpartiesInput;
}>;


export type UpdateOrganizationCounterpartiesMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationCounterparties: (
    { __typename?: 'Organization' }
    & FullOrganizationFragment
  ) }
);

export type EnableOrganizationClientMutationVariables = Exact<{
  input: OrganizationClientInput;
}>;


export type EnableOrganizationClientMutation = (
  { __typename?: 'Mutation' }
  & { enableOrganizationClient: (
    { __typename?: 'Organization' }
    & FullOrganizationFragment
  ) }
);

export type DisableOrganizationClientMutationVariables = Exact<{
  input: OrganizationClientInput;
}>;


export type DisableOrganizationClientMutation = (
  { __typename?: 'Mutation' }
  & { disableOrganizationClient: (
    { __typename?: 'Organization' }
    & FullOrganizationFragment
  ) }
);

export type GetOrganizationClientSecretQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationClientSecretQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOrganizationClientSecret'>
);

export type LinkOrganizationMutationVariables = Exact<{
  input: OrganizationLinkInput;
}>;


export type LinkOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { linkOrganization: (
    { __typename?: 'OrganizationLink' }
    & { linked: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'etag'>
    ), linkedTo: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'etag'>
    ) }
  ) }
);

export type UnlinkOrganizationMutationVariables = Exact<{
  input: OrganizationLinkInput;
}>;


export type UnlinkOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { unlinkOrganization: (
    { __typename?: 'Organization' }
    & OrganizationFragment
  ) }
);

export type RemoveOrganizationMutationVariables = Exact<{
  input: OrganizationDeleteInput;
}>;


export type RemoveOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { removeOrganization: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type GetOrganizationLogoUploadUrlQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationLogoUploadUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOrganizationLogoUploadUrl'>
);

export type OrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name' | 'code' | 'email' | 'role' | 'parentId' | 'etag'>
);

export type FullOrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'hasUsers' | 'functionalCurrency' | 'supportedCurrencies' | 'counterpartyIds' | 'clientId'>
  & OrganizationFragment
);

export type GetPortfoliosQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetPortfoliosQuery = (
  { __typename?: 'Query' }
  & { getPortfolios: Array<(
    { __typename?: 'Portfolio' }
    & PortfolioFragment
  )> }
);

export type GetPortfolioQueryVariables = Exact<{
  input: PortfolioGetInput;
}>;


export type GetPortfolioQuery = (
  { __typename?: 'Query' }
  & { getPortfolio: (
    { __typename?: 'Portfolio' }
    & PortfolioFragment
  ) }
);

export type CreatePortfolioMutationVariables = Exact<{
  input: PortfolioCreateInput;
}>;


export type CreatePortfolioMutation = (
  { __typename?: 'Mutation' }
  & { createPortfolio: (
    { __typename?: 'Portfolio' }
    & PortfolioFragment
  ) }
);

export type UpdatePortfolioMutationVariables = Exact<{
  input: PortfolioUpdateInput;
}>;


export type UpdatePortfolioMutation = (
  { __typename?: 'Mutation' }
  & { updatePortfolio: (
    { __typename?: 'Portfolio' }
    & PortfolioFragment
  ) }
);

export type RemovePortfolioMutationVariables = Exact<{
  input: PortfolioDeleteInput;
}>;


export type RemovePortfolioMutation = (
  { __typename?: 'Mutation' }
  & { removePortfolio: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type PortfolioFragment = (
  { __typename?: 'Portfolio' }
  & Pick<Portfolio, 'id' | 'etag' | 'name' | 'description' | 'organizationId'>
);

export type GetTransactionAttachmentsQueryVariables = Exact<{
  input: TransactionAttachmentListInput;
}>;


export type GetTransactionAttachmentsQuery = (
  { __typename?: 'Query' }
  & { getTransactionAttachments: Array<(
    { __typename?: 'TransactionAttachment' }
    & TransactionAttachmentFragment
  )> }
);

export type GetTransactionAttachmentUploadUrlQueryVariables = Exact<{
  input: TransactionAttachmentUrlInput;
}>;


export type GetTransactionAttachmentUploadUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTransactionAttachmentUploadUrl'>
);

export type CreateTransactionAttachmentMutationVariables = Exact<{
  input: TransactionAttachmentCreateInput;
}>;


export type CreateTransactionAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createTransactionAttachment: (
    { __typename?: 'TransactionAttachment' }
    & TransactionAttachmentFragment
  ) }
);

export type RemoveTransactionAttachmentMutationVariables = Exact<{
  input: TransactionAttachmentDeleteInput;
}>;


export type RemoveTransactionAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { removeTransactionAttachment: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type TransactionAttachmentFragment = (
  { __typename?: 'TransactionAttachment' }
  & Pick<TransactionAttachment, 'id' | 'etag' | 'type' | 'blob' | 'name' | 'size' | 'transactionId' | 'organizationId'>
);

export type GetTransactionImportsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetTransactionImportsQuery = (
  { __typename?: 'Query' }
  & { getTransactionImports: Array<(
    { __typename?: 'TransactionImport' }
    & TransactionImportFragment
  )> }
);

export type GetTransactionImportUploadUrlQueryVariables = Exact<{
  input: TransactionImportUrlInput;
}>;


export type GetTransactionImportUploadUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTransactionImportUploadUrl'>
);

export type CreateTransactionImportMutationVariables = Exact<{
  input: TransactionImportCreateInput;
}>;


export type CreateTransactionImportMutation = (
  { __typename?: 'Mutation' }
  & { createTransactionImport: (
    { __typename?: 'TransactionImport' }
    & TransactionImportFragment
  ) }
);

export type RemoveTransactionImportMutationVariables = Exact<{
  input: TransactionImportDeleteInput;
}>;


export type RemoveTransactionImportMutation = (
  { __typename?: 'Mutation' }
  & { removeTransactionImport: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type TransactionImportFragment = (
  { __typename?: 'TransactionImport' }
  & Pick<TransactionImport, 'id' | 'createdAt' | 'etag' | 'type' | 'blob' | 'name' | 'size' | 'userName' | 'displayName' | 'source' | 'count' | 'bundleId' | 'portfolioId' | 'portfolioName' | 'organizationId'>
);

export type GetTransactionsQueryVariables = Exact<{
  input: TransactionListInput;
}>;


export type GetTransactionsQuery = (
  { __typename?: 'Query' }
  & { getTransactions: Array<(
    { __typename?: 'Transaction' }
    & TransactionFragment
  )> }
);

export type GetTransactionQueryVariables = Exact<{
  input: TransactionGetInput;
}>;


export type GetTransactionQuery = (
  { __typename?: 'Query' }
  & { getTransaction: (
    { __typename?: 'Transaction' }
    & TransactionFragment
  ) }
);

export type CreateTransactionMutationVariables = Exact<{
  input: TransactionCreateInput;
}>;


export type CreateTransactionMutation = (
  { __typename?: 'Mutation' }
  & { createTransaction: (
    { __typename?: 'Transaction' }
    & TransactionFragment
  ) }
);

export type UpdateTransactionMutationVariables = Exact<{
  input: TransactionUpdateInput;
}>;


export type UpdateTransactionMutation = (
  { __typename?: 'Mutation' }
  & { updateTransaction: (
    { __typename?: 'Transaction' }
    & TransactionFragment
  ) }
);

export type RemoveTransactionMutationVariables = Exact<{
  input: TransactionDeleteInput;
}>;


export type RemoveTransactionMutation = (
  { __typename?: 'Mutation' }
  & { removeTransaction: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type TransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'etag' | 'reference' | 'description' | 'dealDate' | 'source' | 'productName' | 'product' | 'counterpartyId' | 'portfolioId' | 'bundleId' | 'organizationId'>
);

export type GetUserGroupsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetUserGroupsQuery = (
  { __typename?: 'Query' }
  & { getUserGroups: Array<(
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  )> }
);

export type GetUserGroupFlatQueryVariables = Exact<{
  input: UserGroupGetInput;
}>;


export type GetUserGroupFlatQuery = (
  { __typename?: 'Query' }
  & { getUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  ) }
);

export type GetUserGroupDeepQueryVariables = Exact<{
  input: UserGroupGetInput;
}>;


export type GetUserGroupDeepQuery = (
  { __typename?: 'Query' }
  & { getUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupDeepFragment
  ) }
);

export type GetAssignableQueryVariables = Exact<{
  input: UserGroupGetInput;
}>;


export type GetAssignableQuery = (
  { __typename?: 'Query' }
  & { getAssignable: (
    { __typename?: 'UserGroupAssignable' }
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'organizationId'>
    )>, userGroups: Array<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'organizationId'>
    )> }
  ) }
);

export type CreateUserGroupMutationVariables = Exact<{
  input: UserGroupCreateInput;
}>;


export type CreateUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { createUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  ) }
);

export type UpdateUserGroupMutationVariables = Exact<{
  input: UserGroupUpdateInput;
}>;


export type UpdateUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  ) }
);

export type AddUsersToUserGroupMutationVariables = Exact<{
  input: UserGroupMembersInput;
}>;


export type AddUsersToUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { addUsersToUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  ) }
);

export type RemoveUsersFromUserGroupMutationVariables = Exact<{
  input: UserGroupMembersInput;
}>;


export type RemoveUsersFromUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeUsersFromUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  ) }
);

export type AddUserGroupsToUserGroupMutationVariables = Exact<{
  input: UserGroupMemberGroupsInput;
}>;


export type AddUserGroupsToUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { addUserGroupsToUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  ) }
);

export type RemoveUserGroupsFromUserGroupMutationVariables = Exact<{
  input: UserGroupMemberGroupsInput;
}>;


export type RemoveUserGroupsFromUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeUserGroupsFromUserGroup: (
    { __typename?: 'UserGroup' }
    & UserGroupFlatFragment
  ) }
);

export type RemoveUserGroupMutationVariables = Exact<{
  input: UserGroupDeleteInput;
}>;


export type RemoveUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeUserGroup: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type UserGroupDeepFragment = (
  { __typename?: 'UserGroup' }
  & Pick<UserGroup, 'id' | 'name' | 'description' | 'isPublic' | 'organizationId' | 'etag'>
  & { memberGroups: Array<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name' | 'description' | 'isPublic' | 'organizationId'>
  )>, memberUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'roles'>
  )> }
);

export type UserGroupFlatFragment = (
  { __typename?: 'UserGroup' }
  & Pick<UserGroup, 'id' | 'name' | 'description' | 'isPublic' | 'organizationId' | 'etag'>
);

export type GetUsersQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: Array<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type GetUserQueryVariables = Exact<{
  input: UserGetInput;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserUpdateInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type ReinviteUserMutationVariables = Exact<{
  input: UserReinviteInput;
}>;


export type ReinviteUserMutation = (
  { __typename?: 'Mutation' }
  & { reinviteUser: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type BlockUserMutationVariables = Exact<{
  input: UserBlockInput;
}>;


export type BlockUserMutation = (
  { __typename?: 'Mutation' }
  & { blockUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UnblockUserMutationVariables = Exact<{
  input: UserBlockInput;
}>;


export type UnblockUserMutation = (
  { __typename?: 'Mutation' }
  & { unblockUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type RemoveUserMutationVariables = Exact<{
  input: UserDeleteInput;
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & { removeUser: (
    { __typename?: 'MutationResult' }
    & Pick<MutationResult, 'status'>
  ) }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'salutation' | 'roles' | 'auth0Id' | 'organizationId' | 'isBlocked' | 'etag'>
);

export type GetWorkbenchChartQueryVariables = Exact<{
  input: WorkbenchChartInput;
}>;


export type GetWorkbenchChartQuery = (
  { __typename?: 'Query' }
  & { getWorkbenchChart: (
    { __typename?: 'WorkbenchChart' }
    & WorkbenchChartFragment
  ) }
);

export type WorkbenchChartFragment = (
  { __typename?: 'WorkbenchChart' }
  & Pick<WorkbenchChart, 'type'>
  & { datasets: Array<(
    { __typename?: 'ChartDataSet' }
    & Pick<ChartDataSet, 'label' | 'stack' | 'fill'>
    & { data: Array<(
      { __typename?: 'ChartDataPoint' }
      & Pick<ChartDataPoint, 'x' | 'y'>
    )> }
  )> }
);

export type GetHolidayCalendarDataQueryVariables = Exact<{
  input: HolidayCalendarInput;
}>;


export type GetHolidayCalendarDataQuery = (
  { __typename?: 'Query' }
  & { getHolidayData: (
    { __typename?: 'HolidayCalendarData' }
    & Pick<HolidayCalendarData, 'data'>
  ) }
);

export type GetSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = (
  { __typename?: 'Query' }
  & { getSettings: (
    { __typename?: 'Settings' }
    & Pick<Settings, 'userPermissions' | 'organizationId' | 'auth0UserId' | 'userId' | 'organizationCode' | 'organizationName' | 'organizationRole' | 'creatorOrganizationId' | 'functionalCurrency' | 'supportedCurrencies' | 'counterpartyIds'>
  ) }
);

export type KeepAliveQueryVariables = Exact<{ [key: string]: never; }>;


export type KeepAliveQuery = (
  { __typename?: 'Query' }
  & { keepAlive: (
    { __typename?: 'KeepAliveResult' }
    & Pick<KeepAliveResult, 'serverTime'>
  ) }
);

export type GetImageAccessTokenQueryVariables = Exact<{
  input: ImageAccessInput;
}>;


export type GetImageAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getImageAccessToken: (
    { __typename?: 'ImageAccessTokenResult' }
    & Pick<ImageAccessTokenResult, 'expiresOn' | 'organizationId' | 'sasToken' | 'origin'>
  ) }
);

export const AccountFragmentDoc = gql`
    fragment Account on Account {
  id
  etag
  number
  bankId
  bankName
  organizationId
  currency
}
    `;
export const CashflowImportFragmentDoc = gql`
    fragment CashflowImport on CashflowImport {
  id
  createdAt
  etag
  type
  blob
  name
  size
  userName
  displayName
  source
  count
  bundleId
  organizationId
}
    `;
export const CashflowFragmentDoc = gql`
    fragment Cashflow on Cashflow {
  id
  etag
  amount
  valueDate
  description
  currency
  state
  source
  bundleId
  organizationId
}
    `;
export const OrganizationFragmentDoc = gql`
    fragment Organization on Organization {
  id
  name
  code
  email
  role
  parentId
  etag
}
    `;
export const FullOrganizationFragmentDoc = gql`
    fragment FullOrganization on Organization {
  ...Organization
  hasUsers
  functionalCurrency
  supportedCurrencies
  counterpartyIds
  clientId
}
    ${OrganizationFragmentDoc}`;
export const PortfolioFragmentDoc = gql`
    fragment Portfolio on Portfolio {
  id
  etag
  name
  description
  organizationId
}
    `;
export const TransactionAttachmentFragmentDoc = gql`
    fragment TransactionAttachment on TransactionAttachment {
  id
  etag
  type
  blob
  name
  size
  transactionId
  organizationId
}
    `;
export const TransactionImportFragmentDoc = gql`
    fragment TransactionImport on TransactionImport {
  id
  createdAt
  etag
  type
  blob
  name
  size
  userName
  displayName
  source
  count
  bundleId
  portfolioId
  portfolioName
  organizationId
}
    `;
export const TransactionFragmentDoc = gql`
    fragment Transaction on Transaction {
  id
  etag
  reference
  description
  dealDate
  source
  productName
  product
  counterpartyId
  portfolioId
  bundleId
  organizationId
}
    `;
export const UserGroupDeepFragmentDoc = gql`
    fragment UserGroupDeep on UserGroup {
  id
  name
  description
  isPublic
  organizationId
  memberGroups {
    id
    name
    description
    isPublic
    organizationId
  }
  memberUsers {
    id
    firstName
    lastName
    roles
  }
  etag
}
    `;
export const UserGroupFlatFragmentDoc = gql`
    fragment UserGroupFlat on UserGroup {
  id
  name
  description
  isPublic
  organizationId
  etag
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName
  lastName
  email
  salutation
  roles
  auth0Id
  organizationId
  isBlocked
  etag
}
    `;
export const WorkbenchChartFragmentDoc = gql`
    fragment WorkbenchChart on WorkbenchChart {
  type
  datasets {
    label
    stack
    fill
    data {
      x
      y
    }
  }
}
    `;
export const GetAccountsDocument = gql`
    query getAccounts($organizationId: ID!) {
  getAccounts(organizationId: $organizationId) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAccountsGQL extends Apollo.Query<GetAccountsQuery, GetAccountsQueryVariables> {
    document = GetAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAccountDocument = gql`
    query getAccount($input: AccountGetInput!) {
  getAccount(input: $input) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAccountGQL extends Apollo.Query<GetAccountQuery, GetAccountQueryVariables> {
    document = GetAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBanksDocument = gql`
    query getBanks($organizationId: ID!) {
  getBanks(organizationId: $organizationId) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBanksGQL extends Apollo.Query<GetBanksQuery, GetBanksQueryVariables> {
    document = GetBanksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAccountDocument = gql`
    mutation createAccount($input: AccountCreateInput!) {
  createAccount(input: $input) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAccountGQL extends Apollo.Mutation<CreateAccountMutation, CreateAccountMutationVariables> {
    document = CreateAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountDocument = gql`
    mutation updateAccount($input: AccountUpdateInput!) {
  updateAccount(input: $input) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccountGQL extends Apollo.Mutation<UpdateAccountMutation, UpdateAccountMutationVariables> {
    document = UpdateAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveAccountDocument = gql`
    mutation removeAccount($input: AccountDeleteInput!) {
  removeAccount(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveAccountGQL extends Apollo.Mutation<RemoveAccountMutation, RemoveAccountMutationVariables> {
    document = RemoveAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCashflowImportsDocument = gql`
    query getCashflowImports($organizationId: ID!) {
  getCashflowImports(organizationId: $organizationId) {
    ...CashflowImport
  }
}
    ${CashflowImportFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCashflowImportsGQL extends Apollo.Query<GetCashflowImportsQuery, GetCashflowImportsQueryVariables> {
    document = GetCashflowImportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCashflowImportUploadUrlDocument = gql`
    query getCashflowImportUploadUrl($input: CashflowImportUrlInput!) {
  getCashflowImportUploadUrl(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCashflowImportUploadUrlGQL extends Apollo.Query<GetCashflowImportUploadUrlQuery, GetCashflowImportUploadUrlQueryVariables> {
    document = GetCashflowImportUploadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCashflowImportDocument = gql`
    mutation createCashflowImport($input: CashflowImportCreateInput!) {
  createCashflowImport(input: $input) {
    ...CashflowImport
  }
}
    ${CashflowImportFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCashflowImportGQL extends Apollo.Mutation<CreateCashflowImportMutation, CreateCashflowImportMutationVariables> {
    document = CreateCashflowImportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveCashflowImportDocument = gql`
    mutation removeCashflowImport($input: CashflowImportDeleteInput!) {
  removeCashflowImport(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveCashflowImportGQL extends Apollo.Mutation<RemoveCashflowImportMutation, RemoveCashflowImportMutationVariables> {
    document = RemoveCashflowImportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCashflowsDocument = gql`
    query getCashflows($input: CashflowPageInput!) {
  getCashflows(input: $input) {
    totalCount
    nodes {
      ...Cashflow
    }
  }
}
    ${CashflowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCashflowsGQL extends Apollo.Query<GetCashflowsQuery, GetCashflowsQueryVariables> {
    document = GetCashflowsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCashflowDocument = gql`
    query getCashflow($input: CashflowGetInput!) {
  getCashflow(input: $input) {
    ...Cashflow
  }
}
    ${CashflowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCashflowGQL extends Apollo.Query<GetCashflowQuery, GetCashflowQueryVariables> {
    document = GetCashflowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCashflowDocument = gql`
    mutation createCashflow($input: CashflowCreateInput!) {
  createCashflow(input: $input) {
    ...Cashflow
  }
}
    ${CashflowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCashflowGQL extends Apollo.Mutation<CreateCashflowMutation, CreateCashflowMutationVariables> {
    document = CreateCashflowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCashflowDocument = gql`
    mutation updateCashflow($input: CashflowUpdateInput!) {
  updateCashflow(input: $input) {
    ...Cashflow
  }
}
    ${CashflowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCashflowGQL extends Apollo.Mutation<UpdateCashflowMutation, UpdateCashflowMutationVariables> {
    document = UpdateCashflowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveCashflowDocument = gql`
    mutation removeCashflow($input: CashflowDeleteInput!) {
  removeCashflow(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveCashflowGQL extends Apollo.Mutation<RemoveCashflowMutation, RemoveCashflowMutationVariables> {
    document = RemoveCashflowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationDocument = gql`
    query getOrganization($id: ID!) {
  getOrganization(id: $id) {
    ...FullOrganization
  }
}
    ${FullOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationGQL extends Apollo.Query<GetOrganizationQuery, GetOrganizationQueryVariables> {
    document = GetOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCounterpartiesDocument = gql`
    query getCounterparties($organizationId: ID!) {
  getCounterparties(organizationId: $organizationId) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCounterpartiesGQL extends Apollo.Query<GetCounterpartiesQuery, GetCounterpartiesQueryVariables> {
    document = GetCounterpartiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLinkableOrganizationsDocument = gql`
    query getLinkableOrganizations($id: ID!) {
  getLinkableOrganizations(id: $id) {
    id
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLinkableOrganizationsGQL extends Apollo.Query<GetLinkableOrganizationsQuery, GetLinkableOrganizationsQueryVariables> {
    document = GetLinkableOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLinkedOrganizationsDocument = gql`
    query getLinkedOrganizations($id: ID!) {
  getLinkedOrganizations(id: $id) {
    id
    name
    code
    role
    etag
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLinkedOrganizationsGQL extends Apollo.Query<GetLinkedOrganizationsQuery, GetLinkedOrganizationsQueryVariables> {
    document = GetLinkedOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrganizationDocument = gql`
    mutation createOrganization($input: OrganizationCreateInput!) {
  createOrganization(input: $input) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganizationGQL extends Apollo.Mutation<CreateOrganizationMutation, CreateOrganizationMutationVariables> {
    document = CreateOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: OrganizationUpdateInput!) {
  updateOrganization(input: $input) {
    ...FullOrganization
  }
}
    ${FullOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationGQL extends Apollo.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> {
    document = UpdateOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganizationCurrenciesDocument = gql`
    mutation updateOrganizationCurrencies($input: OrganizationCurrenciesInput!) {
  updateOrganizationCurrencies(input: $input) {
    ...FullOrganization
  }
}
    ${FullOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationCurrenciesGQL extends Apollo.Mutation<UpdateOrganizationCurrenciesMutation, UpdateOrganizationCurrenciesMutationVariables> {
    document = UpdateOrganizationCurrenciesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganizationCounterpartiesDocument = gql`
    mutation updateOrganizationCounterparties($input: OrganizationCounterpartiesInput!) {
  updateOrganizationCounterparties(input: $input) {
    ...FullOrganization
  }
}
    ${FullOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationCounterpartiesGQL extends Apollo.Mutation<UpdateOrganizationCounterpartiesMutation, UpdateOrganizationCounterpartiesMutationVariables> {
    document = UpdateOrganizationCounterpartiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnableOrganizationClientDocument = gql`
    mutation enableOrganizationClient($input: OrganizationClientInput!) {
  enableOrganizationClient(input: $input) {
    ...FullOrganization
  }
}
    ${FullOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EnableOrganizationClientGQL extends Apollo.Mutation<EnableOrganizationClientMutation, EnableOrganizationClientMutationVariables> {
    document = EnableOrganizationClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisableOrganizationClientDocument = gql`
    mutation disableOrganizationClient($input: OrganizationClientInput!) {
  disableOrganizationClient(input: $input) {
    ...FullOrganization
  }
}
    ${FullOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DisableOrganizationClientGQL extends Apollo.Mutation<DisableOrganizationClientMutation, DisableOrganizationClientMutationVariables> {
    document = DisableOrganizationClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationClientSecretDocument = gql`
    query getOrganizationClientSecret($id: ID!) {
  getOrganizationClientSecret(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationClientSecretGQL extends Apollo.Query<GetOrganizationClientSecretQuery, GetOrganizationClientSecretQueryVariables> {
    document = GetOrganizationClientSecretDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LinkOrganizationDocument = gql`
    mutation linkOrganization($input: OrganizationLinkInput!) {
  linkOrganization(input: $input) {
    linked {
      id
      etag
    }
    linkedTo {
      id
      etag
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkOrganizationGQL extends Apollo.Mutation<LinkOrganizationMutation, LinkOrganizationMutationVariables> {
    document = LinkOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnlinkOrganizationDocument = gql`
    mutation unlinkOrganization($input: OrganizationLinkInput!) {
  unlinkOrganization(input: $input) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UnlinkOrganizationGQL extends Apollo.Mutation<UnlinkOrganizationMutation, UnlinkOrganizationMutationVariables> {
    document = UnlinkOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveOrganizationDocument = gql`
    mutation removeOrganization($input: OrganizationDeleteInput!) {
  removeOrganization(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveOrganizationGQL extends Apollo.Mutation<RemoveOrganizationMutation, RemoveOrganizationMutationVariables> {
    document = RemoveOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationLogoUploadUrlDocument = gql`
    query getOrganizationLogoUploadUrl($id: ID!) {
  getOrganizationLogoUploadUrl(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationLogoUploadUrlGQL extends Apollo.Query<GetOrganizationLogoUploadUrlQuery, GetOrganizationLogoUploadUrlQueryVariables> {
    document = GetOrganizationLogoUploadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPortfoliosDocument = gql`
    query getPortfolios($organizationId: ID!) {
  getPortfolios(organizationId: $organizationId) {
    ...Portfolio
  }
}
    ${PortfolioFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPortfoliosGQL extends Apollo.Query<GetPortfoliosQuery, GetPortfoliosQueryVariables> {
    document = GetPortfoliosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPortfolioDocument = gql`
    query getPortfolio($input: PortfolioGetInput!) {
  getPortfolio(input: $input) {
    ...Portfolio
  }
}
    ${PortfolioFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPortfolioGQL extends Apollo.Query<GetPortfolioQuery, GetPortfolioQueryVariables> {
    document = GetPortfolioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePortfolioDocument = gql`
    mutation createPortfolio($input: PortfolioCreateInput!) {
  createPortfolio(input: $input) {
    ...Portfolio
  }
}
    ${PortfolioFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePortfolioGQL extends Apollo.Mutation<CreatePortfolioMutation, CreatePortfolioMutationVariables> {
    document = CreatePortfolioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePortfolioDocument = gql`
    mutation updatePortfolio($input: PortfolioUpdateInput!) {
  updatePortfolio(input: $input) {
    ...Portfolio
  }
}
    ${PortfolioFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePortfolioGQL extends Apollo.Mutation<UpdatePortfolioMutation, UpdatePortfolioMutationVariables> {
    document = UpdatePortfolioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemovePortfolioDocument = gql`
    mutation removePortfolio($input: PortfolioDeleteInput!) {
  removePortfolio(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePortfolioGQL extends Apollo.Mutation<RemovePortfolioMutation, RemovePortfolioMutationVariables> {
    document = RemovePortfolioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTransactionAttachmentsDocument = gql`
    query getTransactionAttachments($input: TransactionAttachmentListInput!) {
  getTransactionAttachments(input: $input) {
    ...TransactionAttachment
  }
}
    ${TransactionAttachmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTransactionAttachmentsGQL extends Apollo.Query<GetTransactionAttachmentsQuery, GetTransactionAttachmentsQueryVariables> {
    document = GetTransactionAttachmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTransactionAttachmentUploadUrlDocument = gql`
    query getTransactionAttachmentUploadUrl($input: TransactionAttachmentUrlInput!) {
  getTransactionAttachmentUploadUrl(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTransactionAttachmentUploadUrlGQL extends Apollo.Query<GetTransactionAttachmentUploadUrlQuery, GetTransactionAttachmentUploadUrlQueryVariables> {
    document = GetTransactionAttachmentUploadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTransactionAttachmentDocument = gql`
    mutation createTransactionAttachment($input: TransactionAttachmentCreateInput!) {
  createTransactionAttachment(input: $input) {
    ...TransactionAttachment
  }
}
    ${TransactionAttachmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTransactionAttachmentGQL extends Apollo.Mutation<CreateTransactionAttachmentMutation, CreateTransactionAttachmentMutationVariables> {
    document = CreateTransactionAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveTransactionAttachmentDocument = gql`
    mutation removeTransactionAttachment($input: TransactionAttachmentDeleteInput!) {
  removeTransactionAttachment(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveTransactionAttachmentGQL extends Apollo.Mutation<RemoveTransactionAttachmentMutation, RemoveTransactionAttachmentMutationVariables> {
    document = RemoveTransactionAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTransactionImportsDocument = gql`
    query getTransactionImports($organizationId: ID!) {
  getTransactionImports(organizationId: $organizationId) {
    ...TransactionImport
  }
}
    ${TransactionImportFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTransactionImportsGQL extends Apollo.Query<GetTransactionImportsQuery, GetTransactionImportsQueryVariables> {
    document = GetTransactionImportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTransactionImportUploadUrlDocument = gql`
    query getTransactionImportUploadUrl($input: TransactionImportUrlInput!) {
  getTransactionImportUploadUrl(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTransactionImportUploadUrlGQL extends Apollo.Query<GetTransactionImportUploadUrlQuery, GetTransactionImportUploadUrlQueryVariables> {
    document = GetTransactionImportUploadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTransactionImportDocument = gql`
    mutation createTransactionImport($input: TransactionImportCreateInput!) {
  createTransactionImport(input: $input) {
    ...TransactionImport
  }
}
    ${TransactionImportFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTransactionImportGQL extends Apollo.Mutation<CreateTransactionImportMutation, CreateTransactionImportMutationVariables> {
    document = CreateTransactionImportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveTransactionImportDocument = gql`
    mutation removeTransactionImport($input: TransactionImportDeleteInput!) {
  removeTransactionImport(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveTransactionImportGQL extends Apollo.Mutation<RemoveTransactionImportMutation, RemoveTransactionImportMutationVariables> {
    document = RemoveTransactionImportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTransactionsDocument = gql`
    query getTransactions($input: TransactionListInput!) {
  getTransactions(input: $input) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTransactionsGQL extends Apollo.Query<GetTransactionsQuery, GetTransactionsQueryVariables> {
    document = GetTransactionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTransactionDocument = gql`
    query getTransaction($input: TransactionGetInput!) {
  getTransaction(input: $input) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTransactionGQL extends Apollo.Query<GetTransactionQuery, GetTransactionQueryVariables> {
    document = GetTransactionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTransactionDocument = gql`
    mutation createTransaction($input: TransactionCreateInput!) {
  createTransaction(input: $input) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTransactionGQL extends Apollo.Mutation<CreateTransactionMutation, CreateTransactionMutationVariables> {
    document = CreateTransactionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTransactionDocument = gql`
    mutation updateTransaction($input: TransactionUpdateInput!) {
  updateTransaction(input: $input) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTransactionGQL extends Apollo.Mutation<UpdateTransactionMutation, UpdateTransactionMutationVariables> {
    document = UpdateTransactionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveTransactionDocument = gql`
    mutation removeTransaction($input: TransactionDeleteInput!) {
  removeTransaction(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveTransactionGQL extends Apollo.Mutation<RemoveTransactionMutation, RemoveTransactionMutationVariables> {
    document = RemoveTransactionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserGroupsDocument = gql`
    query getUserGroups($organizationId: ID!) {
  getUserGroups(organizationId: $organizationId) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGroupsGQL extends Apollo.Query<GetUserGroupsQuery, GetUserGroupsQueryVariables> {
    document = GetUserGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserGroupFlatDocument = gql`
    query getUserGroupFlat($input: UserGroupGetInput!) {
  getUserGroup(input: $input) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGroupFlatGQL extends Apollo.Query<GetUserGroupFlatQuery, GetUserGroupFlatQueryVariables> {
    document = GetUserGroupFlatDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserGroupDeepDocument = gql`
    query getUserGroupDeep($input: UserGroupGetInput!) {
  getUserGroup(input: $input) {
    ...UserGroupDeep
  }
}
    ${UserGroupDeepFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGroupDeepGQL extends Apollo.Query<GetUserGroupDeepQuery, GetUserGroupDeepQueryVariables> {
    document = GetUserGroupDeepDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAssignableDocument = gql`
    query getAssignable($input: UserGroupGetInput!) {
  getAssignable(input: $input) {
    users {
      id
      firstName
      lastName
      organizationId
    }
    userGroups {
      id
      name
      organizationId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssignableGQL extends Apollo.Query<GetAssignableQuery, GetAssignableQueryVariables> {
    document = GetAssignableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserGroupDocument = gql`
    mutation createUserGroup($input: UserGroupCreateInput!) {
  createUserGroup(input: $input) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGroupGQL extends Apollo.Mutation<CreateUserGroupMutation, CreateUserGroupMutationVariables> {
    document = CreateUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserGroupDocument = gql`
    mutation updateUserGroup($input: UserGroupUpdateInput!) {
  updateUserGroup(input: $input) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGroupGQL extends Apollo.Mutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables> {
    document = UpdateUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUsersToUserGroupDocument = gql`
    mutation addUsersToUserGroup($input: UserGroupMembersInput!) {
  addUsersToUserGroup(input: $input) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUsersToUserGroupGQL extends Apollo.Mutation<AddUsersToUserGroupMutation, AddUsersToUserGroupMutationVariables> {
    document = AddUsersToUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUsersFromUserGroupDocument = gql`
    mutation removeUsersFromUserGroup($input: UserGroupMembersInput!) {
  removeUsersFromUserGroup(input: $input) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUsersFromUserGroupGQL extends Apollo.Mutation<RemoveUsersFromUserGroupMutation, RemoveUsersFromUserGroupMutationVariables> {
    document = RemoveUsersFromUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUserGroupsToUserGroupDocument = gql`
    mutation addUserGroupsToUserGroup($input: UserGroupMemberGroupsInput!) {
  addUserGroupsToUserGroup(input: $input) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUserGroupsToUserGroupGQL extends Apollo.Mutation<AddUserGroupsToUserGroupMutation, AddUserGroupsToUserGroupMutationVariables> {
    document = AddUserGroupsToUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserGroupsFromUserGroupDocument = gql`
    mutation removeUserGroupsFromUserGroup($input: UserGroupMemberGroupsInput!) {
  removeUserGroupsFromUserGroup(input: $input) {
    ...UserGroupFlat
  }
}
    ${UserGroupFlatFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGroupsFromUserGroupGQL extends Apollo.Mutation<RemoveUserGroupsFromUserGroupMutation, RemoveUserGroupsFromUserGroupMutationVariables> {
    document = RemoveUserGroupsFromUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserGroupDocument = gql`
    mutation removeUserGroup($input: UserGroupDeleteInput!) {
  removeUserGroup(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGroupGQL extends Apollo.Mutation<RemoveUserGroupMutation, RemoveUserGroupMutationVariables> {
    document = RemoveUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDocument = gql`
    query getUsers($organizationId: ID!) {
  getUsers(organizationId: $organizationId) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser($input: UserGetInput!) {
  getUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: UserCreateInput!) {
  createUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserUpdateInput!) {
  updateUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReinviteUserDocument = gql`
    mutation reinviteUser($input: UserReinviteInput!) {
  reinviteUser(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReinviteUserGQL extends Apollo.Mutation<ReinviteUserMutation, ReinviteUserMutationVariables> {
    document = ReinviteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BlockUserDocument = gql`
    mutation blockUser($input: UserBlockInput!) {
  blockUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockUserGQL extends Apollo.Mutation<BlockUserMutation, BlockUserMutationVariables> {
    document = BlockUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnblockUserDocument = gql`
    mutation unblockUser($input: UserBlockInput!) {
  unblockUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UnblockUserGQL extends Apollo.Mutation<UnblockUserMutation, UnblockUserMutationVariables> {
    document = UnblockUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserDocument = gql`
    mutation removeUser($input: UserDeleteInput!) {
  removeUser(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGQL extends Apollo.Mutation<RemoveUserMutation, RemoveUserMutationVariables> {
    document = RemoveUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWorkbenchChartDocument = gql`
    query getWorkbenchChart($input: WorkbenchChartInput!) {
  getWorkbenchChart(input: $input) {
    ...WorkbenchChart
  }
}
    ${WorkbenchChartFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWorkbenchChartGQL extends Apollo.Query<GetWorkbenchChartQuery, GetWorkbenchChartQueryVariables> {
    document = GetWorkbenchChartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHolidayCalendarDataDocument = gql`
    query getHolidayCalendarData($input: HolidayCalendarInput!) {
  getHolidayData(input: $input) {
    data
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHolidayCalendarDataGQL extends Apollo.Query<GetHolidayCalendarDataQuery, GetHolidayCalendarDataQueryVariables> {
    document = GetHolidayCalendarDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSettingsDocument = gql`
    query getSettings {
  getSettings {
    userPermissions
    organizationId
    auth0UserId
    userId
    organizationCode
    organizationName
    organizationRole
    creatorOrganizationId
    functionalCurrency
    supportedCurrencies
    counterpartyIds
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSettingsGQL extends Apollo.Query<GetSettingsQuery, GetSettingsQueryVariables> {
    document = GetSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const KeepAliveDocument = gql`
    query keepAlive {
  keepAlive {
    serverTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KeepAliveGQL extends Apollo.Query<KeepAliveQuery, KeepAliveQueryVariables> {
    document = KeepAliveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImageAccessTokenDocument = gql`
    query getImageAccessToken($input: ImageAccessInput!) {
  getImageAccessToken(input: $input) {
    expiresOn
    organizationId
    sasToken
    origin
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetImageAccessTokenGQL extends Apollo.Query<GetImageAccessTokenQuery, GetImageAccessTokenQueryVariables> {
    document = GetImageAccessTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }