import { APP_INITIALIZER, ErrorHandler, NgModule, Version } from "@angular/core";
import { CommonModule, SlicePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  translocoConfig,
  TranslocoModule,
  TranslocoService,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from "@ngneat/transloco";
import { TranslocoPersistLangModule, TRANSLOCO_PERSIST_LANG_STORAGE } from "@ngneat/transloco-persist-lang";
import {
  TranslocoDatePipe,
  TranslocoDecimalPipe,
  TranslocoLocaleModule,
  TranslocoLocaleService,
} from "@ngneat/transloco-locale";
import { AuthHttpInterceptor, AuthModule, AuthService } from "@auth0/auth0-angular";
import { ApplicationinsightsAngularpluginErrorService } from "@zaslavskyv.kleinod21/applicationinsights-angularplugin-js/dist/applicationinsights-angularplugin-js";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { BlockUIModule } from "ng-block-ui";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "../../environments/environment";
import { PermissionGuard } from "./authz/user-permission-guard";
import { PermissionDirective } from "./authz/user-permission-directive";
import { HttpCopyAuthInterceptor } from "./authz/http-interceptor-auth-header";
import { BlockUIComponent } from "./blockui/blockui.component";
import { ErrorDialogComponent } from "./components/error-dialog/error-dialog.component";
import { TableComponent } from "./components/table/table.component";
import { AutoResizeFixDirective } from "./directives/autoresize-fix.directive";
import { UtcFixDirective } from "./directives/utc-fix.directive";
import { NotionalInputDirective} from './directives/notional-input.directive';
import { DateInputDirective} from './directives/date-input.directive';
import { RateInputDirective} from './directives/rate-input.directive';
import { ImagePreloadDirective } from "./directives/image-preload.directive";
import { ConfirmDeactivateGuard } from "./guards/confirm-deactivate-guard";
import { translocoInitializer } from "./i18n/translation.initializer";
import { TranslocoHttpLoader } from "./i18n/translation.loader";
import { BooleanPipe } from "./pipes/boolean-pipe";
import { SwapPointsPipe } from "./pipes/swapPoints.pipe";
import { ExchangeRatePipe } from "./pipes/exchangeRate.pipe";
import { AmountPipe } from "./pipes/amount.pipe";
import { DynamicPipe } from "./pipes/dynamic-pipe";
import { EnumPipe } from "./pipes/enum-pipe";
import { SafeHtmlPipe } from "./pipes/safe-html-pipe";
import { StaticDataPipe } from "./pipes/staticdata-pipe";
import { APP_VERSION } from "./services/app.tokens";
import { ApplicationInsightsService } from "./services/applicationInsights.service";
import { createApollo } from "./services/apollo.helper";
import { UIBlockingHttpInterceptor } from "./services/uiBlockingHttpInterceptor";
import { PrimeNGModule } from "./primeng.module";
import { chartInitializer } from "./chart/chart.initializer";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PrimeNGModule,
    AuthModule.forRoot({
      clientId: environment.auth0_clientId,
      domain: environment.auth0_domain,
      audience: environment.auth0_audience,
      redirectUri: window.location.origin,
      httpInterceptor: {
        allowedList: [environment.graphqlURI],
      },
    }),
    BlockUIModule.forRoot({
      template: BlockUIComponent,
    }),
    NgxSpinnerModule,
    TranslocoModule,
    TranslocoPersistLangModule.init({
      storage: {
        provide: TRANSLOCO_PERSIST_LANG_STORAGE,
        useValue: localStorage,
      },
      storageKey: "language",
    }),
    TranslocoLocaleModule.init(),
  ],
  providers: [
    PermissionGuard,
    ConfirmDeactivateGuard,
    BooleanPipe,
    DynamicPipe,
    ExchangeRatePipe,
    SwapPointsPipe,
    AmountPipe,
    EnumPipe,
    SafeHtmlPipe,
    StaticDataPipe,
    SlicePipe,
    TranslocoDatePipe,
    TranslocoDecimalPipe,
    ApplicationInsightsService,
    AuthService,
    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
    { provide: APOLLO_OPTIONS, useFactory: createApollo, deps: [HttpLink] },
    { provide: HTTP_INTERCEPTORS, useClass: UIBlockingHttpInterceptor, multi: true }, // Important to be the first
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }, // Auth Interceptor emits two requests, but apparently cancelles one directly
    { provide: HTTP_INTERCEPTORS, useClass: HttpCopyAuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, multi: true, useFactory: chartInitializer, deps: [TranslocoLocaleService] },
    { provide: APP_INITIALIZER, multi: true, useFactory: translocoInitializer, deps: [TranslocoService] },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          { id: "en-GB", label: "English" },
          { id: "de-DE", label: "Deutsch" },
        ],
        defaultLang: "en-GB",
        fallbackLang: "en-GB",
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: APP_VERSION, useValue: new Version("0.1.0") },
  ],
  declarations: [
    ErrorDialogComponent,
    TableComponent,
    PermissionDirective,
    AutoResizeFixDirective,
    UtcFixDirective,
    ImagePreloadDirective,
    RateInputDirective,
    NotionalInputDirective,
    DateInputDirective,
    BlockUIComponent,
    BooleanPipe,
    DynamicPipe,
    EnumPipe,
    AmountPipe,
    SwapPointsPipe,
    SafeHtmlPipe,
    StaticDataPipe,
    ExchangeRatePipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    NgxSpinnerModule,
    TranslocoModule,
    TranslocoPersistLangModule,
    TranslocoLocaleModule,
    PrimeNGModule,
    ErrorDialogComponent,
    TableComponent,
    PermissionDirective,
    AutoResizeFixDirective,
    UtcFixDirective,
    ImagePreloadDirective,
    RateInputDirective,
    NotionalInputDirective,
    DateInputDirective,
    BooleanPipe,
    SwapPointsPipe,
    DynamicPipe,
    AmountPipe,
    EnumPipe,
    SafeHtmlPipe,
    StaticDataPipe,
    ExchangeRatePipe
  ],
})
export class SharedModule {}
