export function getLocaleDateString(locale: string) {
  const formats = {
    "af-ZA": "yy/mm/dd",
    "am-ET": "d/mm/yy",
    "ar-AE": "dd/mm/yy",
    "ar-BH": "dd/mm/yy",
    "ar-DZ": "dd-mm-yy",
    "ar-EG": "dd/mm/yy",
    "ar-IQ": "dd/mm/yy",
    "ar-JO": "dd/mm/yy",
    "ar-KW": "dd/mm/yy",
    "ar-LB": "dd/mm/yy",
    "ar-LY": "dd/mm/yy",
    "ar-mmA": "dd-mm-yy",
    "ar-Omm": "dd/mm/yy",
    "ar-QA": "dd/mm/yy",
    "ar-SA": "dd/mm/yy",
    "ar-SY": "dd/mm/yy",
    "ar-TN": "dd-mm-yy",
    "ar-YE": "dd/mm/yy",
    "arn-CL": "dd-mm-yy",
    "as-IN": "dd-mm-yy",
    "az-Cyrl-AZ": "dd.mm.yy",
    "az-Latn-AZ": "dd.mm.yy",
    "ba-RU": "dd.mm.yy",
    "be-BY": "dd.mm.yy",
    "bg-BG": "dd.mm.yy",
    "bn-BD": "dd-mm-yy",
    "bn-IN": "dd-mm-yy",
    "bo-CN": "yy/mm/d",
    "br-FR": "dd/mm/yy",
    "bs-Cyrl-BA": "d.mm.yy",
    "bs-Latn-BA": "d.mm.yy",
    "ca-ES": "dd/mm/yy",
    "co-FR": "dd/mm/yy",
    "cs-CZ": "d.mm.yy",
    "cy-GB": "dd/mm/yy",
    "da-DK": "dd-mm-yy",
    "de-AT": "dd.mm.yy",
    "de-CH": "dd.mm.yy",
    "de-DE": "dd.mm.yy",
    "de-LI": "dd.mm.yy",
    "de-LU": "dd.mm.yy",
    "dsb-DE": "d. mm. yy",
    "dv-mmV": "dd/mm/yy",
    "el-GR": "d/mm/yy",
    "en-029": "mm/dd/yy",
    "en-AU": "d/mm/yy",
    "en-BZ": "dd/mm/yy",
    "en-CA": "dd/mm/yy",
    "en-GB": "dd/mm/yy",
    "en-IE": "dd/mm/yy",
    "en-IN": "dd-mm-yy",
    "en-Jmm": "dd/mm/yy",
    "en-mmY": "d/mm/yy",
    "en-NZ": "d/mm/yy",
    "en-PH": "mm/d/yy",
    "en-SG": "d/mm/yy",
    "en-TT": "dd/mm/yy",
    "en-US": "mm/d/yy",
    "en-ZA": "yy/mm/dd",
    "en-ZW": "mm/d/yy",
    "es-AR": "dd/mm/yy",
    "es-BO": "dd/mm/yy",
    "es-CL": "dd-mm-yy",
    "es-CO": "dd/mm/yy",
    "es-CR": "dd/mm/yy",
    "es-DO": "dd/mm/yy",
    "es-EC": "dd/mm/yy",
    "es-ES": "dd/mm/yy",
    "es-GT": "dd/mm/yy",
    "es-HN": "dd/mm/yy",
    "es-mmX": "dd/mm/yy",
    "es-NI": "dd/mm/yy",
    "es-PA": "mm/dd/yy",
    "es-PE": "dd/mm/yy",
    "es-PR": "dd/mm/yy",
    "es-PY": "dd/mm/yy",
    "es-SV": "dd/mm/yy",
    "es-US": "mm/d/yy",
    "es-UY": "dd/mm/yy",
    "es-VE": "dd/mm/yy",
    "et-EE": "d.mm.yy",
    "eu-ES": "yy/mm/dd",
    "fa-IR": "mm/dd/yy",
    "fi-FI": "d.mm.yy",
    "fil-PH": "mm/d/yy",
    "fo-FO": "dd-mm-yy",
    "fr-BE": "d/mm/yy",
    "fr-CA": "yy-mm-dd",
    "fr-CH": "dd.mm.yy",
    "fr-FR": "dd/mm/yy",
    "fr-LU": "dd/mm/yy",
    "fr-mmC": "dd/mm/yy",
    "fy-NL": "d-mm-yy",
    "ga-IE": "dd/mm/yy",
    "gd-GB": "dd/mm/yy",
    "gl-ES": "dd/mm/yy",
    "gsw-FR": "dd/mm/yy",
    "gu-IN": "dd-mm-yy",
    "ha-Latn-NG": "d/mm/yy",
    "he-IL": "dd/mm/yy",
    "hi-IN": "dd-mm-yy",
    "hr-BA": "d.mm.yy.",
    "hr-HR": "d.mm.yy",
    "hsb-DE": "d. mm. yy",
    "hu-HU": "yy. mm. dd.",
    "hy-Amm": "dd.mm.yy",
    "id-ID": "dd/mm/yy",
    "ig-NG": "d/mm/yy",
    "ii-CN": "yy/mm/d",
    "is-IS": "d.mm.yy",
    "it-CH": "dd.mm.yy",
    "it-IT": "dd/mm/yy",
    "iu-Cans-CA": "d/mm/yy",
    "iu-Latn-CA": "d/mm/yy",
    "ja-JP": "yy/mm/dd",
    "ka-GE": "dd.mm.yy",
    "kk-KZ": "dd.mm.yy",
    "kl-GL": "dd-mm-yy",
    "km-KH": "yy-mm-dd",
    "kn-IN": "dd-mm-yy",
    "ko-KR": "yy-mm-dd",
    "kok-IN": "dd-mm-yy",
    "ky-KG": "dd.mm.yy",
    "lb-LU": "dd/mm/yy",
    "lo-LA": "dd/mm/yy",
    "lt-LT": "yy.mm.dd",
    "lv-LV": "yy.mm.dd.",
    "mi-NZ": "dd/mm/yy",
    "mk-mmK": "dd.mm.yy",
    "ml-IN": "dd-mm-yy",
    "mn-mmN": "yy.mm.dd",
    "mn-mmong-CN": "yy/mm/d",
    "moh-CA": "mm/d/yy",
    "mr-IN": "dd-mm-yy",
    "ms-BN": "dd/mm/yy",
    "ms-mmY": "dd/mm/yy",
    "mt-mmT": "dd/mm/yy",
    "nb-NO": "dd.mm.yy",
    "ne-NP": "mm/d/yy",
    "nl-BE": "d/mm/yy",
    "nl-NL": "d-mm-yy",
    "nn-NO": "dd.mm.yy",
    "nso-ZA": "yy/mm/dd",
    "oc-FR": "dd/mm/yy",
    "or-IN": "dd-mm-yy",
    "pa-IN": "dd-mm-yy",
    "pl-PL": "yy-mm-dd",
    "prs-AF": "dd/mm/yy",
    "ps-AF": "dd/mm/yy",
    "pt-BR": "d/mm/yy",
    "pt-PT": "dd-mm-yy",
    "qut-GT": "dd/mm/yy",
    "quz-BO": "dd/mm/yy",
    "quz-EC": "dd/mm/yy",
    "quz-PE": "dd/mm/yy",
    "rm-CH": "dd/mm/yy",
    "ro-RO": "dd.mm.yy",
    "ru-RU": "dd.mm.yy",
    "rw-RW": "mm/d/yy",
    "sa-IN": "dd-mm-yy",
    "sah-RU": "mm.dd.yy",
    "se-FI": "d.mm.yy",
    "se-NO": "dd.mm.yy",
    "se-SE": "yy-mm-dd",
    "si-LK": "yy-mm-dd",
    "sk-SK": "d. mm. yy",
    "sl-SI": "d.mm.yy",
    "sma-NO": "dd.mm.yy",
    "sma-SE": "yy-mm-dd",
    "smj-NO": "dd.mm.yy",
    "smj-SE": "yy-mm-dd",
    "smn-FI": "d.mm.yy",
    "sms-FI": "d.mm.yy",
    "sq-AL": "yy-mm-dd",
    "sr-Cyrl-BA": "d.mm.yy",
    "sr-Cyrl-CS": "d.mm.yy",
    "sr-Cyrl-mmE": "d.mm.yy",
    "sr-Cyrl-RS": "d.mm.yy",
    "sr-Latn-BA": "d.mm.yy",
    "sr-Latn-CS": "d.mm.yy",
    "sr-Latn-mmE": "d.mm.yy",
    "sr-Latn-RS": "d.mm.yy",
    "sv-FI": "d.mm.yy",
    "sv-SE": "yy-mm-dd",
    "sw-KE": "mm/d/yy",
    "syr-SY": "dd/mm/yy",
    "ta-IN": "dd-mm-yy",
    "te-IN": "dd-mm-yy",
    "tg-Cyrl-TJ": "dd.mm.yy",
    "th-TH": "d/mm/yy",
    "tk-Tmm": "dd.mm.yy",
    "tn-ZA": "yy/mm/dd",
    "tr-TR": "dd.mm.yy",
    "tt-RU": "dd.mm.yy",
    "tzm-Latn-DZ": "dd-mm-yy",
    "ug-CN": "yy-mm-d",
    "uk-UA": "dd.mm.yy",
    "ur-PK": "dd/mm/yy",
    "uz-Cyrl-UZ": "dd.mm.yy",
    "uz-Latn-UZ": "dd/mm yy",
    "vi-VN": "dd/mm/yy",
    "wo-SN": "dd/mm/yy",
    "xh-ZA": "yy/mm/dd",
    "yo-NG": "d/mm/yy",
    "zh-CN": "yy/mm/d",
    "zh-HK": "d/mm/yy",
    "zh-mmO": "d/mm/yy",
    "zh-SG": "d/mm/yy",
    "zh-TW": "yy/mm/d",
    "zu-ZA": "yy/mm/dd",
  };
  return formats[locale] || "dd/mm/yy";
}
