import { Component,  } from "@angular/core";
import { Product } from "../product";

@Component({
  selector: "app-display-summary",
  templateUrl: "./displaySummary.component.html",
})
export class DisplaySummaryComponent {

  product: Product;
}

