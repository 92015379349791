import { Directive, Input, OnChanges, SimpleChanges, Host } from "@angular/core";
import { marketConvention } from "@hedgebench/shared";
import { InputNumber } from "primeng/inputnumber";

@Directive({
  selector: "[notionalInput]",
})
export class NotionalInputDirective implements OnChanges {
  @Input() ccy: string;
  @Input() showCurrency: boolean;


  constructor(@Host() private  readonly input:InputNumber) {
    input.onKeyDown.subscribe(x=>this.updateValue(x))
    input.mode = "decimal";
  }

  updateValue(e:KeyboardEvent) : void
  {
    const key = e.key.toUpperCase();
    if (key=="K" || key =="T")
    {
     this.input.updateModel(null,Number.parseFloat(this.input.lastValue)*1000);
    }
    if (key=="M")
    {
      this.input.updateModel(null,Number.parseFloat(this.input.lastValue)*1000000);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ccy) {
      if (this.ccy && this.ccy != "") {
        const convention = marketConvention.getCurrencyConvention(this.ccy);
        this.input.maxFractionDigits = convention.notionalDecimals;
        this.input.minFractionDigits = convention.notionalDecimals;
        this.input.suffix =  this.showCurrency ? " "+this.ccy : null;
      }
    }
  }
}
