<div class="topbar clearfix" *transloco="let t">
  <a id="topbar-menu-button" href="#" (click)="main.onTopbarMenuButtonClick($event)">
    <i class="pi pi-bars"></i>
  </a>

  <ul class="topbar-menu fadeInDown" [class.topbar-menu-visible]="main.topbarMenuActive">
    <li #language class="language-item p-mt-0" [class.active-topmenuitem]="main.activeTopbarItem === language">
      <a href="#" (click)="main.onTopbarItemClick($event, language)">
        <div class="language-info">
          <span class="flag flag-{{ languageFlagMap[selectedLanguage] }}"></span>
        </div>
      </a>

      <ul class="fadeInDown">
        <li role="menuitem" *ngFor="let language of $any(availableLanguages)">
          <a href="javascript:void(0)" class="nowrap" (click)="switchLanguage(language.id)">
            <span class="flag flag-{{ languageFlagMap[language.id] }}"></span>
            <span class="p-ml-2">{{ language.label }}</span>
          </a>
        </li>
      </ul>
    </li>

    <li #profile class="profile-item" [class.active-topmenuitem]="main.activeTopbarItem === profile">
      <a href="#" (click)="main.onTopbarItemClick($event, profile)">
        <div class="profile-info">
          <span class="topbar-item-name profile-name">{{ appSettings.userDisplayName }}</span>
          <span class="topbar-item-name profile-role">{{ appSettings.organizationName }}</span>
        </div>
      </a>

      <ul class="fadeInDown">
        <li role="menuitem">
          <a>
            <span class="nowrap">HedgeBench v{{ version.full }}</span>
          </a>
        </li>

        <hr class="p-m-0" />

        <li role="menuitem">
          <a href="/logout">
            <i class="pi pi-sign-out"></i>
            <span>{{ t("logout.title") }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
