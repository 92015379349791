<ng-container *transloco="let t">
  <div
    *ngIf="!hidden"
    class="layout-sidebar"
    [ngStyle]="{ overflow: main.sidebarActive ? 'hidden' : 'visible' }"
    (click)="main.onSidebarClick()"
  >
    <div class="layout-tabmenu">
      <ul class="layout-tabmenu-nav">
        <ng-container *ngFor="let item of items; index as i">
          <ng-container *ngIf="item.routerLink">
            <li [class.active-item]="main.activeTabIndex === i">
              <a
                pRipple
                [routerLink]="item.routerLink"
                class="tabmenuitem-link"
                (click)="main.activeTabIndex = i; main.sidebarActive = false"
              >
                <i [class]="item.icon"></i>
              </a>
              <div class="layout-tabmenu-tooltip">
                <div class="layout-tabmenu-tooltip-arrow"></div>
                <div class="layout-tabmenu-tooltip-text">{{ item.label }}</div>
              </div>
            </li>
          </ng-container>

          <ng-container *ngIf="!item.routerLink">
            <li *ngIf="hasItems(item.section)" [class.active-item]="main.activeTabIndex === i">
              <a href="javascript:void(0)" pRipple class="tabmenuitem-link" (click)="main.onTabClick($event, i)">
                <i [class]="item.icon"></i>
              </a>
              <div class="layout-tabmenu-tooltip">
                <div class="layout-tabmenu-tooltip-arrow"></div>
                <div class="layout-tabmenu-tooltip-text">{{ item.label }}</div>
              </div>
            </li>
          </ng-container>
        </ng-container>
      </ul>

      <div class="layout-tabmenu-contents">
        <ng-container *ngFor="let item of items; index as i">
          <div
            *ngIf="!item.routerLink && hasItems(item.section)"
            class="layout-tabmenu-content"
            [class.layout-tabmenu-content-active]="main.activeTabIndex === i"
          >
            <div class="layout-submenu-title clearfix">
              <span>{{ item.label }}</span>
              <a href="javascript:void(0)" class="menu-button pi pi-bars" (click)="main.closeSidebar($event)"></a>
            </div>
            <div class="layout-submenu-content">
              <app-menu [menuSection]="item.section"></app-menu>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
