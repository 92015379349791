<div class="footer" *transloco="let t">
  <div class="p-d-flex p-jc-between footer-inner">
    <div class="flexy">
      <img class="logo" *ngIf="creatorOrganizationLogo" [src]="creatorOrganizationLogo" />
    </div>

    <div class="p-as-center">
      <a href="mailto:feedback@kleinod21.com?subject=Feedback%20-%20HedgeBench">{{ t("feedback.title") }}</a>
    </div>

    <div class="flexy p-text-right">
      <img class="logo" *ngIf="organizationLogo" [src]="organizationLogo" />
    </div>
  </div>
</div>
