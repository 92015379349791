import { TranslocoLocaleService } from "@ngneat/transloco-locale";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CurrencyColorPlugin } from "./currency-color.plugin";
import { FormatPlugin } from "./format.plugin";
import { MultiLegendPlugin } from "./multi-legend.plugin";

import "chart.js/auto";
import "moment";
import "chartjs-adapter-moment";

export function chartInitializer(transloco: TranslocoLocaleService): () => Promise<void> {
  return () => {
    Chart.register(ChartDataLabels, CurrencyColorPlugin, FormatPlugin(transloco), MultiLegendPlugin);
    return Promise.resolve();
  };
}
