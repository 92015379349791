<div class="p-fluid" [formGroup]="form" *transloco="let t">
  <div class="p-grid">
    <div class="p-field p-col-6">
      <label for="buyCurrency">{{ t("buyCurrency.title") }}</label>
      <p-dropdown
        inputId="buyCurrency"
        formControlName="buyCurrency"
        appendTo="body"
        [options]="buyCurrencies"
        [required]="true"
        [placeholder]="t('buyCurrency.placeholder')"
      ></p-dropdown>
      <small *ngIf="form.controls.buyCurrency.errors?.required" class="p-error">
        {{ t("buyCurrency.required") }}
      </small>
    </div>

    <div class="p-field p-col-6">
      <label for="sellCurrency">{{ t("sellCurrency.title") }}</label>
      <p-dropdown
        inputId="sellCurrency"
        formControlName="sellCurrency"
        appendTo="body"
        [options]="sellCurrencies"
        [required]="true"
        [placeholder]="t('sellCurrency.placeholder')"
      ></p-dropdown>
      <small *ngIf="form.controls.sellCurrency.errors?.required" class="p-error">
        {{ t("sellCurrency.required") }}
      </small>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-field p-col-6">
      <label for="amountCurrency">{{ t("currency.title") }}</label>
      <p-dropdown
        inputId="amountCurrency"
        formControlName="amountCurrency"
        appendTo="body"
        [options]="currencies"
        [required]="true"
        [placeholder]="t('currency.placeholder')"
      ></p-dropdown>
      <small *ngIf="form.controls.amountCurrency.errors?.required" class="p-error">
        {{ t("currency.required") }}
      </small>
    </div>

    <div class="p-field p-col-6">
      <label for="amount">{{ t("amount.title") }}</label>
      <p-inputNumber
        notionalInput
        [showCurrency] = "false"
        [ccy] = "form.value.amountCurrency"
        inputId="amount"
        formControlName="amount"
        [locale]="locale"
        [required]="true"
        [placeholder]="t('amount.placeholder')"
      ></p-inputNumber>
      <small *ngIf="form.controls.amount.errors?.required" class="p-error">
        {{ t("amount.required") }}
      </small>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-field p-col-6">
      <label for="valueDate">{{ t("valueDate.title") }}</label>
      <p-calendar
        utcFix
        dateInput
        [ccy1]="form.value.buyCurrency"
        [ccy2]="form.value.sellCurrency"
        inputId="valueDate"
        formControlName="valueDate"
        appendTo="body"
        [dateFormat]="dateFormat"
        [showIcon]="true"
        [required]="true"
      ></p-calendar>
      <small *ngIf="form.controls.valueDate.errors?.required" class="p-error">
        {{ t("valueDate.required") }}
      </small>
    </div>

    <div class="p-field p-col-6">
      <label for="termOption">{{ t("termOption.title") }}</label>

      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <p-checkbox #hasTermOption [binary]="true"></p-checkbox>
        </span>
        <p-calendar
          dateInput
          [ccy1]="form.value.buyCurrency"
          [ccy2]="form.value.sellCurrency"
          [referenceDate]="form.value.valueDate"
          utcFix
          inputId="termOption"
          formControlName="termOption"
          appendTo="body"
          [dateFormat]="dateFormat"
          [minDate]="minTermOption"
          [showIcon]="true"
          [required]="hasTermOption.checked"
        ></p-calendar>
      </div>
      <small *ngIf="form.controls.termOption.errors?.required" class="p-error">
        {{ t("termOption.required") }}
      </small>
    </div>
  </div>

  <div class="p-field">
    <label for="exchangeRate">{{ t("exchangeRate.title") }}</label>
    <p-inputNumber
      rateInput
      [ccy1]="form.value.buyCurrency"
      [ccy2]="form.value.sellCurrency"
      inputId="exchangeRate"
      formControlName="exchangeRate"
      [locale]="locale"
      [required]="true"
      [placeholder]="t('exchangeRate.placeholder')"
    ></p-inputNumber>
    <small *ngIf="form.controls.exchangeRate.errors?.required" class="p-error">
      {{ t("exchangeRate.required") }}
    </small>
  </div>
</div>
