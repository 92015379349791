import { ProductType } from "../products/products.model";
import { StaticDataSet, StaticDataModel, StaticDataType } from "../static-data.model";

const germanState: StaticDataModel[] = [
  { value: "DE-BW", label: "Baden-Württemberg" },
  { value: "DE-BY", label: "Bayern" },
  { value: "DE-BE", label: "Berlin" },
  { value: "DE-BB", label: "Brandenburg" },
  { value: "DE-HB", label: "Bremen" },
  { value: "DE-HH", label: "Hamburg" },
  { value: "DE-HE", label: "Hessen" },
  { value: "DE-MV", label: "Mecklenburg-Vorpommern" },
  { value: "DE-NI", label: "Niedersachsen" },
  { value: "DE-NW", label: "Nordrhein-Westfalen" },
  { value: "DE-RP", label: "Rheinland-Pfalz" },
  { value: "DE-SL", label: "Saarland" },
  { value: "DE-SN", label: "Sachsen" },
  { value: "DE-ST", label: "Sachsen-Anhalt" },
  { value: "DE-SH", label: "Schleswig-Holstein" },
  { value: "DE-TH", label: "Thüringen" },
];

const countries: StaticDataModel[] = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Åland" },
  { value: "AL", label: "Albanien" },
  { value: "DZ", label: "Algerien" },
  { value: "AS", label: "Amerikanisch-Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AG", label: "Antigua und Barbuda" },
  { value: "AR", label: "Argentinien" },
  { value: "AM", label: "Armenien" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australien" },
  { value: "AT", label: "Österreich" },
  { value: "AZ", label: "Aserbaidschan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesch" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgien" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivien" },
  { value: "BA", label: "Bosnien und Herzegowina" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Brasilien" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgarien" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Kambodscha" },
  { value: "CM", label: "Kamerun" },
  { value: "CA", label: "Kanada" },
  { value: "CV", label: "Kap Verde" },
  { value: "KY", label: "Kaimaninseln " },
  { value: "CF", label: "Zentralafrikanische Republik" },
  { value: "TD", label: "Tschad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CO", label: "Kolumbien" },
  { value: "KM", label: "Komoren" },
  { value: "CG", label: "Republik Kongo" },
  { value: "CD", label: "Demokratische Republik Kongo" },
  { value: "CK", label: "Cookinseln" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Elfenbeinküste" },
  { value: "HR", label: "Kroatien" },
  { value: "CU", label: "Kuba" },
  { value: "CY", label: "Zypern" },
  { value: "CZ", label: "Tschechien " },
  { value: "DK", label: "Dänemark" },
  { value: "DJ", label: "Dschibuti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominikanische Republik" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Ägypten" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Äquatorialguinea " },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estland" },
  { value: "ET", label: "Äthiopien " },
  { value: "FK", label: "Falklandinseln" },
  { value: "FO", label: "Färöer" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "Frankreich" },
  { value: "GF", label: "Französisch-Guyana" },
  { value: "PF", label: "Französisch-Polynesien" },
  { value: "GA", label: "Gabun" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgien" },
  { value: "DE", label: "Deutschland" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Griechenland" },
  { value: "GL", label: "Grönland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "VA", label: "Vatikanstadt" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Ungarn" },
  { value: "IS", label: "Island " },
  { value: "IN", label: "Indien" },
  { value: "ID", label: "Indonesien" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Irak" },
  { value: "IE", label: "Irland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italien" },
  { value: "JM", label: "Jamaika" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordanien" },
  { value: "KZ", label: "Kasachstan" },
  { value: "KE", label: "Kenia" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Nordkorea" },
  { value: "KR", label: "Südkorea" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kirgisistan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Lettland" },
  { value: "LB", label: "Libanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyen" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Litauen" },
  { value: "LU", label: "Luxemburg" },
  { value: "MK", label: "Nordmazedonien " },
  { value: "MG", label: "Madagaskar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Malediven" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshallinseln" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauretanien" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mexiko" },
  { value: "FM", label: "Föderierte Staaten von Mikronesien" },
  { value: "MD", label: "Moldawien" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolei" },
  { value: "MA", label: "Marokko" },
  { value: "MZ", label: "Mosambik" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Niederlande" },
  { value: "NZ", label: "Neuseeland" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NO", label: "Norwegen" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palästinensische Autonomiegebiete" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua-Neuguinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippinen" },
  { value: "PL", label: "Polen" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Katar" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Rumänien" },
  { value: "RU", label: "Russland" },
  { value: "RW", label: "Ruanda" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Sankt Lucia" },
  { value: "VC", label: "Sankt Vincent und die Grenadinen" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "São Tomé und Príncipe" },
  { value: "SA", label: "Saudi-Arabien" },
  { value: "SN", label: "Senegal" },
  { value: "CS", label: "Serbien und Montenegro" },
  { value: "SC", label: "Seychellen" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapur" },
  { value: "SK", label: "Slowakei" },
  { value: "SI", label: "Slowenien" },
  { value: "SB", label: "Salomonen" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "Südafrika" },
  { value: "ES", label: "Spanien" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SZ", label: "Swasiland" },
  { value: "SE", label: "Schweden" },
  { value: "CH", label: "Schweiz" },
  { value: "SY", label: "Syrien" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tadschikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Osttimor" },
  { value: "TG", label: "Togo" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunesien" },
  { value: "TR", label: "Türkei" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "Vereinigte Arabische Emirate" },
  { value: "GB", label: "Vereinigtes Königreich" },
  { value: "US", label: "USA" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Usbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "YE", label: "Jemen" },
  { value: "ZM", label: "Sambia" },
  { value: "ZW", label: "Simbabwe" },
];

const languages: StaticDataModel[] = [
  { value: "AR", label: "Arabisch" },
  //{ value: "ZH", label: "Chinese" },
  //{ value: "DA", label: "Danish" },
  { value: "DE", label: "Deutsch" },
  { value: "EN", label: "Englisch" },
  { value: "ES", label: "Spanisch" },
  //{ value: "FA", label: "Persian" },
  //{ value: "FI", label: "Finnish" },
  { value: "FR", label: "Französisch" },
  //{ value: "GA", label: "Irish" },
  //{ value: "HE", label: "Hebrew" },
  //{ value: "HI", label: "Hindi" },
  { value: "IT", label: "Italienisch" },
  //{ value: "JA", label: "Japanese" },
  //{ value: "NL", label: "Dutch; Flemish" },
  //{ value: "NO", label: "Norwegian" },
  { value: "PT", label: "Portugiesisch" },
  { value: "RU", label: "Russisch" },
  //{ value: "SV", label: "Swedish" },
  //{ value: "AZ", label: "Turkish" },
  //{ value: "UK", label: "Ukrainian" },
];

const occupation: StaticDataModel[] = [
  { value: "MIDWIFE", label: "Entbindungspfleger" }, //Hebamme
  { value: "ATA", label: "Anästhesietechnischer Assistent" }, // Narkseführung // Anästhesietechnischer Assistent
  { value: "OTA", label: "Operationstechnischer Assistent" }, //Krankheitslehre primär auf zu operierende Krankheiten // Operationstechnischen Assistenten
  { value: "NURSE", label: "Krankenpfleger" },
  { value: "PARAMEDIC", label: "Rettungssanitäter" },
  { value: "MTRA", label: "Medizinisch technischer Radiologieassistent" }, // Medizinisch-technische Radiologieassistenten
  { value: "MFA", label: "Medizinischer Fachangestellter" }, // Medizinische Fachangestellte
  { value: "MTLA", label: "Medizinisch technischer Laboratoriumsassistent" }, // Medizinische Fachangestellte
  { value: "PHYSIOTHERAPIST", label: "Physiotherapeut" },
  { value: "DOCTOR", label: "Arzt" },
];

const education: StaticDataModel[] = [
  { value: "VT", label: "Berufsausbildung" },
  { value: "HS", label: "Highschool/Abitur" },
  { value: "BA", label: "Bachelor" },
  { value: "MS", label: "Master" },
  { value: "PHD", label: "Doktor" },
];

const salutation: StaticDataModel[] = [
  { value: "MR", label: "Herr" },
  { value: "MRS", label: "Frau" },
  { value: "MX", label: "Mx" },
];

const gender: StaticDataModel[] = [
  { value: "FEMALE", label: "weiblich" },
  { value: "MALE", label: "männlich" },
  { value: "OTHER", label: "neutral" },
];

const martialStatus: StaticDataModel[] = [
  { value: "SINGLE", label: "Single" },
  { value: "ENGAGED", label: "Verlobt" },
  { value: "MARRIED", label: "Verheiratet" },
  { value: "DIVORCED", label: "Geschieden" },
  { value: "WIDOWED", label: "Verwitwet" },
];

const professionalFields: StaticDataModel[] = [
  { value: "AK", label: "Akutgeriatrie / Remobilisation" },
  { value: "AM", label: "Ambulanz " },
  { value: "AN", label: "Anästhesiologie und Intensivmedizin (Narkosen, Notfallmedizin, Schmerztherapie)" },
  { value: "AU", label: "Augenheilkunde" },
  { value: "BU", label: "Verbrennungszentrum" },
  { value: "CH", label: "Chirurgie " },
  { value: "DE", label: "Dermatologie " },
  { value: "DI", label: "Dialyse " },
  { value: "GE", label: "Geriatrie " },
  { value: "GY", label: "Gynäkologie / Geburtshilfe" },
  { value: "HA", label: "Hals-, Nasen- und Ohrenheilkunde" },
  { value: "IC", label: "ICU" },
  { value: "IN", label: "Innere Medizin" },
  { value: "KA", label: "Kardiologie " },
  { value: "KIC", label: "Kinder- und Jugendchirurgie" },
  { value: "KIH", label: "Kinder- und Jugendheilkunde" },
  { value: "KIP", label: "Kinder- und Jugendpsychiatrie" },
  { value: "MU", label: "Mund-, Kiefer- und Gesichtschirurgie" },
  { value: "NEP", label: "Nephrologie" },
  { value: "NER", label: "Neurologie" },
  { value: "NEC", label: "Neurochirurgie" },
  { value: "NI", label: "NICU" },
  { value: "NU", label: "Nuklearmedizin " },
  { value: "ON", label: "Onkologie " },
  { value: "OR", label: "Orthopädie " },
  { value: "OT", label: "Otolaryngologie" },
  { value: "PA", label: "Palliativ " },
  { value: "PE", label: "Pädiatrie " },
  { value: "PH", label: "Physiotherapie" },
  { value: "PI", label: "PICU" },
  { value: "PL", label: "Platische Chirurgie" },
  { value: "PN", label: "Pneumologie " },
  { value: "PS", label: "Psychatrie " },
  { value: "PSM", label: "Psychosomatik " },
  { value: "RA", label: "Radiologie " },
  { value: "RAO", label: "Radioonkologie" },
  { value: "ST", label: "Stroke" },
  { value: "UN", label: "Unfallchirurgie" },
  { value: "UR", label: "Urologie" },
  { value: "ZA", label: "Zahn, Mund, Kiefer" },
  { value: "ZNA", label: "Zentrale Notaufnahme" },
  { value: "VAR", label: "Sonstiges" },
];

const languageLevels: StaticDataModel[] = [
  { value: "A1", label: "A1" },
  { value: "A2", label: "A2" },
  { value: "B1", label: "B1" },
  { value: "B2", label: "B2" },
  { value: "C1", label: "C1" },
  { value: "C2", label: "C2" },
  { value: "NV", label: "NV" },
];

const allowedFileTypes: StaticDataModel[] = [
  { value: "CV", label: "Lebenslauf" },
  { value: "PASSPORT", label: "Reisepass" },
  { value: "DIPLOMA", label: "Diplomurkunde" },
  { value: "LISTOFGRADES", label: "Notenliste" },
  { value: "LANGCERT", label: "Spachzertifikat" },
  { value: "WORKCERT", label: "Arbeitsbescheinigung" },
  { value: "NURSINGLICENSE", label: "Krankenpfleger Zulassung" },
  { value: "BIRTHCERT", label: "Geburtsurkunde" },
  { value: "CERTOFEDU", label: "Nachweis von Weiterbildung" },
  { value: "POLICERECORD", label: "Polizeiliches Führungszeugnis" },
  { value: "CERTOFHEALTH", label: "Gesundheitsnachweis" },
  { value: "CERTOFVACC", label: "Impfpass" },
  { value: "RECDOC", label: "Anerkennungsbescheid" },
  { value: "POAREC", label: "Vollmacht für Anerkennung" },
  { value: "POAFEG", label: "Vollmacht für FEG" },
  { value: "APPSTATE", label: "Antrag auf Staatsbürgeschaft" },
  { value: "CONTRACTLANG", label: "Vertrag Sprachschule" },
  { value: "VISASTATEMENT", label: "Erklärung zu bereits beantragten oder durchgeführten Visaverfahren" },
  { value: "CONTRACTWORK", label: "Arbeitsvertrag" },
  { value: "VISA", label: "Visa" },
  { value: "Registration", label: "Registrierung" },
  { value: "OTHER", label: "Sonstiges" },
];

const careFacilities: StaticDataModel[] = [
  { value: "UNVHOSPITAL", label: "Unv. Krankenhaus" },
  { value: "MILTHOSPITAL", label: "Milt. Krankenhaus" },
  { value: "PRIVHOSPITAL", label: "Privates Krankenhaus" },
  { value: "PUBHOSPITAL", label: "Öffentliches Krankenhaus" },
  { value: "COMPNURSE", label: "Betriebskrankenpfleger" },
  { value: "REHA", label: "Rehaklinik" },
  { value: "HCA", label: "Heimpflege / Erwachsene" },
  { value: "HCC", label: "Heimpflege / Kinder" },
  { value: "ECC", label: "Altenpflege" },
  { value: "POLICLINIC", label: "Poliklinik" },
  { value: "DOCOFFICE", label: "Arztpraxis" },
];

const workArea: StaticDataModel[] = [
  { value: "STATION", label: "Station" },
  { value: "OP", label: "OP" },
];

const currency: StaticDataModel[] = [
  { value: "EUR", label: "EUR" },
  { value: "USD", label: "USD" },
  { value: "CHF", label: "CHF" },
  { value: "GBP", label: "GBP" },
  { value: "NOK", label: "NOK" },
  { value: "SEK", label: "SEK" },
  { value: "DKK", label: "DKK" },
  { value: "SGD", label: "SGD" },
  { value: "AUD", label: "AUD" },
  { value: "JPY", label: "JPY" },
  { value: "NZD", label: "NZD" },
  { value: "CAD", label: "CAD" },
  { value: "AED", label: "AED" },
  { value: "TRY", label: "TRY" },
  { value: "THB", label: "THB" },
  { value: "CZK", label: "CZK" },
  { value: "PLN", label: "PLN" },
  { value: "ZAR", label: "ZAR" },
];

const product: StaticDataModel[] = [
  { value: ProductType.FxForward, label: "FX Forward" },
  { value: ProductType.FxSwap, label: "FX Swap" },
];

export const StaticDataDeDE: StaticDataSet = {
  "StaticDataType.MartialStatus": martialStatus,
  "StaticDataType.Gender": gender,
  "StaticDataType.Salutation": salutation,
  "StaticDataType.Countries": countries,
  "StaticDataType.GermanState": germanState,
  "StaticDataType.Languages": languages,
  "StaticDataType.Occupation": occupation,
  "StaticDataType.Education": education,
  "StaticDataType.CareFacilities": careFacilities,
  "StatStaticDataType.LanguageLevels": languageLevels,
  "StatStaticDataType.ProfessionalFields": professionalFields,
  "StatStaticDataType.AllowedUploadFileTypes": allowedFileTypes,
  "StatStaticDataType.WorkArea": workArea,
  [StaticDataType.Currency]: currency,
  [StaticDataType.Product]: product,
};
