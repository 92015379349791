import { TranslocoLocaleService } from "@ngneat/transloco-locale";

function format(service: TranslocoLocaleService, config: any, value: any, currency?: string): string {
  value = config.options?.absolute ? Math.abs(value) : value;
  switch (config.mode) {
    case "date":
      return Array.isArray(value)
        ? `${service.localizeDate(value[0])} - ${service.localizeDate(value[1])}`
        : service.localizeDate(value);
    case "decimal":
      return service.localizeNumber(value, "decimal", undefined, config.options);
    case "currency":
      return `${service.localizeNumber(value, "decimal", undefined, config.options)} ${currency}`;
  }
}

export const FormatPlugin = (service: TranslocoLocaleService) => ({
  id: "format",
  beforeInit: function (chart, _args, options) {
    if (options.scales?.x) {
      if (!chart.options.scales.x) {
        chart.options.scales.x = { ticks: {} };
      }
      chart.options.scales.x.ticks.callback = (_, i, ts) =>
        format(
          service,
          options.scales.x,
          chart.data.sublabels && chart.data.sublabels[i] ? [ts[i].value, chart.data.sublabels[i]] : ts[i].value
        );
    }

    if (options.scales?.y) {
      if (!chart.options.scales.y) {
        chart.options.scales.y = { ticks: {} };
      }
      chart.options.scales.y.ticks.callback = (_, i, ts) => format(service, options.scales.y, ts[i].value);
    }

    if (options.tooltip?.title) {
      chart.options.plugins.tooltip.callbacks.title = (ts) => format(service, options.tooltip.title, ts[0].parsed.x);
    }

    if (options.tooltip?.label) {
      chart.options.plugins.tooltip.callbacks.label = (t) =>
        format(service, options.tooltip.label, t.parsed.y, t.dataset.label);
    }

    if (options.datalabels?.label) {
      chart.options.plugins.datalabels.formatter = (v, t) =>
        format(service, options.datalabels.label, v, t.dataset.label);
    }
  },
});
