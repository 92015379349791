export enum errorMessageKey {
  ORGANIZATION_NAME_EXISTS = "ORGANIZATION_NAME_EXISTS",
  ORGANIZATION_CODE_EXISTS = "ORGANIZATION_CODE_EXISTS",
  ORGANIZATION_EMAIL_EXISTS = "ORGANIZATION_EMAIL_EXISTS",
  USER_EMAIL_EXISTS = "USER_EMAIL_EXISTS",
  GROUP_NAME_EXISTS = "GROUP_NAME_EXISTS",
  ACCOUNT_IBAN_EXISTS = "ACCOUNT_IBAN_EXISTS",
  PORTFOLIO_NAME_EXISTS = "PORTFOLIO_NAME_EXISTS",
  TRANSACTION_REFERENCE_EXISTS = "TRANSACTION_REFERENCE_EXISTS",
  FILE_MALFORMED = "FILE_MALFORMED",
  FILE_EMPTY = "FILE_EMPTY",
  FILE_CURRENCY_WRONG_TYPE = "FILE_CURRENCY_WRONG_TYPE",
  FILE_CURRENCY_UNSUPPORTED = "FILE_CURRENCY_UNSUPPORTED",
  FILE_VALUE_DATE_WRONG_TYPE = "FILE_VALUE_DATE_WRONG_TYPE",
  FILE_VALUE_DATE_WRONG_FORMAT = "FILE_VALUE_DATE_WRONG_FORMAT",
  FILE_VALUE_DATE_UNSUPPORTED = "FILE_VALUE_DATE_UNSUPPORTED",
  FILE_DESCRIPTION_WRONG_TYPE = "FILE_DESCRIPTION_WRONG_TYPE",
  FILE_STATE_WRONG_TYPE = "FILE_STATE_WRONG_TYPE",
  FILE_STATE_UNSUPPORTED = "FILE_STATE_UNSUPPORTED",
  FILE_AMOUNT_WRONG_TYPE = "FILE_AMOUNT_WRONG_TYPE",
  FILE_AMOUNT_UNSUPPORTED = "FILE_AMOUNT_UNSUPPORTED",
  FILE_PRODUCT_WRONG_TYPE = "FILE_PRODUCT_WRONG_TYPE",
  FILE_PRODUCT_UNSUPPORTED = "FILE_PRODUCT_UNSUPPORTED",
  FILE_REFERENCE_WRONG_TYPE = "FILE_REFERENCE_WRONG_TYPE",
  FILE_COUNTERPARTY_WRONG_TYPE = "FILE_COUNTERPARTY_WRONG_TYPE",
  FILE_COUNTERPARTY_UNSUPPORTED = "FILE_COUNTERPARTY_UNSUPPORTED",
  FILE_DEAL_DATE_WRONG_TYPE = "FILE_DEAL_DATE_WRONG_TYPE",
  FILE_DEAL_DATE_WRONG_FORMAT = "FILE_DEAL_DATE_WRONG_FORMAT",
  FILE_EXCHANGE_RATE_WRONG_TYPE = "FILE_EXCHANGE_RATE_WRONG_TYPE",
  FILE_EXCHANGE_RATE_UNSUPPORTED = "FILE_EXCHANGE_RATE_UNSUPPORTED",
  FILE_TERM_OPTION_WRONG_TYPE = "FILE_TERM_OPTION_WRONG_TYPE",
  FILE_TERM_OPTION_WRONG_FORMAT = "FILE_TERM_OPTION_WRONG_FORMAT",
  FILE_TERM_OPTION_UNSUPPORTED = "FILE_TERM_OPTION_UNSUPPORTED",
  FILE_REFERENCE_EXISTS = "FILE_REFERENCE_EXISTS",
  OPTIMISTIC_CONCURRENCY_VIOLATION = "OPTIMISTIC_CONCURRENCY_VIOLATION",
}
