import { ProductType } from '../products/products.model';
import { StaticDataSet, StaticDataModel, StaticDataType } from "../static-data.model";

const germanState: StaticDataModel[] = [
  { value: "DE-BW", label: "Baden-Württemberg" },
  { value: "DE-BY", label: "Bavaria" },
  { value: "DE-BE", label: "Berlin" },
  { value: "DE-BB", label: "Brandenburg" },
  { value: "DE-HB", label: "Bremen" },
  { value: "DE-HH", label: "Hamburg" },
  { value: "DE-HE", label: "Hessen" },
  { value: "DE-MV", label: "Mecklenburg-Western Pomerania" },
  { value: "DE-NI", label: "Lower Saxony" },
  { value: "DE-NW", label: "North Rhine-Westphalia" },
  { value: "DE-RP", label: "Rhineland-Palatinate" },
  { value: "DE-SL", label: "Saarland" },
  { value: "DE-SN", label: "Saxony" },
  { value: "DE-ST", label: "Saxony-Anhalt" },
  { value: "DE-SH", label: "Schleswig-Holstein" },
  { value: "DE-TH", label: "Thuringia" },
];

const countries: StaticDataModel[] = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Åland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "AndorrA" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Brazil" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, The Democratic Republic of the" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote D'Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran, Islamic Republic Of" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Korea, Democratic People'S Republic of" },
  { value: "KR", label: "Korea, Republic of" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People'S Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MK", label: "Macedonia, The Former Yugoslav Republic of" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States of" },
  { value: "MD", label: "Moldova, Republic of" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territory, Occupied" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "RWANDA" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "CS", label: "Serbia and Montenegro" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan, Province of China" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tansania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "USA" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

const languages: StaticDataModel[] = [
  { value: "AR", label: "Arabic" },
  //{ value: "ZH", label: "Chinese" },
  //{ value: "DA", label: "Danish" },
  { value: "DE", label: "German" },
  { value: "EN", label: "English" },
  { value: "ES", label: "Spanish" },
  //{ value: "FA", label: "Persian" },
  //{ value: "FI", label: "Finnish" },
  { value: "FR", label: "French" },
  //{ value: "GA", label: "Irish" },
  //{ value: "HE", label: "Hebrew" },
  //{ value: "HI", label: "Hindi" },
  { value: "IT", label: "Italian" },
  //{ value: "JA", label: "Japanese" },
  //{ value: "NL", label: "Dutch; Flemish" },
  //{ value: "NO", label: "Norwegian" },
  { value: "PT", label: "Portuguese" },
  { value: "RU", label: "Russian" },
  //{ value: "SV", label: "Swedish" },
  //{ value: "AZ", label: "Turkish" },
  //{ value: "UK", label: "Ukrainian" },
];

const occupation: StaticDataModel[] = [
  { value: "MIDWIFE", label: "Midwife" }, //Hebamme
  { value: "ATA", label: "ATA" }, // Narkseführung // Anästhesietechnischer Assistent
  { value: "OTA", label: "OTA" }, //Krankheitslehre primär auf zu operierende Krankheiten // Operationstechnischen Assistenten
  { value: "NURSE", label: "Nurse" },
  { value: "PARAMEDIC", label: "Paramedic" },
  { value: "MTRA", label: "MTRA" }, // Medizinisch-technische Radiologieassistenten
  { value: "MFA", label: "MFA" }, // Medizinische Fachangestellte
  { value: "MTLA", label: "MTLA" }, // Medizinische Fachangestellte
  { value: "PHYSIOTHERAPIST", label: "Physiotherapist" },
  { value: "DOCTOR", label: "Doctor" },
];

const education: StaticDataModel[] = [
  { value: "VT", label: "Vocational training" },
  { value: "HS", label: "High school" },
  { value: "BA", label: "Bachelor" },
  { value: "MS", label: "Master" },
  { value: "PHD", label: "PhD" },
];

const salutation: StaticDataModel[] = [
  { value: "MR", label: "Mr." },
  { value: "MRS", label: "Mrs." },
  { value: "MX", label: "Mx." },
];

const gender: StaticDataModel[] = [
  { value: "FEMALE", label: "Female" },
  { value: "MALE", label: "Male" },
  { value: "OTHER", label: "Other" },
];

const martialStatus: StaticDataModel[] = [
  { value: "SINGLE", label: "Single" },
  { value: "ENGAGED", label: "Engaged" },
  { value: "MARRIED", label: "Married" },
  { value: "DIVORCED", label: "Divorced" },
  { value: "WIDOWED", label: "Widowed" },
];

const professionalFields: StaticDataModel[] = [
  { value: "AK", label: "Geriatrics / remobilization" },
  { value: "AM", label: "Outpatient clinic" },
  { value: "AN", label: "Anesthesiology" },
  { value: "AU", label: "Ophthalmology" },
  { value: "BU", label: "Burn unit" },
  { value: "CH", label: "Surgery" },
  { value: "DE", label: "Dermatology" },
  { value: "DI", label: "Dialysis" },
  { value: "GE", label: "Geriatrics" },
  { value: "GY", label: "Gynecology / obstetrics" },
  { value: "HA", label: "Otolaryngology" },
  { value: "IC", label: "ICU" },
  { value: "IN", label: "Internal medicine" },
  { value: "KA", label: "Cardiology" },
  { value: "KIC", label: "Pediatric and adolescent surgery" },
  { value: "KIH", label: "Pediatrics" },
  { value: "KIP", label: "Child and adolescent psychiatry" },
  { value: "MU", label: "Oral and maxillofacial surgery" },
  { value: "NEP", label: "Nephrology" },
  { value: "NER", label: "Neurology" },
  { value: "NEC", label: "Neurosurgery" },
  { value: "NI", label: "Neo-natal ICU (NICU)" },
  { value: "NU", label: "Nuclear medicine" },
  { value: "ON", label: "Oncology" },
  { value: "OR", label: "Orthopedics" },
  { value: "OT", label: "Otolaryngology" },
  { value: "PA", label: "Palliative care" },
  { value: "PE", label: "Pediatrics" },
  { value: "PH", label: "Physiotherapy" },
  { value: "PI", label: "Pediatric ICU (PICU)" },
  { value: "PL", label: "Plastic surgery" },
  { value: "PN", label: "Pneumology" },
  { value: "PS", label: "Psychiatry" },
  { value: "PSM", label: "Psychosomatics" },
  { value: "RA", label: "Radiology" },
  { value: "RAO", label: "Radiation oncology" },
  { value: "ST", label: "Stroke unit" },
  { value: "UN", label: "Trauma surgery" },
  { value: "UR", label: "Urology" },
  { value: "ZA", label: "Tooth, mouth, jaw" },
  { value: "ZNA", label: "Emergency department" },
  { value: "VAR", label: "Various" },
];

const languageLevels: StaticDataModel[] = [
  { value: "A1", label: "A1" },
  { value: "A2", label: "A2" },
  { value: "B1", label: "B1" },
  { value: "B2", label: "B2" },
  { value: "C1", label: "C1" },
  { value: "C2", label: "C2" },
  { value: "NV", label: "Native speaker" },
];

const allowedFileTypes: StaticDataModel[] = [
  { value: "DEALCONFIRM", label: "Deal confirmation" },
  { value: "EVENTCONFIRM", label: "Event confirmation" },
  { value: "OTHER", label: "Other" },
];

const careFacilities: StaticDataModel[] = [
  { value: "UNVHOSPITAL", label: "Unv. hospital" },
  { value: "MILTHOSPITAL", label: "Milt. hospital" },
  { value: "PRIVHOSPITAL", label: "Private hospital" },
  { value: "PUBHOSPITAL", label: "Public hospital" },
  { value: "COMPNURSE", label: "Company nurse" },
  { value: "REHA", label: "Rehabilitation clinic " },
  { value: "HCA", label: "Home care / adults" },
  { value: "HCC", label: "Home care / children" },
  { value: "ECC", label: "Elderly care" },
  { value: "POLICLINIC", label: "Polyclinic" },
  { value: "DOCOFFICE", label: "Doctors office" },
];

const workArea: StaticDataModel[] = [
  { value: "STATION", label: "Station" },
  { value: "OP", label: "Operation" },
];

const currency: StaticDataModel[] = [
  { value: "EUR", label: "EUR" },
  { value: "USD", label: "USD" },
  { value: "CHF", label: "CHF" },
  { value: "GBP", label: "GBP" },
  { value: "NOK", label: "NOK" },
  { value: "SEK", label: "SEK" },
  { value: "DKK", label: "DKK" },
  { value: "SGD", label: "SGD" },
  { value: "AUD", label: "AUD" },
  { value: "JPY", label: "JPY" },
  { value: "NZD", label: "NZD" },
  { value: "CAD", label: "CAD" },
  { value: "AED", label: "AED" },
  { value: "TRY", label: "TRY" },
  { value: "THB", label: "THB" },
  { value: "CZK", label: "CZK" },
  { value: "PLN", label: "PLN" },
  { value: "ZAR", label: "ZAR" },
];

const product: StaticDataModel[] = [
  { value: ProductType.FxForward, label: "FX Forward" },
  { value: ProductType.FxSwap, label: "FX Swap" },
];

export const StaticDataEnGB: StaticDataSet = {
  "StaticDataType.MartialStatus": martialStatus,
  "StaticDataType.Gender": gender,
  "StaticDataType.Salutation": salutation,
  "StaticDataType.Countries": countries,
  "StaticDataType.GermanState": germanState,
  "StaticDataType.Languages": languages,
  "StaticDataType.Occupation": occupation,
  "StaticDataType.Education": education,
  "StaticDataType.CareFacilities": careFacilities,
  "StatStaticDataType.LanguageLevels": languageLevels,
  "StatStaticDataType.ProfessionalFields": professionalFields,
  "StatStaticDataType.WorkArea": workArea,
  [StaticDataType.AllowedUploadFileTypes]: allowedFileTypes,
  [StaticDataType.Currency]: currency,
  [StaticDataType.Product]: product,
};
