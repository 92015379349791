import { addDays, addMonths, addWeeks, addYears } from "../dateUtils";

export class DateParser
{
    private readonly flags = "i";
    private readonly regDay = new RegExp("([0-9]+)\s*d", this.flags);
    private readonly regWeek = new RegExp("([0-9]+)\s*w", this.flags);
    private readonly regMonth = new RegExp("([0-9]+)\s*m", this.flags);
    private readonly regYear = new RegExp("([0-9]+)\s*y", this.flags);

    public  fromString(code:string,refDate:Date) : Date {
      if ( !code || code=="")
        return refDate;
      code = code.trim();
      let newDate : Date = null;
      let match = this.regYear.exec(code);
      if (match && match.length>0)
      {
        const text = match[1];
        newDate = addYears(refDate, Number.parseInt(text))
      }
      match = this.regMonth.exec( code );
      if (match && match.length>0)
			{
        const text = match[1];
				newDate=addMonths(refDate, Number.parseInt(text) );
			}
      match = this.regWeek.exec( code );
      if (match && match.length>0)
			{
        const text = match[1];
				newDate=addWeeks(refDate, Number.parseInt(text) );
			}
      match = this.regDay.exec( code );
      if (match && match.length>0)
			{
        const text = match[1];
				newDate=addDays(refDate, Number.parseInt(text) );
			}
      if (!newDate)
      {
        const ucode = code.toUpperCase();
        if( ucode == "ON" || ucode == "TN" || ucode == "SN" )
				{
          newDate=addDays(refDate, 1 );
				}
				else if( ucode == "SW")
				{
            newDate=addWeeks(refDate, 1 );
				}
      }
      return newDate;
    }

}


