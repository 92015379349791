import { ComponentFactoryResolver, Injectable } from "@angular/core";
import { FxSwap } from "@hedgebench/graphql";
import { FxSwapData, FxSwapShared,  ProductType } from "@hedgebench/shared";
import { AmountPipe } from "../../../shared/pipes/amount.pipe";
import { ExchangeRatePipe } from "../../../shared/pipes/exchangeRate.pipe";
import { SwapPointsPipe } from "../../../shared/pipes/swapPoints.pipe";
import { Product, ProductInstance } from "../product/product";
import { FxSwapFormComponent } from "./fx-swap-form/fx-swap-form.component";


@ProductInstance(ProductType.FxSwap)
export class FxSwapProduct extends Product {


  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
     exchangePipe: ExchangeRatePipe,
     amountPipe: AmountPipe,
     swapPointsPipe: SwapPointsPipe
  ) {
    super(componentFactoryResolver,exchangePipe,amountPipe,swapPointsPipe);
    this.formComponentFactoryProduct = this.componentFactoryResolver.resolveComponentFactory(FxSwapFormComponent);
    this.productShared = new FxSwapShared();
  }


  productState: FxSwapData = new FxSwap();

  getInputChartData() {
    return {
      labels: [this.productState.valueDateNear, this.productState.valueDateFar],
      datasets: [
        {
          label: this.productState.buyCurrency,
          data: [
            this.productState.amountCurrencyNear === this.productState.buyCurrency
              ? this.productState.amountNear
              : this.productState.amountNear * this.productState.exchangeRateNear,
              this.productState.amountCurrencyNear === this.productState.buyCurrency
              ? -this.productState.amountNear
              : -this.productState.amountNear * this.productState.exchangeRateFar
          ],
          datalabels: { anchor: 'end', align: 15 },
          maxBarThickness: 30
        },
        {
          label: this.productState.sellCurrency,
          data: [
            this.productState.amountCurrencyNear === this.productState.buyCurrency
              ? -this.productState.amountNear * this.productState.exchangeRateNear
              : -this.productState.amountNear,
              this.productState.amountCurrencyNear === this.productState.buyCurrency
              ? this.productState.amountNear * this.productState.exchangeRateFar
              : this.productState.amountNear
          ],
          datalabels: { anchor: 'start', align: -15 },
          maxBarThickness: 30
        }
      ]
    }
  }

  getProductDescription(language?: string): string {
    return "desc"
  }
}
