import { Directive, Input, OnChanges, SimpleChanges, Host } from "@angular/core";
import { marketConvention } from "@hedgebench/shared";
import { InputNumber } from "primeng/inputnumber";


@Directive({
  selector: "[rateInput]",
})
export class RateInputDirective implements OnChanges {
  @Input() ccy1: string;
  @Input() ccy2: string;
  @Input() isSwapPoints: boolean = false;


  constructor(@Host() private  readonly input:InputNumber) {
    input.mode = "decimal";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ccy1 || changes.ccy2) {
      if (this.ccy1 && this.ccy1 != "" && this.ccy2 && this.ccy2 != "") {
        const convention = marketConvention.getFXConvention(this.ccy1, this.ccy2);
        this.input.suffix = convention.QuotationMarketStandard ? ` ${this.ccy1}-${this.ccy2}` : ` ${this.ccy2}-${this.ccy1}`;
        if (!this.isSwapPoints) {
          this.input.maxFractionDigits = convention.Precision_CrossRateDecimals;
          this.input.minFractionDigits = convention.Precision_CrossRateDecimals;
        } else {
          this.input.maxFractionDigits = convention.Precision_SwapPointDecimals;
          this.input.minFractionDigits = convention.Precision_SwapPointDecimals;
        }
      }
    }
  }
}
