<div class="exception-body error-body" *transloco="let t">
  <div class="exception-panel"></div>

  <div class="exception-band">
    <div class="exception-content">
      <h3 class="p-underline">{{ t("initializing.title") }}</h3>
      <p *ngIf="!(this.authenticated | async)">{{ t("initializing.authentication") }}</p>
      <p *ngIf="!(this.authorized | async) && (this.authenticated | async)">{{ t("initializing.authorization") }}</p>
      <img src="assets/layout/images/logo.png" />
    </div>
  </div>
</div>
