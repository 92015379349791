import { Component, Input, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { MenuService, MenueSection } from "./menu.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
})
export class MenuComponent implements OnInit {
  @Input() menuSection: MenueSection;

  items: MenuItem[];

  constructor(private readonly menuService: MenuService) {}

  ngOnInit(): void {
    this.items = this.menuService.getMenueItemsForSection(this.menuSection);
  }
}
