import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AppSettings } from "../services/app.settings";
import { UserPermission } from "@hedgebench/shared";

@Directive({
  selector: "[appPermissions]",
})
export class PermissionDirective {
  private _requiredPermissions: UserPermission[];
  private _viewRef: EmbeddedViewRef<any> | null = null;
  private _templateRef: TemplateRef<any> | null = null;

  @Input()
  set appPermissions(permission: UserPermission | UserPermission[]) {
    this._requiredPermissions = Array.isArray(permission) ? permission : [permission];
    this._viewRef = null;
    this.init();
  }

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly appSettings: AppSettings
  ) {
    this._templateRef = templateRef;
  }

  init(): void {
    const isPermitted =
      this._requiredPermissions == null ||
      this._requiredPermissions.length == 0 ||
      this._requiredPermissions.some((x) => this.appSettings.hasPermission(x));
    if (isPermitted) {
      this._viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
