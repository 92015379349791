import { ProductCashflow, ProductData } from "./products.model";

export abstract class ProductShared {

  isValid(product:ProductData) : boolean
  {
    return (product!=null && product.buyCurrency!=null && product.sellCurrency!=null)

  }

  abstract getCashflows(product: ProductData): ProductCashflow[];

  getAggregatedCashflow(product: ProductData, currency: string, valueDate:Date): number {
    let flow: number = 0;
    this.getCashflows(product)
      .filter((x) => x.currency == currency && x.valueDate==valueDate)
      .forEach((x) => (flow += x.amount));
    return flow;
  }
}
