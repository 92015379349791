import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./structure/app.component";
import { SharedModule } from "./shared/shared.module";
import { FooterComponent } from "./structure/footer/footer.component";
import { MainComponent } from "./structure/main/main.component";
import { MenuComponent } from "./structure/menu/menu.component";
import { MenuItemComponent } from "./structure/menu-item/menu-item.component";
import { SidebarComponent } from "./structure/sidebar/sidebar.component";
import { TopbarComponent } from "./structure/topbar/topbar.component";
import { PagesModule } from "./pages/pages.module";
import { ProductsModule } from "./features/products/products.module";
import { AppRoutingModule } from "./app-routing.module";

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, SharedModule, ProductsModule, PagesModule, AppRoutingModule],
  declarations: [
    AppComponent,
    FooterComponent,
    MainComponent,
    MenuComponent,
    MenuItemComponent,
    SidebarComponent,
    TopbarComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
