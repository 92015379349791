import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { AccessDeniedComponent } from "./acessdenied/accessdenied.component";
import { ErrorComponent } from "./error/error.component";
import { InitComponent } from "./initalization/init.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { NotFoundComponent } from "./notfound/notfound.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    AccessDeniedComponent,
    ErrorComponent,
    InitComponent,
    LoginComponent,
    LogoutComponent,
    NotFoundComponent,
  ],
  exports: [AccessDeniedComponent, ErrorComponent, InitComponent, LoginComponent, LogoutComponent, NotFoundComponent],
})
export class PagesModule {}
