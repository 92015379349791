import { Component, Inject, Version } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AppSettings } from "../../shared/services/app.settings";
import { APP_VERSION } from "../../shared/services/app.tokens";
import { MainComponent } from "../main/main.component";
import { TranslocoService, PersistStorage } from "@ngneat/transloco";
import {
  TRANSLOCO_PERSIST_LANG_STORAGE,
  TRANSLOCO_PERSIST_LANG_CONFIG,
  PersistLangConfig,
} from "@ngneat/transloco-persist-lang";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent {
  readonly selectedLanguage = this.transloco.getActiveLang();
  readonly availableLanguages = this.transloco.getAvailableLangs();
  readonly languageFlagMap = {
    "en-GB": "uk",
    "de-DE": "de",
  };

  constructor(
    public readonly main: MainComponent,
    public readonly appSettings: AppSettings,
    @Inject(APP_VERSION) public readonly version: Version,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly transloco: TranslocoService,
    @Inject(TRANSLOCO_PERSIST_LANG_STORAGE) private readonly translocoStorage: PersistStorage,
    @Inject(TRANSLOCO_PERSIST_LANG_CONFIG) private readonly translocoConfig: PersistLangConfig
  ) {}

  switchLanguage(language: string): void {
    this.translocoStorage.setItem(this.translocoConfig.storageKey, language);
    this.document.defaultView.location.reload();
  }
}
