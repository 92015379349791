
import { Pipe, PipeTransform } from "@angular/core";
import { marketConvention} from "@hedgebench/shared"

@Pipe({
  name: "exchangeRatePipe",
})
export class ExchangeRatePipe implements PipeTransform {

  transform(value: number,currency1:string,currency2:string): string {
    let convention = marketConvention.getFXConvention(currency1,currency2);
    let prefix =`${currency1}-${currency2}`;
    if (!convention.QuotationMarketStandard)
    {
      convention = marketConvention.getFXConvention(currency2,currency1);
      prefix =`${currency2}-${currency1}`;
    }
    const val = prefix +" " + value.toFixed(convention.Precision_CrossRateDecimals);
    return val;
  }
}
