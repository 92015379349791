import { Pipe, PipeTransform } from "@angular/core";
import { EnumPipe } from "./enum-pipe";

@Pipe({
  name: "booleanPipe",
})
export class BooleanPipe implements PipeTransform {
  constructor(private readonly enumPipe: EnumPipe) {}

  transform(value: boolean): string {
    return this.enumPipe.transform(!!value, "boolean");
  }
}
