import { Directive, Input, HostBinding, HostListener } from "@angular/core";

@Directive({
  selector: "img[default]",
})
export class ImagePreloadDirective {
  @HostBinding("src") @Input() src: string;
  @Input() default: string;
  @HostBinding("class") className;

  @HostListener("error")
  updateUrl(): void {
    this.src = this.default;
  }

  @HostListener("load")
  load(): void {
    this.className = "image-loaded";
  }
}
