import { Component } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
})
export class ErrorDialogComponent {
  errorMessage: string;
  caption: string;

  constructor(private readonly dialogRef: DynamicDialogRef, readonly config: DynamicDialogConfig) {
    this.errorMessage = config.data.message;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
