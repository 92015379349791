import { AfterViewInit, Directive, Optional } from "@angular/core";
import { InputTextarea } from "primeng/inputtextarea";
import { Sidebar } from "primeng/sidebar";
import { TableComponent } from "../components/table/table.component";

// https://github.com/primefaces/primeng/issues/9231
// https://github.com/primefaces/primeng/issues/9890
@Directive({
  selector: "[autoResizeFix]",
})
export class AutoResizeFixDirective implements AfterViewInit {
  constructor(
    private readonly textArea: InputTextarea,
    @Optional() private readonly sidebar: Sidebar,
    @Optional() private readonly table: TableComponent
  ) {}

  ngAfterViewInit(): void {
    (this.sidebar ?? this.table?.sidebar)?.onShow.subscribe(() => {
      if (this.textArea.autoResize) {
        this.textArea.resize();
      }
    });
  }
}
