export const MultiLegendPlugin = {
  id: "multiLegend",
  beforeInit: function (chart) {
    if (chart.legend) {
      chart.options.plugins.legend.labels.filter = (item, chart) =>
        chart.datasets.findIndex((s) => s.label === item.text) === item.datasetIndex;

      chart.options.plugins.legend.onClick = (_, item, legend) => {
        const datasets = legend.chart.data.datasets.map((s, i) => [s, i]).filter(([s, _]) => s.label === item.text);
        for (const [_, index] of datasets) {
          const meta = legend.chart.getDatasetMeta(index);
          meta.hidden = meta.hidden === undefined ? !legend.chart.data.datasets[index].hidden : !meta.hidden;
        }
        legend.chart.update();
      };
    }
  },
};
