export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addYears(date: Date, years: number): Date {
  const result = new Date(date);
  result.setFullYear(date.getFullYear() + years);
  return result;
}

export function getDaysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

export function addMonths(date: Date, months: number): Date {
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  month += months;
  while (month > 12) {
    month -= 12;
    year++;
  }
  while (month < 1) {
    month += 12;
    year--;
  }
  const daysInMonth = getDaysInMonth(month, year);
  day = Math.min(day, daysInMonth);
  return new Date(year, month, day);
}

export function addWeeks(date,weeks:number) : Date
{
  const result = new Date(date);
  result.setDate(result.getDate() + weeks*7);
  return result;
}
