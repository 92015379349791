import { ProductShared } from "./product.shared";
import { FxSwapData, ProductCashflow } from "./products.model";

export class FxSwapShared extends ProductShared {


  isValid(product: FxSwapData): boolean {
    return super.isValid(product) && product.amountNear!=null && product.amountNear>=0 && product.amountCurrencyNear!=null && product.valueDateNear!=null
    && product.exchangeRateNear!=null && product.exchangeRateFar!=null  && product.valueDateFar!=null  && product.exchangeRateNear>0 && product.exchangeRateFar>0
  }

  getCashflows(product: FxSwapData): ProductCashflow[] {
    return [
      {
        currency: product.buyCurrency,
        valueDate: product.valueDateNear,
        amount:
          product.amountCurrencyNear === product.buyCurrency
            ? product.amountNear
            : product.amountNear * product.exchangeRateNear,
      },
      {
        currency: product.buyCurrency,
        valueDate: product.valueDateFar,
        amount:
          product.amountCurrencyNear === product.buyCurrency
            ? -product.amountNear
            : -product.amountNear * product.exchangeRateFar,
      },
      {
        currency: product.sellCurrency,
        valueDate: product.valueDateNear,
        amount:
          product.amountCurrencyNear === product.buyCurrency
            ? -product.amountNear * product.exchangeRateNear
            : -product.amountNear,
      },
      {
        currency: product.sellCurrency,
        valueDate: product.valueDateFar,
        amount:
          product.amountCurrencyNear === product.buyCurrency
            ? product.amountNear * product.exchangeRateFar
            : product.amountNear,
      },
    ];
  }
}
