export function getLogoDownloadURI(id: string): string {
  return `${getImageContainerName(id)}/${getLogoBlobName()}`;
}

export function getImageContainerName(id: string): string {
  return `${id}-images`;
}

export function getLogoBlobName(): string {
  return `logo`;
}
