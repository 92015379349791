import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../../shared/services/app.settings";

@Component({
  selector: "app-accessdenied",
  templateUrl: "./accessdenied.component.html",
})
export class AccessDeniedComponent {
  loggedIn: Observable<boolean>;

  constructor(private readonly appSettings: AppSettings) {
    this.loggedIn = this.appSettings.isAuthenticated$;
  }
}
