<a
  *ngIf="!item.routerLink || item.items"
  [attr.href]="item.url"
  (click)="itemClick($event)"
  (keydown.enter)="itemClick($event)"
  [attr.target]="item.target"
  [attr.tabindex]="0"
  pRipple
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
  [ngClass]="item.class"
>
  <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
  <span>{{ item.label }}</span>
  <span class="ink" *ngIf="hover"></span>
  <span class="menuitem-badge" *ngIf="item.badge">{{ item.badge }}</span>
  <i class="pi pi-fw pi-angle-down submenu-toggler" *ngIf="item.items"></i>
</a>

<a
  *ngIf="item.routerLink && !item.items"
  (click)="itemClick($event)"
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
  [ngClass]="item.class"
  [routerLink]="item.routerLink"
  routerLinkActive="active-menuitem-routerlink"
  pRipple
  [routerLinkActiveOptions]="item.routerLinkActiveOptions ?? { exact: true }"
  [attr.target]="item.target"
  [attr.tabindex]="0"
>
  <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
  <span>{{ item.label }}</span>
  <span class="ink" *ngIf="hover"></span>
  <span class="menuitem-badge" *ngIf="item.badge">{{ item.badge }}</span>
  <i class="pi pi-fw pi-angle-down submenu-toggler" *ngIf="item.items"></i>
</a>

<ul *ngIf="item.items && active" [@children]="active ? 'visibleAnimated' : 'hiddenAnimated'">
  <li *ngFor="let child of item.items; index as i">
    <app-menu-item [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></app-menu-item>
  </li>
</ul>
