
import { Pipe, PipeTransform } from "@angular/core";
import { marketConvention} from "@hedgebench/shared"

@Pipe({
  name: "amountPipe",
})
export class AmountPipe implements PipeTransform {

  transform(value: number,currency:string): string {
    const convention = marketConvention.getCurrencyConvention(currency);
    const val = value.toLocaleString(undefined,{minimumFractionDigits:convention.notionalDecimals,maximumFractionDigits:convention.notionalDecimals})  +" "+ currency;
    return val;
  }
}
