import { Component } from "@angular/core";
import { Product } from "../product";

@Component({
  selector: "app-display-fchart",
  templateUrl: "./displayChart.component.html",
})
export class DisplayChartComponent {

  product: Product;

}
