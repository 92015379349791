import { ApolloClientOptions, InMemoryCache, DefaultOptions } from "@apollo/client/core";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "apollo-angular/http";
import { withScalars } from "apollo-link-scalars";
import { createPersistedQueryLink } from "apollo-angular/persisted-queries";
import { sha256 } from "crypto-hash";
import { environment } from "../../../environments/environment";
import { AppSettings } from "./app.settings";
import introspectionResult  from "./../../_generated/graphql.schema.json";
import { buildClientSchema,IntrospectionQuery } from "graphql";
import { DateTimeResolver } from 'graphql-scalars'

const schema = buildClientSchema((introspectionResult as unknown) as IntrospectionQuery)

const typesMap = {
  DateTime:  {
    serialize: (parsed: Date)=> parsed.toString(),
    parseValue: (raw: string |number | null): Date | null => {
      return raw ? new Date(raw): null;
    }
  },
}

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const link = <any>httpLink.create({ uri: environment.graphqlURI })
  const scalarLink = withScalars({schema:schema,typesMap:typesMap}).concat(link)
  const persistedQueryLink = createPersistedQueryLink({ sha256 }).concat(scalarLink);
  const retry = new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: environment.production ? 8 : 2,
      retryIf: (error, _operation) => !!error,
    },
  }).concat(persistedQueryLink);
  const error = errorFunc.concat(retry);
  return {
    connectToDevTools: true,
    assumeImmutableResults: true,
    link: error,
    cache: new InMemoryCache({
      possibleTypes: {
        Product: ["FxSwap", "FxForward"],
      },
    }),
    defaultOptions: defaultOptions,
  };
}

//https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-first",
    errorPolicy: "none",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "none",
  },
  mutate: {
    errorPolicy: "none",
  },
};

// GraphQL Error Handling
export const errorFunc = onError((errorResponse) => {
  AppSettings.graphQLError$.next(errorResponse);
  const { graphQLErrors, networkError } = errorResponse;
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) =>
      console.log(
        `[GraphQL error]: Message: ${message},Extension: ${extensions["code"]} ,Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: Message: ${networkError.message}`);
  }
});
