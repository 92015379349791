// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //graphqlURI: "https://hedgebench-dev.azurewebsites.net/graphql",
  graphqlURI: "https://hedgebench-dev.azurewebsites.net/api/graphql",
  auth0_clientId: "CKXlAEDgiLLDr0ydXvinIGPUSvbWKf5Y",
  auth0_domain: "hedgebench-dev.eu.auth0.com",
  auth0_audience: "https://dev.hedgebench.com",
  appinsights_connectionstring:"InstrumentationKey=d08f1568-fb13-4437-9bc1-e55ef226e68e;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
