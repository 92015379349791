import { AfterViewInit, Component,  OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { getLocaleDateString } from "@hedgebench/shared";
import { TranslocoService } from "@ngneat/transloco";
import { Checkbox } from "primeng/checkbox";
import { combineLatest, startWith } from "rxjs";
import { AppSettings } from "../../../../shared/services/app.settings";
import { FxForwardProduct } from "../fx-forward.product";
import {ProductForm} from "../../product/product.form"

@Component({
  selector: "app-fx-forward-form",
  templateUrl: "./fx-forward-form.component.html",
})
export class FxForwardFormComponent extends ProductForm implements OnInit, AfterViewInit {

  product: FxForwardProduct;

  @ViewChild("hasTermOption")
  hasTermOption: Checkbox;

  locale: string;
  dateFormat: string;
  currencies: string[];
  buyCurrencies: string[];
  sellCurrencies: string[];
  minTermOption: Date;

  constructor(
    private readonly appSettings: AppSettings,
    private readonly formBuilder: FormBuilder,
    private readonly transloco: TranslocoService
  ) {
    super();
  }

  ngOnInit(): void {
    this.locale = this.transloco.getActiveLang();
    this.dateFormat = getLocaleDateString(this.locale);

    this.form.addControl("buyCurrency", this.formBuilder.control(this.product.productState.buyCurrency, Validators.required));
    this.form.addControl("sellCurrency", this.formBuilder.control(this.product.productState.sellCurrency, Validators.required));
    this.form.addControl("valueDate", this.formBuilder.control(this.product.productState.valueDate, Validators.required));
    this.form.addControl(
      "exchangeRate",
      this.formBuilder.control(
        this.product.productState.exchangeRate,
        Validators.compose([Validators.required, Validators.min(0.01)])
      )
    );
    this.form.addControl(
      "amount",
      this.formBuilder.control(this.product.productState.amount, Validators.compose([Validators.required, Validators.min(1)]))
    );
    this.form.addControl("amountCurrency", this.formBuilder.control(this.product.productState.amountCurrency, Validators.required));
    this.form.addControl("termOption", this.formBuilder.control(this.product.productState.termOption));

    this.buyCurrencies = this.appSettings.supportedCurrencies;
    combineLatest([
      this.form.controls.buyCurrency.valueChanges.pipe(startWith(this.product.productState.buyCurrency)),
      this.form.controls.sellCurrency.valueChanges.pipe(startWith(this.product.productState.sellCurrency)),
    ]).subscribe(([buy, sell]) => this.updateCurrencies(buy, sell));
    this.form.controls.buyCurrency.valueChanges
      .pipe(startWith(this.product.productState.buyCurrency))
      .subscribe((buy) => this.updateCurrencies2(buy));
  }

  ngAfterViewInit(): void {
    let initialToggle = true;
    this.hasTermOption.onChange.pipe(startWith({ checked: !!this.product.productState.termOption })).subscribe((e) => {
      if (e.checked) {
        this.form.controls.termOption.enable();
        this.form.controls.termOption.setValidators(Validators.required);
      } else {
        this.form.controls.termOption.reset();
        this.form.controls.termOption.disable();
        this.form.controls.termOption.clearValidators();
      }
      if (!initialToggle) {
        this.form.controls.termOption.markAsDirty();
      }
      initialToggle = false;
    });
    this.hasTermOption.writeValue(!!this.product.productState.termOption);
    this.form.controls.valueDate.valueChanges
      .pipe(startWith(this.product.productState.valueDate))
      .subscribe((valueDate) => this.updateTermOption(valueDate));
  }

  private updateCurrencies(buy: string, sell: string): void {
    this.currencies = [...new Set([buy, sell])].filter((c) => c);
    if (!this.currencies.includes(this.form.controls.amountCurrency.value)) {
      this.form.controls.amountCurrency.setValue(null);
    }
  }

  private updateCurrencies2(buy: string): void {
    this.sellCurrencies = this.appSettings.supportedCurrencies.filter((c) => c !== buy);
    if (!this.sellCurrencies.includes(this.form.controls.sellCurrency.value)) {
      this.form.controls.sellCurrency.setValue(null);
    }
  }

  private updateTermOption(valueDate: Date): void {
    const minTermOption = new Date(valueDate);
    minTermOption.setDate(minTermOption.getDate() + 1);
    this.minTermOption = minTermOption;

    if (this.hasTermOption.checked && this.form.controls.termOption.value < this.minTermOption) {
      this.form.controls.termOption.reset();
    }
  }
}
