export enum UserPermission {
  ORG_CUD = "cud:organization",
  ORG_READ = "read:organization",
  MYORG_CUD = "cud:myorganization",
  USER_READ = "read:user",
  USER_CUD = "cud:user",
  USERGROUP_READ = "read:userGroup",
  USERGROUP_CUD = "cud:userGroup",
}

export enum UserRole {
  Administrator = "Administrator",
  Advisor_User = "Advisor_User",
  Advisor_Manager = "Advisor_Manager",
  Corporate_User = "Corporate_User",
  Corporate_Manager = "Corporate_Manager",
}

export interface RolePermission {
  role: UserRole;
  permissions: UserPermission[];
}

export interface RolePermissions {
  rolePermissions: RolePermission[];
}

export const API_PERMISSIONS: UserPermission[] = [UserPermission.ORG_CUD];

export const ROLE_PERMISSION_MAPPING: RolePermissions = {
  rolePermissions: [
    {
      role: UserRole.Administrator,
      permissions: Object.keys(UserPermission).map<UserPermission>((x) => UserPermission[x]),
    },
    {
      role: UserRole.Corporate_User,
      permissions: [UserPermission.ORG_READ],
    },
    {
      role: UserRole.Advisor_User,
      permissions: [UserPermission.ORG_READ],
    },
    {
      role: UserRole.Corporate_Manager,
      permissions: [
        UserPermission.MYORG_CUD,
        UserPermission.ORG_CUD,
        UserPermission.ORG_READ,
        UserPermission.USERGROUP_READ,
        UserPermission.USERGROUP_CUD,
        UserPermission.USER_READ,
        UserPermission.USER_CUD,
      ],
    },
    {
      role: UserRole.Advisor_Manager,
      permissions: [
        UserPermission.MYORG_CUD,
        UserPermission.ORG_READ,
        UserPermission.ORG_CUD,
        UserPermission.USERGROUP_READ,
        UserPermission.USERGROUP_CUD,
        UserPermission.USER_READ,
        UserPermission.USER_CUD,
      ],
    },
  ],
};
