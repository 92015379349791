import { Component, OnInit } from "@angular/core";
import { OrganizationsService } from "../../features/organizations/organizations.service";
import { AppSettings } from "../../shared/services/app.settings";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  organizationLogo?: string;
  creatorOrganizationLogo?: string;

  constructor(private readonly appSettings: AppSettings, private readonly orgService: OrganizationsService) {}

  ngOnInit(): void {
    this.orgService
      .getOrganizationLogoDownloadURL(this.appSettings.organizationId)
      .subscribe((x) => (this.organizationLogo = x));
    if (this.appSettings.creatorOrganizationId) {
      this.orgService
        .getOrganizationLogoDownloadURL(this.appSettings.creatorOrganizationId)
        .subscribe((x) => (this.creatorOrganizationLogo = x));
    }
  }
}
